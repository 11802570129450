import React from 'react';
import { Carousel } from 'antd';
import Banner1 from '../assets/img/ad_banner_1.jpg';
import Banner2 from '../assets/img/ad_banner_2.jpg';

const AdSlider = () => {
  return (
    <>
      <Carousel autoplay={true}>
        <div>
          <img className='w-full' src={Banner1} alt='Advert 1' />
        </div>
        <div>
          <img className='w-full' src={Banner2} alt='Advert 2' />
        </div>
      </Carousel>
    </>
  );
};

export default AdSlider;
