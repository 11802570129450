import { useEffect, useState } from 'react';
import HshsBranches from '../Hshs/HshsSchedules';
import { parse } from 'querystring';
import HshsScheduleBooking from '../Hshs/HshsBooking';

const HshsBooking = () => {
  //const [scheduleId, setScheduleId] = useState<string | undefined>();
  const query_param = parse(window.location.search.replace('?', ''));
  const [showScheduleBooking, setShowScheduleBooking] = useState(false);

  useEffect(() => {
    if (
      query_param &&
      query_param?.sid &&
      typeof query_param?.sid !== 'object'
    ) {
      setShowScheduleBooking(true);
      //setScheduleId(query_param?.sid);
    }
  }, [query_param]);

  return (
    <div>
      {showScheduleBooking && query_param?.sid ? (
        <HshsScheduleBooking scheduleId={query_param?.sid} />
      ) : (
        <HshsBranches />
      )}
    </div>
  );
};

export default HshsBooking;
