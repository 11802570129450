import { api } from '../helpers/api';
import { RouteConstants } from '../helpers/constants';

export const fetchHshsScheduleDetails = async (
  token: string,
  id: string | undefined
): Promise<any> => {
  if (token && id) {
    try {
      const { data } = await api.get(
        `${RouteConstants.SEARCH_LOADING_POINT_SCHEDULES}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data?.payload;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error?.message);
    }
  }
};
