import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import homeImg from '../../assets/img/home.svg';
import homeActiveImg from '../../assets/img/home_active.svg';
import teacherImg from '../../assets/img/teacher.png';
import teacherActiveImg from '../../assets/img/teacher_active.png';

interface navProps {
  activeIndex: number;
}

interface MenuType {
  id: number;
  icon: React.ReactNode;
  name: string;
  route: string;
  activeIcon: React.ReactNode;
}

const BottomNav = ({ activeIndex }: navProps) => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState(1);

  //get active tab on load
  useEffect(() => {
    setActiveMenu(activeIndex);
  }, [activeIndex]);

  const menuList: Array<MenuType> = [
    {
      id: 1,
      icon: <img src={homeImg} alt='home' />,
      activeIcon: <img src={homeActiveImg} alt='Home active' />,
      name: 'Home',
      route: '/',
    },
    {
      id: 2,
      icon: <img src={teacherImg} alt='Hshs active' className='w-7 h-7' />,
      activeIcon: (
        <img src={teacherActiveImg} alt='Hshs active' className='w-7 h-7' />
      ),
      name: 'HSHS',
      route: '/mb-hshs',
    },
    // {
    //   id: 3,
    //   activeIcon: (
    //     <img
    //       src={require("../../assets/img/activity_active.png")}
    //       alt="Activities"
    //       className="w-7 h-7"
    //     />
    //   ),
    //   icon: (
    //     <img
    //       src={require("../../assets/img/activity.png")}
    //       alt="Activies"
    //       className="w-7 h-7"
    //     />
    //   ),
    //   name: "Activies",
    //   route: "/activities",
    // },
    {
      id: 3,
      activeIcon: <Cog6ToothIcon className='w-7 h-7 text-white' />,

      icon: <Cog6ToothIcon className='w-7 h-7 text-[#939291]' />,
      route: '/mobile-settings',
      name: 'Settings',
    },
  ];

  const handleNavigation = (selected: any, index: number) => {
    setActiveMenu(index);
    navigate(selected.route);
  };

  return (
    <Fragment>
      <div className='h-[80px] nav-bar relative'>
        {menuList.map((menu: MenuType, i: number) => (
          <button
            key={i}
            className={`${
              activeMenu === menu.id
                ? 'text-white bg-oya-red-100 h-12 w-12'
                : 'text-neutral-300'
            }  rounded-md flex justify-center items-center mr-3`}
            onClick={() => handleNavigation(menu, menu.id)}
          >
            <div className='flex flex-col justify-center items-center'>
              {activeMenu === menu.id ? (
                <div>{menu.activeIcon}</div>
              ) : (
                <div className='flex flex-col justify-center items-center text-[10px] text-neutral-400'>
                  <div>{menu.icon}</div>
                  <div className='pt-1'>{menu.name}</div>
                </div>
              )}
            </div>
          </button>
        ))}
      </div>
    </Fragment>
  );
};

export default BottomNav;
