import { Fragment, useState, useEffect } from "react";
import { Checkbox, Space, Drawer } from "antd";
import { debounce } from "lodash";
import { getCookie } from "../../helpers/utils";
import { api } from "../../helpers/api";
import AsyncSelect from "react-select/async";
import { RouteConstants } from "../../helpers/constants";
import { components } from "react-select";

type Comfort = "PREM" | "REG";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.5999 7.4585L11.1666 12.8918C10.5249 13.5335 9.4749 13.5335 8.83324 12.8918L3.3999 7.4585"
          stroke="#484442"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const FilterSchedule = ({
  isOpen,
  handleOpenDrawer,
  handleReset,
  applyFilter,
}: {
  isOpen: boolean;
  handleOpenDrawer: () => void;
  handleReset: () => void;
  applyFilter: (busType: any | null, comfort: Comfort | undefined) => void;
}) => {
  const [comfortType, setComfortType] = useState<Comfort | undefined>();

  const [selectedBusTypes, setSelectedBusType] = useState<any>([]);

  const token = getCookie("oya_token");

  const [types, setTypes] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const getBusTypes = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(RouteConstants.BUS_TYPE, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setTypes(
        data?.payload?.items?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadBusTypes = debounce(
    async (inputValue: string, callback: (option: any) => void) => {
      try {
        const filter = [
          {
            f: "name",
            o: "contains",
            p: [`${inputValue}`],
          },
        ];
        const { data } = await api.get(
          `${RouteConstants.BUS_TYPE}?filters=${JSON.stringify(filter)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const res = data?.payload?.items?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        }));

        callback(res);
      } catch (error) {
        console.log(error);
      }
    },
    1500
  );

  useEffect(() => {
    getBusTypes();

    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Drawer
        placement="bottom"
        footer={null}
        open={isOpen}
        closable={false}
        className=" rounded-t-[30px] bg-white"
        onClose={() => handleOpenDrawer()}
      >
        <div>
          <div className="mb-3 flex items-center justify-between">
            <div className="text-lg font-semibold">Filter by</div>
            <button onClick={handleOpenDrawer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M10.3519 21.9445C10.0083 21.9445 9.66463 21.8179 9.39335 21.5466C8.86888 21.0222 8.86888 20.1541 9.39335 19.6296L19.6296 9.39335C20.1541 8.86888 21.0222 8.86888 21.5466 9.39335C22.0711 9.91783 22.0711 10.7859 21.5466 11.3104L11.3104 21.5466C11.0572 21.8179 10.6955 21.9445 10.3519 21.9445Z"
                  fill="#484442"
                />
                <path
                  d="M20.5881 21.9445C20.2445 21.9445 19.9009 21.8179 19.6296 21.5466L9.39335 11.3104C8.86888 10.7859 8.86888 9.91783 9.39335 9.39335C9.91783 8.86888 10.7859 8.86888 11.3104 9.39335L21.5466 19.6296C22.0711 20.1541 22.0711 21.0222 21.5466 21.5466C21.2754 21.8179 20.9317 21.9445 20.5881 21.9445Z"
                  fill="#484442"
                />
              </svg>
            </button>
          </div>

          <div className="mb-5">
            <div className="text-oya-gray font-semibold">Comfort</div>
            <div className="mobile-comfort-checkbox mt-2">
              <Space direction="vertical">
                <Checkbox
                  checked={comfortType === "PREM" ? true : false}
                  onClick={() => {
                    setComfortType("PREM");
                  }}
                >
                  Premium
                </Checkbox>
                <Checkbox
                  checked={comfortType === "REG" ? true : false}
                  onClick={() => {
                    setComfortType("REG");
                  }}
                >
                  Regular
                </Checkbox>
              </Space>
            </div>
          </div>

          <hr />
          <div className="mt-5">
            <div className="text-oya-gray font-semibold mb-2">Bus type</div>
            <AsyncSelect
              placeholder="Select bus type"
              components={{ DropdownIndicator }}
              isMulti
              styles={{
                container(base, props) {
                  return {
                    ...base,
                    height: "40px",
                    borderWidth: "1px",
                    borderRadius: "12px",
                    borderColor: "#939291",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#fff",
                  };
                },
                control(base, props) {
                  return {
                    ":active": {
                      borderColor: "#ccc",
                    },
                    width: "100%",
                    display: "flex",
                  };
                },
              }}
              value={selectedBusTypes}
              defaultOptions={types}
              isLoading={loading}
              isClearable
              loadOptions={(inputValue, callback) => {
                loadBusTypes(inputValue, callback);
              }}
              onChange={(value: any) => {
                setSelectedBusType(value);
                //console.log(value);
              }}
            />
          </div>
          <div className="flex items-center justify-evenly mt-8">
            <button
              onClick={() => {
                //setcomfortType(null);
                setComfortType(undefined);
                setSelectedBusType(undefined);
                handleReset();
                handleOpenDrawer();
              }}
              className="btn-subtle"
            >
              Clear filters
            </button>
            <button
              onClick={() => {
                if (selectedBusTypes) {
                  applyFilter(selectedBusTypes, comfortType);
                  handleOpenDrawer();
                }

                if (comfortType) {
                  applyFilter(selectedBusTypes, comfortType);
                  handleOpenDrawer();
                }
              }}
              className="rounded-[8px] bg-oya-red-100 py-2 px-8 text-white "
            >
              Apply
            </button>
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default FilterSchedule;
