import React from "react";
import { useNavigate } from "react-router-dom";
import notFoundImg from "../assets/img/not_found.svg";
import CustomButton from "../components/CustomBtn";

type Props = {};

const PageNotFound = (props: Props) => {
    const navigate = useNavigate();
    return (
        <div className="flex justify-center items-center h-screen">
            <div>
                <img
                    className="w-[250px] lg:w-[400px]"
                    src={notFoundImg}
                    alt="404"
                />
                <div className="mt-4 flex flex-col items-center justify-center">
                    <p className="font-semibold text-2xl">
                        Oops! Page Not Found
                    </p>

                    <CustomButton
                        data-testid="goBackHome"
                        onClick={() => navigate("/")}
                    >
                        Go Back Home
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
