import { ISeat } from "../types";
import { api } from "./api";
import {
  DB_PREF,
  COOKIES_DOMAIN,
  COOKIES_SECURED,
  RouteConstants,
  ACCOUNTS_URL,
} from "./constants";
import moment from "moment";

export const redirect = (to: any) => {
  window.location = to;
};

export const stringToBase64 = (str: string) => {
  const base64String = btoa(str);
  return base64String;
};

export const getDaysUntilJwtExpiration = (jwtToken: string) => {
  const tokenParts = jwtToken.split(".");
  if (tokenParts.length !== 3) {
    throw new Error("Invalid JWT format");
  }

  const payload = JSON.parse(atob(tokenParts[1]));

  if (!payload.exp) {
    throw new Error("JWT does not contain an expiration claim (exp)");
  }

  const expirationTimestamp = payload.exp;
  const currentTimestamp = Math.floor(Date.now() / 1000);

  const secondsUntilExpiration = expirationTimestamp - currentTimestamp;
  const daysUntilExpiration = Math.ceil(
    secondsUntilExpiration / (60 * 60 * 24)
  );

  return daysUntilExpiration;
};

export const getCookie = (cookieName: string) => {
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(";");
  for (let index = 0; index < cookiesArray.length; index++) {
    let cookie = cookiesArray[index];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};

export async function copyTextToClipboard(text: string) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export const setCookie = (cname: string, cvalue: any, exdays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${
    DB_PREF + cname
  }=${cvalue};${expires};path=/;domain=${COOKIES_DOMAIN};SameSite=Strict;${
    COOKIES_SECURED ? "Secure" : ""
  }`;
};

export const delCookie = (cname: string) => {
  //console.log(cname);
  window.document.cookie = `${cname}=;domain=${COOKIES_DOMAIN};path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const setCookieJson = (cname: string, cvalue: any, exdays = 7) => {
  setCookie(cname, JSON.stringify(cvalue), exdays);
};

export const disabledDate = (current: any) => {
  // Can not select days before today and today
  return current && current < moment().startOf("day");
};

export const logout = () => {
  window.localStorage.clear();
  redirect(`${ACCOUNTS_URL}/logout?appref=${window.location.origin}/`);
};

export const networks = [
  {
    value: "MTN_GH",
    label: "MTN Mobile Money",
  },
  {
    value: "TIGO_GH",
    label: "AirtelTigo Money",
  },
  {
    value: "VODAFONE_GH",
    label: "Telecel Cash",
  },
];

export const app = {
  version: require("../../package.json").version,
  dbpref: "oya_",
  cookieDomain: process.env.REACT_APP_COOKIES_DOMAIN || "localhost",
};

export const getTransactionStatus = async (id: string) => {
  const maxAttempts = 10;
  const token = getCookie("oya_token");

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      // console.log(`Attempt ${attempt}`);

      const response = await api.get(
        `${RouteConstants.TRANSACTIONS}/${id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const statusCode = response?.data?.payload?.status?.code;
      if (statusCode === "SUCCESS" || statusCode === "FAILED") {
        return response?.data?.payload;
      }

      // Wait for a while before the next attempt
      await new Promise((resolve) => setTimeout(resolve, 8000));
    } catch (error) {
      // Handle error if needed
      return error;
    }
  }

  // If not successful after 15 attempts, return an error or handle it accordingly
  return { error: "Max attempts reached, transaction status not updated." };
};

export const getGuestTransactionStatus = async (id: string) => {
  const maxAttempts = 10;

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      // console.log(`Attempt ${attempt}`);

      const response = await api.get(`${RouteConstants.GUEST_TICKET}/${id}`);

      const statusCode = response?.data?.payload?.payment_status?.code;
      if (statusCode === "PAID" || statusCode === "FAILED") {
        return response?.data?.payload;
      }

      // Wait for a while before the next attempt
      await new Promise((resolve) => setTimeout(resolve, 8000));
    } catch (error) {
      // Handle error if needed
      return error;
    }
  }

  // If not successful after 15 attempts, return an error or handle it accordingly
  return { error: "Max attempts reached, transaction status not updated." };
};

export const getTime = (time: string) => {
  const travelTime = new Date(`1970-01-01T${time}Z`);
  return moment(travelTime).format("LT");
};

export const validateUserName = (_: any, value: string | undefined) => {
  if (!value || /^[A-Za-z]+$/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Only alphabets are allowed!"));
};

export const checkFieldsComplete = (seat: ISeat | undefined) => {
  switch (seat?.occupant_type) {
    case "ADULT":
      return seat?.passenger?.first_name &&
        seat?.passenger?.last_name &&
        seat?.passenger?.ice_phone &&
        seat?.passenger?.phone
        ? true
        : false;
    case "MINOR":
      return seat?.passenger?.first_name &&
        seat?.passenger?.last_name &&
        seat?.passenger?.ice_phone
        ? true
        : false;
    case "LAGGAGE":
      return seat?.passenger?.phone ? true : false;
    default:
      return false;
  }
};
