import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

type PaginationProps = {
  current: number;
  total: number;
  pageSize: number;
  onChange: (page: number) => void;
};

const Pagination = ({
  current,
  total,
  pageSize,
  onChange,
}: PaginationProps) => {
  const totalPages = Math.ceil(total / pageSize);
  //const maxPageNumbersToShow = 5;

  const handleClick = (page: number) => {
    if (page !== current && page >= 1 && page <= totalPages) {
      onChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5; // Adjust as needed

    if (totalPages <= maxPageNumbersToShow) {
      // Display all pages if total pages are within the threshold
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li
            key={i}
            className={`relative cursor-pointer inline-flex items-center px-4 py-2 text-sm font-semibold text-oya-gray border rounded-lg border-gray-100 focus:z-20 focus:outline-offset-0 transition-all ${
              i === current
                ? 'bg-oya-red-100 hover:bg-red-500'
                : 'hover:bg-gray-50'
            }`}
            onClick={() => handleClick(i)}
          >
            <span className={`${i === current ? 'text-white' : ''}`}>{i}</span>
          </li>
        );
      }
    } else {
      // Display start pages, ellipsis, current-range pages, ellipsis, end pages
      const startPages = [1, 2];
      const endPages = [totalPages - 1, totalPages];
      const nearbyPages = [current - 1, current, current + 1].filter(
        (page) => page > 2 && page < totalPages - 1
      );

      // Helper to render a single page item
      const renderPageItem = (page: number) => (
        <li
          key={page}
          className={`relative cursor-pointer inline-flex items-center px-4 py-2 text-sm font-semibold text-oya-gray rounded-lg focus:z-20 focus:outline-offset-0 transition-all ${
            page === current
              ? 'bg-oya-red-100 hover:bg-red-500'
              : 'hover:bg-gray-50 bg-oya-gray-50'
          }`}
          onClick={() => handleClick(page)}
        >
          <span className={`${page === current ? 'text-white' : ''}`}>
            {page}
          </span>
        </li>
      );

      // Render start pages
      startPages.forEach((page) => pageNumbers.push(renderPageItem(page)));

      // Add ellipsis if needed
      if (nearbyPages[0] > 3) {
        pageNumbers.push(
          <li key='start-ellipsis' className='px-2'>
            ...
          </li>
        );
      }

      // Render nearby pages
      nearbyPages.forEach((page) => pageNumbers.push(renderPageItem(page)));

      // Add ellipsis if needed
      if (nearbyPages[nearbyPages.length - 1] < totalPages - 2) {
        pageNumbers.push(
          <li key='end-ellipsis' className='px-2'>
            ...
          </li>
        );
      }

      // Render end pages
      endPages.forEach((page) => pageNumbers.push(renderPageItem(page)));
    }

    return pageNumbers;
  };

  return (
    <div className='flex items-end justify-end  px-4 py-3 sm:px-6'>
      <div className='flex flex-1 justify-between sm:hidden'>
        <button
          type='button'
          disabled={current === 1}
          className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
            current === 1 ? 'disabled opacity-50' : ' opacity-100'
          }`}
          onClick={() => handleClick(current - 1)}
        >
          Previous
        </button>
        <button
          type='button'
          className={`relative ml-3 inline-flex items-center rounded-md border px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 ${
            current === totalPages ? 'disabled opacity-50' : ' opacity-100'
          }`}
          disabled={current === totalPages}
          onClick={() => handleClick(current + 1)}
        >
          Next
        </button>
      </div>
      <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-end'>
        <div>
          <nav aria-label='Page navigation'>
            <ul className='isolate inline-flex gap-3  items-center'>
              <button
                className={`relative inline-flex items-center bg-oya-gray-50 rounded-lg px-2 py-2 text-oya-gray hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                  current === 1 ? 'disabled opacity-50' : ' opacity-100'
                }`}
                disabled={current === 1}
                onClick={() => handleClick(current - 1)}
              >
                <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                <span className=''>Prev. page</span>
              </button>
              {renderPageNumbers()}
              <button
                className={`relative inline-flex items-center bg-oya-gray-50 rounded-lg px-2 py-2 text-oya-gray hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                  current === totalPages ? 'disabled opacity-50' : 'opacity-100'
                }`}
                onClick={() => handleClick(current + 1)}
                disabled={current === totalPages}
              >
                <span className=''>Next page</span>
                <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
              </button>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
