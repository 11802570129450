import { useState, useEffect } from "react";
import { Skeleton, Row, Col } from "antd";
import SearchFilter from "../components/Search";
import { Schedule } from "../types";
import { api } from "../helpers/api";
import CustomButton from "../components/CustomBtn";
import notFound from "../assets/img/not_found.svg";
import ScheduleCard from "../components/Schedules/ScheduleCard";
import ReactGA from "react-ga";
import BusType from "../components/Filters/BusType";
import Comfort from "../components/Filters/Comfort";
import { RouteConstants } from "../helpers/constants";
import Pagination from "../components/Pagination";
import { useNavigate } from "react-router-dom";

const SearchTrip = () => {
  const navigate = useNavigate();
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const [pagination, setPagination] = useState({
    pageSize: 12,
    pageIndex: 0,
  });
  const [busType, setBusType] = useState<any>([]);
  const [isPremium, setIsPremium] = useState<any>();

  const [pickupId, setPickupId] = useState<number | undefined>();
  const [destinationId, setDestinationId] = useState<number | undefined>();

  const getRides = async () => {
    setLoading(true);
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endofDay = new Date();
    endofDay.setHours(23, 59, 59, 9999);

    let defaultFilter = [];

    defaultFilter.push(
      {
        f: "created_at",
        o: "between",
        p: [`${startOfDay.toISOString()}`, `${endofDay.toISOString()}`],
      },
      {
        f: "status",
        o: "in",
        p: ["LOADING", "SCALED"],
      }
    );

    // if (tripCode) {
    //   defaultFilter.push({
    //     f: "code",
    //     o: "=",
    //     p: [`${tripCode}`],
    //   });
    // }

    if (busType && busType.length > 0) {
      //console.log(busType);
      let bus_types = busType?.map((item: any) => `${item?.value}`);
      defaultFilter.push({
        f: "bus_type.id",
        o: "in",
        p: bus_types,
      });
    }

    if (isPremium === false || isPremium === true) {
      defaultFilter.push({
        f: "premium",
        o: "=",
        p: [isPremium],
      });
    }

    if (pickupId && destinationId) {
      defaultFilter.push(
        {
          f: "route.from.id",
          o: "=",
          p: [pickupId],
          r: "AND",
        },
        {
          f: "route.to.id",
          o: "=",
          p: [destinationId],
        }
      );
    } else if (pickupId) {
      defaultFilter.push({
        f: "route.from.id",
        o: "=",
        p: [pickupId],
      });
    } else if (destinationId) {
      defaultFilter.push({
        f: "route.to.id",
        o: "=",
        p: [destinationId],
      });
    }

    try {
      const { data } = await api.get(
        `${RouteConstants.SCHEDULES_SEARCH}?filters=${JSON.stringify(
          defaultFilter
        )}&limit=${pagination.pageSize}&offset=${Number(
          pagination.pageSize * pagination.pageIndex
        )}`
      );

      setTotal(data?.payload?.total);
      setSchedules(data?.payload?.items);

      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const handlePagination = (page: number) => {
    setPagination({
      ...pagination,
      pageIndex: page - 1,
    });
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    getRides();
    // eslint-disable-next-line
  }, [
    pickupId,
    destinationId,
    // tripCode,
    pagination.pageIndex,
    pagination.pageSize,
    busType,
    isPremium,
  ]);

  return (
    <div>
      <div className="mb-8">
        <SearchFilter
          handleSearch={(pickup, destination) => {
            setPickupId(pickup);
            setDestinationId(destination);
          }}
        />
      </div>

      <div className="flex gap-3">
        <div className="w-full h-screen">
          <div className="flex items-center w-full justify-between lg:justify-start">
            <div className="font-semibold m-3 sm:text-[24px] text-lg text-oya-gray lg:flex-[0.3]">
              Available Trips
            </div>
            <div className="flex gap-4  items-center flex-[0.65]">
              <Comfort
                handleChange={(value) => {
                  setIsPremium(value);
                }}
              />
              <BusType
                handleChange={(id) => {
                  setBusType(id);
                }}
              />
            </div>
          </div>

          {loading ? (
            <Row className="mx-3 gap-3">
              <Col xs={24} md={12} lg={7}>
                <div className="p-2 border shadow rounded-md">
                  <Skeleton paragraph active />
                </div>
              </Col>
              <Col xs={24} md={12} lg={7}>
                <div className="p-2 border shadow rounded-md">
                  <Skeleton paragraph active />
                </div>
              </Col>
              <Col xs={24} md={12} lg={7}>
                <div className="p-2 border shadow rounded-md">
                  <Skeleton paragraph active />
                </div>
              </Col>
            </Row>
          ) : (
            <>
              {schedules.length < 1 ? (
                <div className=" py-3 ">
                  <div className="flex flex-col justify-center items-center h-full">
                    <img
                      className="w-[50%] sm:w-[40%] h-auto mt-8"
                      src={notFound}
                      alt="404"
                    />
                    <div className="font-bold sm:text-xl text-center mt-8 text-gray-500">
                      No schedules available
                    </div>
                    <div className="mt-2">
                      <CustomButton
                        onClick={() => {
                          navigate("/book-advance");
                        }}
                      >
                        Book for advance ticket
                      </CustomButton>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
                    {schedules.map((item, index) => (
                      <div className="w-full min-w-[220px]" key={index}>
                        <ScheduleCard item={item} />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}

          <div className="pt-10 w-full flex justify-end items-end">
            {total > pagination.pageSize && (
              <div className="pagination w-full justify-end items-end">
                <Pagination
                  pageSize={pagination.pageSize}
                  current={pagination.pageIndex + 1}
                  total={total}
                  onChange={(page) => {
                    handlePagination(page);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchTrip;
