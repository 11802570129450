import React from "react";
import { data } from "../../helpers/constants";

type Props = {};

const More = (props: Props) => {
    return (
        <div className="bg-white flex justify-center items-center px-[1rem] lg:px-[6rem] xl:px-[8rem] py-5">
            <div>
                <div>
                    <div className="text-[24px] text-center sm:text-[30px] lg:text-[50px] lg:leading-[50px] font-black pb-8">
                        Do more with <span className="text-red-700">Oya!</span>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 m-1 mt-4">
                    {data.map((item) => (
                        <div
                            key={item.id}
                            className="text-white custom-card-container mb-3"
                        >
                            <img
                                src={item.image}
                                alt=""
                                className="rounded-lg w-full"
                            />
                            <div className="custom-card-overlay rounded-lg flex justify-end items-center flex-col p-4">
                                <div>
                                    <div className="sm:text-[24px] font-bold pb-2">
                                        {item.title}
                                    </div>
                                    <div className="text-[12px] sm:text-base">
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center pl-[2rem] sm:pl-0 items-center sm:gap-2 mt-4 ">
                    <button
                        data-testid="playStore1"
                        onClick={() => {
                            window.open(
                                "https://play.google.com/store/apps/details?id=com.oyaghana.oyaapp_porter&pli=1",
                                "_blank"
                            );
                        }}
                        className=" hover:bg-opacity-70"
                    >
                        <img
                            className="sm:w-full w-[70%]"
                            src="https://res.cloudinary.com/djp5oy3ri/image/upload/v1701973247/beehive_oya/way8jnbzu0tjemcbsdcl.svg"
                            alt=""
                        />
                    </button>
                    <button
                        data-testid="appStore1"
                        onClick={() => {
                            window.open(
                                "https://apps.apple.com/us/app/oya/id1623866371",
                                "_blank"
                            );
                        }}
                        className=""
                    >
                        <img
                            className="sm:w-full w-[70%]"
                            src="https://res.cloudinary.com/djp5oy3ri/image/upload/v1701954978/beehive_oya/z3stgfbiz4ymqky3z5po.svg"
                            alt=""
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default More;
