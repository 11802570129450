import { api } from '../helpers/api';
import { RouteConstants } from '../helpers/constants';

export const fetchTicketManifest = async (tn: string): Promise<any> => {
  if (tn) {
    try {
      const { data } = await api.post(`${RouteConstants.TICKET_MANIFEST}`, {
        code: tn,
      });

      return data?.payload;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error?.message);
    }
  }
};
