import { useState, useEffect } from 'react';
import { RouteConstants } from '../../helpers/constants';
import { Row, Col, Skeleton, Pagination } from 'antd';
import { api } from '../../helpers/api';
import CustomButton from '../CustomBtn';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import notFound from '../../assets/img/not_found.svg';
import LoadingPointScheduleCard from '../Schedules/LoadingPointScheduleCard';
import Search from './Search';
import { useDebounce } from '@uidotdev/usehooks';
import HshsMobileHome from '../../mobile/hshs/HshsMobileHome';

function HshsSchedules() {
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string | null>('');
  const debounceSearchName = useDebounce(searchValue, 1200);
  const [isMobile, setIsMobile] = useState(true);

  const [pagination, setPagination] = useState({
    pageSize: 12,
    pageIndex: 0,
  });

  const handlePagination = (page: number) => {
    setPagination({
      ...pagination,
      pageIndex: page - 1,
    });
  };

  const searchLoadingPointSchedules = async (search: string | null) => {
    const filter: any[] = [];
    setLimit(50);
    try {
      if (search && search.trim().length > 0) {
        filter.push(
          {
            f: 'loading_point.source.name',
            o: 'contains',
            p: [`${search}`],
            c: 'OR',
          },
          {
            f: 'loading_point.destination.name',
            o: 'contains',
            p: [`${search}`],
            c: 'OR',
          },
          {
            f: 'loading_point.label',
            o: 'contains',
            p: [`${search}`],
          }
        );
      }

      const { data } = await api.get(
        `${
          RouteConstants.SEARCH_LOADING_POINT_SCHEDULES
        }?filters=${JSON.stringify(filter)}&limit=${limit}`
      );

      setTotal(data?.payload?.total);

      return data?.payload?.items;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error?.message);
    }
  };

  const { isLoading, data, isSuccess } = useQuery({
    queryKey: ['search-loading-point-schedules', debounceSearchName],
    queryFn: () => {
      return searchLoadingPointSchedules(searchValue);
    },
  });

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkScreenSize);

    checkScreenSize();

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  if (isMobile) {
    return <HshsMobileHome />;
  }

  return (
    <div className='flex gap-3 xl:mr-[8rem] lg:mr-[6rem]'>
      <div className='w-full h-screen'>
        <div className='my-5 w-full flex justify-center items-center'>
          <Search
            handleChange={(value) => {
              setSearchValue(value);
            }}
          />
        </div>
        <div className='font-semibold m-3 sm:text-[20px] text-lg text-oya-gray lg:flex-[0.3] pb-5'>
          Available Trips
        </div>
        {isLoading ? (
          <Row className='mx-3 gap-3'>
            <Col xs={24} md={12} lg={7}>
              <div className='p-2 border shadow rounded-md'>
                <Skeleton paragraph active />
              </div>
            </Col>
            <Col xs={24} md={12} lg={7}>
              <div className='p-2 border shadow rounded-md'>
                <Skeleton paragraph active />
              </div>
            </Col>
            <Col xs={24} md={12} lg={7}>
              <div className='p-2 border shadow rounded-md'>
                <Skeleton paragraph active />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            {isSuccess ? (
              <>
                {data.length < 1 ? (
                  <div className=' py-3 '>
                    <div className='flex flex-col justify-center items-center h-full'>
                      <img
                        className='w-[50%] sm:w-[40%] h-auto mt-8'
                        src={notFound}
                        alt='404'
                      />
                      <div className='font-bold sm:text-xl text-center mt-8 text-gray-500'>
                        No schedules available
                      </div>
                      <div className='mt-2'>
                        <CustomButton
                          onClick={() => {
                            navigate('/book-advance');
                          }}
                        >
                          Book for advance ticket
                        </CustomButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5'>
                      {data.map((item: any, index: number) => (
                        <div className='w-full min-w-[220px]' key={index}>
                          <LoadingPointScheduleCard item={item} />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className='w-full flex justify-center items-center'>
                <div className='mt-[10%] text-center'>
                  An unexpected error occured
                </div>
              </div>
            )}
          </>
        )}

        <div className='pt-10 w-full flex justify-end items-end'>
          {total > pagination.pageSize && (
            <div className='pagination w-full justify-end items-end'>
              <Pagination
                pageSize={pagination.pageSize}
                current={pagination.pageIndex + 1}
                total={total}
                onChange={(page) => {
                  handlePagination(page);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HshsSchedules;
