import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { api } from '../../helpers/api';
import { components } from 'react-select';
import { RouteConstants } from '../../helpers/constants';
import { getCookie } from '../../helpers/utils';
import { debounce } from 'lodash';

type Props = {
  handleChange: (id: string) => void;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M16.5999 7.4585L11.1666 12.8918C10.5249 13.5335 9.4749 13.5335 8.83324 12.8918L3.3999 7.4585'
          stroke='#484442'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const BusType = ({ handleChange }: Props) => {
  const token = getCookie('oya_token');

  const [types, setTypes] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const getBusTypes = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(RouteConstants.BUS_TYPE, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setTypes(
        data?.payload?.items?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadBusTypes = debounce(
    async (inputValue: string, callback: (option: any) => void) => {
      try {
        const filter = [
          {
            f: 'name',
            o: 'contains',
            p: [`${inputValue}`],
          },
        ];
        const { data } = await api.get(
          `${RouteConstants.BUS_TYPE}?filters=${JSON.stringify(filter)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const res = data?.payload?.items?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        }));

        callback(res);
      } catch (error) {
        console.log(error);
      }
    },
    1500
  );

  useEffect(() => {
    getBusTypes();

    // eslint-disable-next-line
  }, []);

  return (
    <AsyncSelect
      placeholder='Bus type'
      components={{ DropdownIndicator }}
      isMulti
      styles={{
        container(base, props) {
          return {
            ...base,
            height: '40px',
            borderWidth: '1px',
            borderRadius: '12px',
            borderColor: '#939291',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#fff',
          };
        },
        control(base, props) {
          return {
            ':active': {
              borderColor: '#ccc',
            },
            width: '100%',
            display: 'flex',
          };
        },
      }}
      defaultOptions={types}
      isLoading={loading}
      isClearable
      loadOptions={(inputValue, callback) => {
        loadBusTypes(inputValue, callback);
      }}
      onChange={(value: any) => {
        handleChange(value);
        //console.log(value);
      }}
    />
  );
};

export default BusType;
