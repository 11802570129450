import React from "react";
// import Header from '../components/Beehive/Header';
// import Hero from '../components/Beehive/Hero';
import Buses from "../components/Beehive/Buses";
import Footer from "../components/Beehive/Footer";
import { useMediaQuery } from "@mui/material";
import HeaderLarge from "../components/Beehive/HeaderLarge";
import HeaderSmall from "../components/Beehive/HeaderSmall";
// import HeroTwoSmall from '../components/Beehive/HeroTwoSmall';
import Step from "../components/Beehive/Step";
import FooterSmall from "../components/Beehive/FooterSmall";
import HeroTwoLarge from "../components/Beehive/HeroTwoLarge";
import { useParams } from "react-router-dom";
import More from "../components/Beehive/More";
import { Helmet } from "react-helmet";

const BeehiveLanding = ({ type = "branches" }) => {
  const isLarge = useMediaQuery("(min-width:500px)");
  const params = useParams();

  return (
    <div className="bg-gray-50">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Busride</title>
        <meta
          name="description"
          content="Book a trip using the Oya! busride app"
        />
        <meta property="og:title" content="Busride" />
        {/* <meta
          property='og:description'
          content='Join the extraordinary Outmosphere Eco-Friendly Festival on December 9th, 2023, in Aburi, Ghana. Immerse yourself in a celebration of nature, music, and unforgettable vibes!'
        />
        <meta
          property='og:image'
          content='https://res.cloudinary.com/djp5oy3ri/image/upload/v1701954987/beehive_oya/bqe8pb0xvdqevunanpfe.png'
        /> */}

        {/* Google tag */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-S5HMEJ59JG"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){
              dataLayer.push(arguments)
            }

            gtag('js', new Date()); gtag('config', 'G-S5HMEJ59JG');
          `}
        </script>
      </Helmet>
      {isLarge ? <HeaderLarge /> : <HeaderSmall />}
      {/* {!isLarge && <Hero />} */}
      {/* <Hero /> */}
      {/* {isLarge ? <HeroTwoLarge /> : <HeroTwoSmall />} */}
      <HeroTwoLarge />
      <Step />
      <Buses param={Number(params?.id)} type={type} />
      <More />
      {isLarge ? <Footer /> : <FooterSmall />}
    </div>
  );
};

export default BeehiveLanding;
