import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHSHS_SCHEDULE, User } from '../types';

type HshsBookingPayload = {
  passenger?: {
    first_name: string;
    last_name: string;
    phone: string;
    ice_phone: string;
  };
  message?: string;
  luggage_description?: string;
  luggage_sizes?: Array<any>;
};
export interface UserState {
  user: User | undefined;
  hshs_schedule: IHSHS_SCHEDULE | undefined;
  luggage_sizes: Array<any>;
  hshs_booking_payload: HshsBookingPayload | undefined;
}

const initialState: UserState = {
  user: undefined,
  hshs_schedule: undefined,
  luggage_sizes: [],
  hshs_booking_payload: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setHshsBookingPayload: (
      state,
      action: PayloadAction<HshsBookingPayload | undefined>
    ) => {
      state.hshs_booking_payload = action.payload;
    },
    setHshsSchedule: (
      state,
      action: PayloadAction<IHSHS_SCHEDULE | undefined>
    ) => {
      state.hshs_schedule = action.payload;
    },

    setLuggages: (state, action: PayloadAction<any>) => {
      state.luggage_sizes = action.payload;
    },
  },
});

export const { setUser, setHshsSchedule, setLuggages, setHshsBookingPayload } =
  userSlice.actions;

export default userSlice.reducer;
