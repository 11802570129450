import React from "react";
import { Modal, notification } from "antd";
import cookie from "js-cookie";
import { app } from "../../helpers/utils";

type Props = {
  visible: boolean;
  handleClose: () => void;
  appref?: string;
};

const LogoutModal = ({ visible, handleClose, appref }: Props) => {
  const handleLogout = async () => {
    try {
      window.localStorage.clear();

      cookie.remove(`oya_token`, {
        path: "/",
        domain: app.cookieDomain,
        expires: -1,
      });
      cookie.remove(`oya_user`, {
        path: "/",
        domain: app.cookieDomain,
        expires: -1,
      });
      cookie.remove(`oya_usr`, {
        path: "/",
        domain: app.cookieDomain,
        expires: -1,
      });
      cookie.remove(`oya_user_info`, {
        path: "/",
        domain: app.cookieDomain,
        expires: -1,
      });
      cookie.remove(`oya_selected_user_role`, {
        path: "/",
        domain: app.cookieDomain,
        expires: -1,
      });
      cookie.remove(`oya_selected_service`, {
        path: "/",
        domain: app.cookieDomain,
        expires: -1,
      });

      if (appref) {
        window.location.href = `/signin?appref=${appref}`;
      } else {
        window.location.href = "/signin";
      }
    } catch (error: any) {
      notification.error({
        message: error?.message,
      });
    }
  };
  return (
    <Modal closable open={visible} onCancel={() => handleClose()} footer={null}>
      <div className="rounded-xl bg-white ">
        <div className="header-text-3">Log out</div>
        <div>Are you sure you want to log out from this device?</div>

        <div className="w-full justify-end items-end flex-row flex mt-10">
          <div className="flex gap-5 items-center">
            <button
              onClick={() => {
                handleClose();
              }}
            >
              <div className="font-medium text-oya-gray">Cancel</div>
            </button>
            <button
              onClick={() => {
                handleLogout();
              }}
            >
              <div className="font-medium text-oya-gray">Log out</div>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
