import { Fragment, useState, useEffect } from "react";
import { User } from "../../types";
import { RouteConstants } from "../../helpers/constants";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import PhoneInput, { Value } from "react-phone-number-input";
import { Radio } from "@material-tailwind/react";
import { message, notification, Select, Modal } from "antd";
import axios from "axios";
import { Input } from "antd";
import { accounts, api } from "../../helpers/api";
import { getCookie } from "../../helpers/utils";

const EditPersonal = ({
  handleStepChange,
}: {
  handleStepChange: () => void;
}) => {
  const [userPersonal, setPersonalData] = useState<User | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [middle_name, setMiddleName] = useState<string | null>();
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState<any | null>();
  const [email, setEmail] = useState<string | null>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUpload, setImageUpload] = useState<File | null>(null);

  const [imageModal, setImageModal] = useState(false);
  const [ice_phone, setIcePhone] = useState<Value | undefined>("");
  const [network_providers, setNetworkProviders] = useState<any>([]);
  const [payment_number, setPaymentNumber] = useState<Value | undefined>("");
  const [selectedProvider, setSelectedProvider] = useState<any>();

  const token = getCookie("oya_token");

  // logic to read image as
  const getBase64 = (img: File, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  // logic to varify image before its uploaded
  const beforeUpload = (file: File) => {
    // check if selected file is an image
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    //check if file size is less than 2megabite
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  // logic to change image from the file system
  //   const handleUploadChange: UploadProps["onChange"] = async (info) => {
  //     if (info.file.status === "uploading") {
  //       setLoading(true);
  //       return;
  //     }
  //     if (info.file.status === "done") {
  //       getBase64(info.file.originFileObj as File, (url) => {
  //         setLoading(false);
  //         setImageUrl(url);
  //       });
  //     }
  //   };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageModal(false);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageUpload(file);

      const imgCheck = beforeUpload(file);

      if (imgCheck) {
        getBase64(file, (imgUrl: string) => {
          console.log(imgUrl);
          setImageUrl(imgUrl);
          //uploadImage(imgUrl, file);
        });
      } else {
        notification.error({
          message: "Image must be 2MB in size and a jpg or, jpeg or, png file",
        });
      }
    }
  };

  const getProviders = async () => {
    try {
      const { data } = await api.get(RouteConstants.NETWORK_PROVIDER, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNetworkProviders(
        data?.payload?.items?.map((item: any) => ({
          label: item?.name,
          value: item?.code,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // logic to get user profile information
  const getUserData = async () => {
    setIsLoading(true);
    try {
      const res = await accounts.get(RouteConstants.PROFILE, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setPersonalData(res.data?.payload);
      setGender(res.data?.payload?.gender);
      setIsLoading(false);
      //return res;
    } catch (e) {
      setIsLoading(false);
    }
  };

  // logice to submit edited profile values
  const handleSubmit = () => {
    // let formatIce1Phone = ice1_phone?.replace(/\s+/g, '');
    // let formatIce2Phone = ice2_phone?.replace(/\s+/g, '');

    setIsLoading(true);

    // if the value was changed, use the change value otherwise use the database value
    accounts
      .put(
        RouteConstants.PROFILE,
        {
          first_name: first_name ? first_name : userPersonal?.first_name,
          last_name: last_name ? last_name : userPersonal?.last_name,
          middle_name: middle_name ? middle_name : userPersonal?.middle_name,
          phone: userPersonal?.phone,
          gender: gender ? gender : userPersonal?.gender,
          email: email ? email : userPersonal?.email,
          ice1_phone: ice_phone ? ice_phone : userPersonal?.ice1_phone,
          ice2_phone: userPersonal?.ice2_phone,
          birthdate: dob ? dob : userPersonal?.birthdate,
          payment_account_provider: selectedProvider
            ? selectedProvider
            : userPersonal?.payment_account_provider,
          payment_account_number: payment_number
            ? payment_number
            : userPersonal?.payment_account_number,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: any) => {
        notification.success({
          message: "Profile has been updated!",
        });
        handleStepChange();
        window.location.reload();
      })
      .catch((e: any) => {
        notification.error({
          message: e?.response?.data?.message || e?.message,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleAction = async () => {
    setIsLoading(true);
    try {
      const res = await accounts.post(
        RouteConstants.UPLOAD_FOTO,
        {
          mime: imageUpload?.type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data?.payload?.upload_url) {
        await axios.put(res.data?.payload?.upload_url, imageUpload, {
          headers: {
            "Content-Type": imageUpload?.type,
          },
        });
      }
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
      message.error("File upload failed. Try again");
    }
    return "";
  };

  //   const changeImageHander = async (e: any) => {
  //     // console.log(e.target.files[0])
  //     setImageModal(false);
  //     handleImageChange(e);
  //     //setIsLoading(true);
  //     //await handleAction(e.target.files[0]);
  //   };

  const removeImageHandler = async () => {
    // console.log(e.target.files[0])
    setIsLoading(false);
    try {
      await accounts.delete(RouteConstants.DELETE_FOTO, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      notification.success({
        message: "Profile has been updated!",
      });
      handleStepChange();

      setIsLoading(false);
      setImageUrl("");
      setImageModal(false);
      window.location.reload();
    } catch (error: any) {
      setIsLoading(false);
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    }
  };

  useEffect(() => {
    getUserData();
    getProviders();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <Modal
        title={
          <p className=" font-bold text-lg text-oya-gray mb-4">Profile photo</p>
        }
        centered
        open={imageModal}
        onCancel={() => setImageModal(false)}
        footer={null}
      >
        <div className=" w-full">
          <label>
            <input
              type="file"
              hidden
              accept="image/png, image/gif, image/jpeg"
              onChange={handleImageChange}
            />
            <div className="py-3 flex gap-x-1 items-center text-[#484442] mb-3">
              <img src="/images/gallery.png" alt="gallery" />
              <p className=" text-sm">Choose Photo from gallery</p>
            </div>
          </label>

          <div
            className="py-3 flex gap-x-1 items-center text-[#484442] mb-3"
            onClick={() => {
              if (userPersonal?.image) {
                removeImageHandler();
              }
            }}
          >
            <img src="/images/profile-trash.png" alt="delete" />
            <p className=" text-sm">Delete profile photo</p>
          </div>
        </div>
      </Modal>
      <div className="h-screen overflow-hidden bg-white dark:bg-dark-100">
        <div className="h-full mt-5">
          <div className="flex justify-between items-center mx-[20px]">
            <div className="flex-[0.3]">
              <button
                className="h-10 text-oya-grayflex justify-center items-center"
                onClick={() => handleStepChange()}
              >
                <ChevronLeftIcon className="h-6 w-6 font-bold" />
              </button>
            </div>
            <div className="flex-[0.7]">
              <h4 className="header-text-4">Personal</h4>
            </div>
          </div>

          {isLoading ? (
            <div className="mt-5">
              <div className="animate-pulse">
                <div className="flex justify-center items-center">
                  <div className="w-[120px] h-[120px] rounded-[32px] bg-gray-300" />
                </div>
              </div>

              <div className="mt-5 border-b border-neutral-100 animate-pulse">
                <div className="flex gap-3 items-center pb-3 mx-[20px]">
                  <div className="w-[20px] h-[20px] bg-gray-300" />
                  <div className="bg-gray-300 w-[80%] h-[20px]" />
                </div>
              </div>
              <div className="mt-5 border-b border-neutral-100 animate-pulse">
                <div className="flex gap-3 items-center pb-3 mx-[20px]">
                  <div className="w-[20px] h-[20px] bg-gray-300" />
                  <div className="bg-gray-300 w-[80%] h-[20px]" />
                </div>
              </div>
              <div className="mt-5 border-b border-neutral-100 animate-pulse">
                <div className="flex gap-3 items-center pb-3 mx-[20px]">
                  <div className="w-[20px] h-[20px] bg-gray-300" />
                  <div className="bg-gray-300 w-[80%] h-[20px]" />
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-5 h-screen overflow-y-auto">
              <div className="flex justify-center items-center ">
                <div className="w-[120px] h-[120px]">
                  {imageUrl ? (
                    // <ImgCrop rotationSlider>
                    <div>
                      <div className="w-[120px] h-[120px] rounded-3xl border border-gray-400 relative">
                        <img
                          src={imageUrl}
                          alt="avatar"
                          className="w-full h-full rounded-3xl"
                        />
                      </div>
                      <button
                        onClick={() => setImageModal(true)}
                        className="absolute right-[10rem] top-[10.5rem] z-10"
                      >
                        <img
                          src="/images/edit-profile.png"
                          alt="edit"
                          className=" w-8 h-8"
                        />
                      </button>
                    </div>
                  ) : (
                    <>
                      {userPersonal?.image ? (
                        <div className="w-[120px] h-[120px] rounded-3xl border border-gray-400 relative">
                          <img
                            src={userPersonal?.image}
                            alt="dp"
                            className="w-full h-full rounded-3xl"
                          />
                          <img
                            src="/images/edit-profile.png"
                            alt="edit"
                            className="absolute -bottom-2 -right-3 z-10 cursor-pointer w-8 h-8"
                            onClick={() => setImageModal(true)}
                          />
                        </div>
                      ) : (
                        <div className="w-[120px] h-[120px] rounded-3xl border border-gray-400 relative">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className=" w-full h-full sm:w-32 sm:h-32 rounded-full text-oya-ghana-green"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                          <button
                            onClick={() => setImageModal(true)}
                            className="translate-x-[5.5rem] -translate-y-1"
                          >
                            <img
                              src="/images/edit-profile.png"
                              alt="edit"
                              className=" w-8 h-8"
                            />
                          </button>
                        </div>
                      )}
                    </>
                  )}
                  {imageUrl && (
                    <div className="my-4">
                      <button
                        onClick={() => handleAction()}
                        className="text-oya-red-100 text-[14px] font-medium"
                      >
                        Upload image
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="border-t border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Name</div>
                    <div>
                      <div className="text-caption py-2 ml-1">First name</div>
                      <input
                        onChange={(e) => setFirstName(e.target.value)}
                        defaultValue={userPersonal?.first_name}
                        className="input-container w-full text-[14px]"
                        data-testid="firstnameInput"
                      />
                    </div>
                    <div>
                      <div className="text-caption py-2 ml-1">Middle name</div>
                      <input
                        onChange={(e) => setMiddleName(e.target.value)}
                        defaultValue={userPersonal?.middle_name}
                        className="input-container w-full text-[14px]"
                        data-testid="middlenameInput"
                      />
                    </div>
                    <div>
                      <div className="text-caption py-2 ml-1">Last name</div>
                      <input
                        onChange={(e) => setLastName(e.target.value)}
                        defaultValue={userPersonal?.last_name}
                        className="input-container w-full text-[14px]"
                        data-testid="lastnameInput"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Gender</div>
                    <div className="flex flex-col">
                      <Radio
                        name="gender"
                        defaultChecked={gender === "M" ? true : false}
                        placeholder=""
                        color="deep-orange"
                        label="Male"
                        data-testid="genderInput"
                        value="M"
                        crossOrigin=""
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      />

                      <Radio
                        name="gender"
                        defaultChecked={gender === "F" ? true : false}
                        placeholder=""
                        color="deep-orange"
                        label="Female"
                        data-testid="genderInput"
                        value="F"
                        crossOrigin=""
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                      />

                      <Radio
                        name="gender"
                        placeholder=""
                        defaultChecked={gender === "O" ? true : false}
                        color="deep-orange"
                        label="Prefer not to say"
                        data-testid="genderInput"
                        crossOrigin=""
                        onChange={(e) => {
                          setGender(e.target.value);
                        }}
                        value="O"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Date of birth</div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Date of birth
                      </div>

                      <Input
                        data-testid="birthdateInput"
                        type="date"
                        className=" h-[50px] rounded-lg border border-[#939291] text-[14px] w-full"
                        onChange={(e) => setDob(e.target.value)}
                        value={userPersonal?.birthdate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" border-neutral-100 mt-5 border-b pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Email address</div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Email address
                      </div>
                      {/* <input
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={userPersonal?.email}
                        type='email'
                        className='input-container w-full'
                      /> */}
                      <Input
                        data-testid="emailInput"
                        className=" h-[50px] rounded-lg border border-[#939291] text-[14px]"
                        suffix={<img src="/images/sms.png" alt="mail" />}
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={userPersonal?.email}
                        type="email"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">
                      Payment network provider
                    </div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Select a provider
                      </div>
                      <div>
                        <Select
                          size="large"
                          value={selectedProvider}
                          defaultValue={userPersonal?.payment_account_provider}
                          className="w-full input-container"
                          options={network_providers}
                          onChange={(e) => setSelectedProvider(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Payment number</div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Your payment number
                      </div>
                      <div>
                        <PhoneInput
                          className="input-container text-[14px]"
                          defaultCountry="GH"
                          inputStyle={{ width: "100%" }}
                          value={userPersonal?.payment_account_number}
                          onChange={(e) => setPaymentNumber(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Emergency contact</div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Mobile number
                      </div>
                      <div>
                        <PhoneInput
                          className="input-container text-[14px]"
                          defaultCountry="GH"
                          inputStyle={{ width: "100%" }}
                          value={userPersonal?.ice1_phone}
                          onChange={(e) => setIcePhone(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 pb-5">
                <div className="mx-[20px]">
                  <div className="mt-5">
                    <div className="text-header-4">Number</div>
                    <div>
                      <div className="text-caption py-2 ml-1">
                        Mobile number
                      </div>
                      <div>
                        <PhoneInput
                          className="input-container text-[14px]"
                          defaultCountry="GH"
                          readOnly
                          inputStyle={{ width: "100%" }}
                          value={userPersonal?.phone}
                          onChange={() => {}}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-evenly items-center mt-8">
                <button
                  className="text-oya-gray font-medium"
                  onClick={() => handleStepChange()}
                >
                  Cancel
                </button>
                <button
                  className="font-semibold text-oya-red-100"
                  onClick={() => handleSubmit()}
                  data-testid="saveButton"
                >
                  Save
                </button>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default EditPersonal;
