import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@material-tailwind/react";
import { useState } from "react";
import PhoneInput, { Value } from "react-phone-number-input";
import {
  setCookie,
  setCookieJson,
  getDaysUntilJwtExpiration,
} from "../../../helpers/utils";

import { notification } from "antd";
import CustomButton from "../../../components/CustomBtn";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../helpers/api";
import { RouteConstants } from "../../../helpers/constants";

interface stepProps {
  dataFromStep1: any;
  goBack: () => void;
}

const SecondStep = ({ dataFromStep1, goBack }: stepProps) => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [theUserData, setTheUserData] = useState<any>(null);
  const [emergency1, setEmergency1] = useState<Value | undefined>();
  const [emergency2, setEmergency2] = useState<Value | undefined>();
  const [thePin, setThePin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [wrongPin, setWrongPins] = useState(false);
  const [code, setCode] = useState<number | any>();

  //openVerificationModal
  const [openVerify, setOpenVerify] = useState(false);
  const handleOpenVerify = () => {
    setOpenVerify(true);
  };

  //handleCreateAccount
  const handleCreateAccount = async () => {
    let formatPhoneNumber1 = emergency1?.replace(/\s+/g, "");
    let formatPhoneNumber2 = emergency2?.replace(/\s+/g, "");

    if (!thePin || !confirmPin) {
      setIsError(true);
    } else if (thePin !== confirmPin) {
      setWrongPins(true);
    } else {
      let finalData = {
        ...dataFromStep1,
        ice1_phone: formatPhoneNumber1,
        ice2_phone: formatPhoneNumber2,
        pin: thePin,
      };
      // console.log(finalData)
      setTheUserData(finalData);
      setIsBusy(true);
      try {
        const jsonData = await auth.post(
          `${RouteConstants.REGISTER}`,
          finalData
        );
        // console.log(response?.data)

        const daysUntilExpiration = getDaysUntilJwtExpiration(
          jsonData.data?.payload?.token
        );

        setCookieJson(
          "usr",
          jsonData.data?.payload?.user?.id,
          daysUntilExpiration
        );
        setCookie("token", jsonData.data?.payload?.token, daysUntilExpiration);
        setCookieJson("user_info", jsonData?.data?.payload?.user);

        setCookieJson(
          "user",
          {
            // staffs: jsonData?.data?.payload?.relations,
            user_roles: jsonData.data?.payload?.user_roles,
            role_id: jsonData.data?.payload?.user?.type?.id,
            role: jsonData.data?.payload?.user?.type?.name,
          },
          daysUntilExpiration
        );
        setIsBusy(false);

        handleOpenVerify();
      } catch (error: any) {
        // console.log(error)
        notification.error({
          message: error?.response?.data?.message || error?.message,
        });
        setIsBusy(false);
      } finally {
        setIsBusy(false);
      }
    }
  };

  //handleOnPinComplete
  const handleOnPinComplete = async () => {
    // console.log(value)
    let data = {
      phone: theUserData?.phone,
      code,
    };
    // console.log(data)
    setIsBusy(true);

    try {
      await auth.post(RouteConstants.VERIFY_OTP, {
        ...data,
      });
      notification.success({
        message: "Login successful",
      });
      localStorage.setItem("isOnboard", "ONBOARD");
      navigate("/");
      window.location.reload();

      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    }
  };

  //handleResend
  const handleResend = async () => {
    let data = {
      phone: theUserData?.phone,
    };
    setIsBusy(true);
    try {
      await auth.post(RouteConstants.SEND_OTP, {
        phone: data.phone,
      });
      notification.success({
        message: "Verification code sent",
      });

      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    }
  };

  return (
    <>
      {openVerify === false ? (
        <div>
          <button onClick={() => goBack()}>
            <ChevronLeftIcon className="w-6 h-6" />
          </button>
          <div className="w-full mt-5">
            <div className="header-text-3">Create Pin</div>
            <div className="body-text-2 pt-1">
              Create a pin to secure your account.
            </div>
          </div>

          <div className="flex flex-col mt-8 relative">
            <small className={`${isError ? "text-red-500" : ""} mb-2`}>
              Your new pin
            </small>
            <input
              className="input"
              type="password"
              value={thePin}
              maxLength={4}
              onChange={(e) => setThePin(e.target.value)}
              placeholder="Enter your new pin"
              data-testid="pinInput"
            />
            {/* <LockClosedIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
          </div>

          <div className="flex flex-col mt-8 relative">
            <small className={`${isError ? "text-red-500" : ""} mb-2`}>
              Confirm your pin
            </small>
            <input
              className="input"
              type="password"
              value={confirmPin}
              maxLength={4}
              onChange={(e) => setConfirmPin(e.target.value)}
              placeholder="Confirm your new pin"
              data-testid="confirmPinInput"
            />
            {/* <LockClosedIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
          </div>
          {wrongPin && <div className="text-red-500 mt-2">Pin mismatch!</div>}

          <div className="flex flex-col mt-8 relative">
            <small className={`${isError ? "text-red-500" : ""} mb-2`}>
              Emergency Number 1
            </small>
            <PhoneInput
              className="input"
              defaultCountry="GH"
              inputStyle={{ width: "100%" }}
              value={emergency1}
              placeholder="Number to call in case of emergency"
              onChange={(phone) => setEmergency1(phone)}
              numberInputProps={{
                className: "focus:outline-none",
              }}
              data-testid="emergencyOnePhoneInput"
            />
          </div>

          <div className="flex flex-col mt-4 relative">
            <small className={`${isError ? "text-red-500" : ""} mb-2`}>
              Emergency Number 2
            </small>
            <PhoneInput
              className="input"
              defaultCountry="GH"
              inputStyle={{ width: "100%" }}
              value={emergency2}
              onChange={(phone) => setEmergency2(phone)}
              placeholder="Number to call in case of emergency"
              numberInputProps={{
                className: "focus:outline-none",
              }}
              data-testid="emergencyTwoPhoneInput"
            />
          </div>

          <div className="mt-[30px] flex justify-center items-center">
            <CustomButton
              width="w-full"
              disabled={isBusy}
              data-testid="savedInputs"
              onClick={() => handleCreateAccount()}
            >
              {isBusy ? (
                <div className="w-full flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <span>Continue</span>
              )}
            </CustomButton>
          </div>
        </div>
      ) : (
        <div>
          <button
            onClick={() => {
              setOpenVerify(false);
            }}
          >
            <ChevronLeftIcon className="w-6 h-6" />
          </button>
          <div className="w-full mt-5">
            <div className="header-text-3">Verify number</div>
            <div className="body-text-2 pt-1" data-testid="displayPhone">
              Enter the 6-digit code sent to {theUserData?.phone}
            </div>
          </div>

          <div className="mt-[4rem]">
            <PinInput
              length={6}
              initialValue=""
              secret
              secretDelay={100}
              type="custom"
              inputMode="number"
              inputStyle={{
                borderColor: "#939291",
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
              data-testid="otpInput"
              inputFocusStyle={{ borderColor: "#FF6200" }}
              onComplete={(value, index) => {
                setCode(value);
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <div className="mt-5">
            {isBusy ? (
              <Spinner color="green" className="h-8 w-8" />
            ) : (
              <>
                <div>
                  <p className="flex items-center gap-2">
                    Didn’t receive code?{" "}
                    <span
                      className="btn-subtle"
                      data-testid="resendOTP"
                      onClick={() => handleResend()}
                    >
                      Resend.
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="mt-[100px] flex justify-center items-center">
            <CustomButton
              width="w-full"
              disabled={isBusy}
              data-testid="CreateAndVerifyOTP"
              onClick={() => {
                handleOnPinComplete();
              }}
            >
              {isBusy ? (
                <div className="w-full flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <span>Create account</span>
              )}
            </CustomButton>
          </div>
        </div>
      )}
    </>
  );
};

export default SecondStep;
