import React from "react";
import Header from "../Header";

const PageLayout = ({ children }: any) => {
  return (
    <div>
      <Header />
      <div className=" bg-[#f5f5f5] min-h-screen pb-6">{children}</div>
    </div>
  );
};

export default PageLayout;
