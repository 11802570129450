import React, { useState } from "react";
import VerifyPhoneMobile from "../mobile/components/VerifyPhoneMobile";
import { useMediaQuery } from "@mui/material";
import PageLayout from "../components/utils/PageLayout";
import { auth } from "../helpers/api";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../helpers/utils";
import { RouteConstants } from "../helpers/constants";
import { notification } from "antd";
import { ArrowBackIos } from "@mui/icons-material";
import { Spinner } from "@material-tailwind/react";
import CustomButton from "../components/CustomBtn";
import PinInput from "react-pin-input";

type Props = {};

const VerifyPhone = (props: Props) => {
  const isLarge = useMediaQuery("(min-width:768px)");
  const navigate = useNavigate();
  const data: any = getCookie("oya_user_info");
  const theUserData = JSON.parse(data);
  const [isBusy, setIsBusy] = useState(false);

  const [code, setCode] = useState<number | any>();

  //handleResend
  const handleResend = async () => {
    let data = {
      phone: theUserData?.phone,
    };
    setIsBusy(true);
    try {
      await auth.post(RouteConstants.SEND_OTP, {
        ...data,
      });
      notification.success({
        message: "Verification code sent",
      });

      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    }
  };

  //handleOnPinComplete
  const handleOnPinComplete = async () => {
    // console.log(value)
    if (code) {
      let data = {
        phone: theUserData?.phone,
        code,
      };
      // console.log(data)
      setIsBusy(true);

      try {
        await auth.post(RouteConstants.VERIFY_OTP, {
          ...data,
        });
        notification.success({
          message: "Phone verification successful",
        });
        //localStorage.setItem("isOnboard", "ONBOARD");
        navigate("/");
        window.location.reload();

        setIsBusy(false);
      } catch (error: any) {
        setIsBusy(false);
        notification.error({
          message: error?.response?.data?.message || error?.message,
        });
      }
    }
  };
  return (
    <>
      {isLarge ? (
        <PageLayout>
          <div className="pt-[8rem] flex justify-center items-center">
            <div className="rounded-xl shadow-md bg-white p-5 w-[40%]">
              <button
                data-testid="goBack"
                className="mt-[2rem] mb-5 flex gap-3 items-center"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIos
                  sx={{
                    width: "20px",
                    height: "20px",
                    color: "#B40303",
                  }}
                />
                <div className="text-oya-red-100 font-semibold text-lg">
                  Back
                </div>
              </button>

              <div className="mt-4">
                <div className="w-full mt-5">
                  <div className="header-text-3">Verify number</div>
                  <div className="body-text-2 pt-1">
                    Enter the 6-digit code sent to {theUserData?.phone}
                  </div>
                </div>

                <div className="mt-[4rem]">
                  <PinInput
                    length={6}
                    initialValue=""
                    secret
                    secretDelay={100}
                    type="custom"
                    inputMode="number"
                    inputStyle={{
                      borderColor: "#939291",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                    inputFocusStyle={{ borderColor: "#FF6200" }}
                    onComplete={(value, index) => {
                      setCode(value);
                    }}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    data-testid="otpInput"
                  />
                </div>
                <div className="mt-5">
                  {isBusy ? (
                    <Spinner color="green" className="h-8 w-8" />
                  ) : (
                    <>
                      <div>
                        <p className="flex items-center gap-2">
                          Didn’t receive code?{" "}
                          <span
                            className="btn-subtle"
                            onClick={() => handleResend()}
                            data-testid="resendOtpBtn"
                          >
                            Resend.
                          </span>
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="mt-[100px] flex justify-center items-center">
                  <CustomButton
                    width="w-full"
                    disabled={isBusy}
                    onClick={() => {
                      handleOnPinComplete();
                    }}
                    data-testid="verifyPhoneDesktopBtn"
                  >
                    {isBusy ? (
                      <div className="w-full flex justify-center items-center">
                        <Spinner />
                      </div>
                    ) : (
                      <span>Verify phone</span>
                    )}
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        </PageLayout>
      ) : (
        <div className="h-screen bg-white overflow-hidden flex flex-col">
          <div className="w-full px-[20px] mt-[20%]">
            <VerifyPhoneMobile />
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyPhone;
