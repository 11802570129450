import React from "react";

// import Onboard from "./auth/Onboard";
import MobileHome from "./home/MobileHome";

const Main = () => {
  //const onboard = localStorage.getItem("isOnboard");
  return (
    <React.Fragment>
      <MobileHome />
    </React.Fragment>
  );
};

export default Main;
