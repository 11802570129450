import React, { useState } from "react";
import { Input, Modal, Spin, notification } from "antd";
import Loader from "../../components/Loader";
import { accounts } from "../../helpers/api";
import { RouteConstants } from "../../helpers/constants";
import { getCookie, logout } from "../../helpers/utils";

type Props = {
  visible: boolean;
  handleClose: () => void;
};

const DeleteModal = ({ visible, handleClose }: Props) => {
  const token = getCookie("oya_token");
  const [stage, setStage] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [pin, setPin] = useState<string>("");

  const handleSubmit = async () => {
    if (pin) {
      setSubmitting(true);
      try {
        await accounts.delete(RouteConstants.DELETE_USER, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            pin,
          },
        });
        setSubmitting(false);
        logout();
      } catch (error: any) {
        setSubmitting(false);
        notification.error({
          message: error?.response?.data?.message || error?.message,
        });
      }
    }
  };

  return (
    <Modal
      closable={submitting}
      open={visible}
      onCancel={() => handleClose()}
      footer={null}
    >
      <Spin
        spinning={submitting}
        indicator={<Loader width="w-10" height="h-10" />}
      >
        <div className="rounded-xl bg-white ">
          <div className="header-text-3">Delete Account</div>
          <div>
            {stage === 1 && (
              <div>
                <div>
                  Are you sure you want to delete your account? Taking this
                  action will delete this account and all data associated with
                  it.
                </div>

                <div className="w-full justify-end items-end flex-row flex mt-10">
                  <div className="flex gap-5 items-center">
                    <button
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <div className="font-medium text-oya-gray">Cancel</div>
                    </button>
                    <button
                      onClick={() => {
                        setStage(2);
                      }}
                    >
                      <div className="font-medium text-oya-gray">
                        Delete account
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {stage === 2 && (
              <div className="mt-6">
                <div>
                  <div className="ml-2 text-[12px] mb-1 text-oya-gray">
                    Your pin
                  </div>
                  <Input.Password
                    type="number"
                    max={4}
                    className="border rounded-lg h-[44px]"
                    placeholder="Enter your PIN"
                    onChange={(e) => setPin(e.target.value)}
                  />
                </div>

                <div className="w-full justify-end items-end flex-row flex mt-10">
                  <div className="flex gap-5 items-center">
                    <button
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <div className="font-medium text-oya-gray">Cancel</div>
                    </button>
                    <button
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      <div className="font-medium text-oya-gray">Delete</div>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default DeleteModal;
