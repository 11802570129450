import React, { useState, useEffect, Fragment } from 'react';
import { Spin, Form, Input, Radio, Modal, notification } from 'antd';
import LuggageModal from '../Schedules/LuggageModal';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { getCookie } from '../../helpers/utils';
import PhoneInput from 'react-phone-number-input';
import CustomButton from '../CustomBtn';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchHshsScheduleDetails } from '../../api/fetchHshsScheduleDetail';
import { useDispatch, useSelector } from 'react-redux';
import {
  setHshsBookingPayload,
  setLuggages,
  UserState,
} from '../../redux/reducer';
import { fetchLuggageSizes } from '../../api/fetchLuggageSizes';
import { api } from '../../helpers/api';
import { RouteConstants } from '../../helpers/constants';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import HshsScheduleDetail from '../../mobile/hshs/HshsScheduleDetail';

const HshsScheduleBooking = ({ scheduleId }: { scheduleId: any }) => {
  const [submitting, setSubmitting] = useState(false);
  const token = getCookie('oya_token');
  const [isMobile, setIsMobile] = useState(true);

  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const [luggageModal, openLuggageModal] = useState(false);
  const [luggage_description, setLuggageDescription] = useState('');
  const [message, setMessage] = useState('');
  const [luggageType, setLuggageType] = useState<number>(2);
  const [selectedLuggage, setSelectedLuggage] = useState<any>([]);
  const [luggageSizes, setLuggageSizes] = useState<Array<any>>([]);

  const [phone, setPhone] = useState<string | undefined>();
  const [icePhone, setIcePhone] = useState<string | undefined>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [success, setSuccess] = useState(false);

  const schedule = useSelector((state: UserState) => state.hshs_schedule);
  const saved_booking = useSelector(
    (state: UserState) => state.hshs_booking_payload
  );

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    console.log(values);
    setSubmitting(true);
    try {
      const body = {
        luggage_sizes:
          luggageType === 3
            ? selectedLuggage?.length > 0
              ? selectedLuggage?.map((item: any) => ({
                  luggage_size_code: item?.code,
                  number_of_luggages: item?.quantity,
                }))
              : undefined
            : undefined,
        // pickup_id: 0,
        message: message ? message : undefined,
        luggage_description: luggage_description
          ? luggage_description
          : undefined,
      };

      dispatch(setHshsBookingPayload(body));
      const { data } = await api.post(
        `${RouteConstants.LOADING_POINT_SCHEDULES}/${schedule?.id}/loading-point-bookings`,
        {
          ...body,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      navigate(`/hshs/pay/${data?.payload?.id}`);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitGuest = async () => {
    // const values = form.getFieldsValue();
    // console.log(values);
    form
      .validateFields(['first_name', 'last_name'])
      .then(async (values) => {
        // console.log(values);
        if (icePhone && phone && values?.first_name && values?.last_name) {
          setSubmitting(true);
          try {
            const body = {
              passenger: {
                phone,
                first_name: values?.first_name,
                last_name: values?.last_name,
                ice_phone: icePhone,
              },
              luggage_sizes:
                luggageType === 3
                  ? selectedLuggage?.length > 0
                    ? selectedLuggage?.map((item: any) => ({
                        luggage_size_code: item?.code,
                        number_of_luggages: item?.quantity,
                      }))
                    : undefined
                  : undefined,
              //loading_point_id: schedule?.id,
              message: message ? message : undefined,
              luggage_description: luggage_description
                ? luggage_description
                : undefined,
            };

            dispatch(setHshsBookingPayload(body));
            const { data } = await api.post(
              `${RouteConstants.LOADING_POINT_SCHEDULES}/${schedule?.id}/guest-loading-point-bookings`,
              {
                ...body,
              }
            );

            navigate(`/hshs/pay/${data?.payload?.id}`);
          } catch (error: any) {
            notification.error({
              message: error?.response?.data?.message || error?.message,
            });
          } finally {
            setSubmitting(false);
          }
        } else {
          setStep(2);
        }
      })
      .catch((e) => {
        notification.info({
          message: 'Fill the required fields to continue',
        });
      });
  };

  const details = useQuery({
    queryKey: [`hshs-schedule-${scheduleId}`],
    queryFn: () => fetchHshsScheduleDetails(token, scheduleId),
  });

  const luggages = useQuery({
    queryKey: [`luggage-sizes`],
    queryFn: fetchLuggageSizes,
  });

  useEffect(() => {
    if (luggages.data) {
      setLuggageSizes(luggages.data.items);
      dispatch(setLuggages(luggages.data.items));
    }

    // eslint-disable-next-line
  }, [luggages.isSuccess]);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkScreenSize);

    checkScreenSize();

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  useEffect(() => {
    if (saved_booking) {
      if (!token && saved_booking?.passenger) {
        form.setFieldValue('first_name', saved_booking?.passenger?.first_name);
        form.setFieldValue('last_name', saved_booking?.passenger?.last_name);
        setPhone(saved_booking?.passenger?.phone);
        setIcePhone(saved_booking?.passenger?.ice_phone);
      }

      form.setFieldValue('message', saved_booking?.message);
      form.setFieldValue(
        'luggage_description',
        saved_booking?.luggage_description
      );
    }

    // eslint-disable-next-line
  }, []);

  if (isMobile) {
    return <HshsScheduleDetail />;
  }

  return (
    <div>
      {scheduleId ? (
        <Fragment>
          <>
            <Spin spinning={submitting}>
              <div className=' min-h-screen'>
                <div className={'flex flex-row w-full gap-7'}>
                  <Form
                    className={`book-advance-container sm:w-[65%] rounded-[16px] lg:p-11 p-7 flex-[0.7]`}
                    form={form}
                    layout='vertical'
                    autoComplete='false'
                  >
                    <>
                      {step === 1 && (
                        <>
                          <>
                            <button
                              className='flex gap-2 items-center py-3'
                              onClick={() => {
                                navigate('/hshs');
                              }}
                            >
                              <ChevronLeftIcon className='text-oya-gray w-5 h-5' />
                              <div>Back</div>
                            </button>
                            <>
                              <div className='text-heading-3 mt-5'>
                                Luggage details
                              </div>
                              <Form.Item
                                name='luggage-type'
                                label={
                                  <label className='mt-2 text-[16px]'>
                                    Bringing any luggage?
                                  </label>
                                }
                              >
                                <div className='w-full lg:flex justify-between'>
                                  <Radio
                                    defaultChecked
                                    onChange={(e) => {
                                      setLuggageType(e.target.value);
                                      setSelectedLuggage([]);
                                    }}
                                    checked={luggageType === 2 ? true : false}
                                    className={`rounded-lg p-8 border flex-[0.5] w-full items-start ${
                                      luggageType === 2
                                        ? 'border-oya-red-100'
                                        : 'border-oya-gray-200'
                                    }`}
                                    value={2}
                                  >
                                    <div className='texdt-[16px]'>
                                      No luggage
                                    </div>
                                    <div>No luggage, only personal item</div>
                                  </Radio>

                                  <Radio
                                    onChange={(e) => {
                                      openLuggageModal(true);
                                    }}
                                    disabled={luggages.isLoading}
                                    onClick={() => {
                                      openLuggageModal(true);
                                    }}
                                    checked={luggageType === 3 ? true : false}
                                    className={`rounded-lg p-8 border flex-[0.5] w-full mt-4 lg:mt-0 ${
                                      luggageType === 3
                                        ? 'border-oya-red-100'
                                        : 'border-oya-gray-200'
                                    }`}
                                    value={3}
                                  >
                                    <div className='texdt-[16px]'>Luggage</div>
                                    {selectedLuggage?.length > 0 ? (
                                      <div className='flex flex-wrap items-center gap-2'>
                                        {selectedLuggage?.map(
                                          (item: any, index: number) => (
                                            <div
                                              key={index}
                                              className='flex flex-wrap'
                                            >
                                              <div className='flex gap-1'>
                                                <div>{item?.quantity}</div>
                                                <div>{item?.name}</div>
                                                <div>
                                                  {index <
                                                  selectedLuggage?.length - 1
                                                    ? ', '
                                                    : ''}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <div>Small, medium or large bags</div>
                                    )}
                                  </Radio>
                                </div>
                              </Form.Item>
                              {luggageType === 3 && (
                                <Form.Item
                                  colon={false}
                                  label='Any additional information about your luggage?'
                                  name='luggage_description'
                                  className='w-full mt-[32px]'
                                  initialValue={
                                    saved_booking?.luggage_description &&
                                    saved_booking?.luggage_description
                                  }
                                >
                                  <Input.TextArea
                                    className='border border-gray-400 h-[170px] md:h-[96px] roundedLg mb-5 pl-[16px] pt-[12px]'
                                    rows={5}
                                    placeholder='Enter your message...'
                                    maxLength={100}
                                    onChange={(e) =>
                                      setLuggageDescription(e.target.value)
                                    }
                                  />
                                  <div className='w-full justify-end items-end text-oya-gray-200'>
                                    {luggage_description.length} / 100
                                  </div>
                                </Form.Item>
                              )}
                            </>

                            <hr className='mt-8 mb-2' />

                            <div className='text-heading-3 mt-5'>
                              Additional information
                            </div>

                            <Form.Item
                              colon={false}
                              label='Anything else we should know? (Optional)'
                              name='message'
                              className='w-full mt-[32px]'
                              initialValue={
                                saved_booking?.message && saved_booking?.message
                              }
                            >
                              <Input.TextArea
                                className='border border-gray-400 h-[170px] md:h-[96px] roundedLg mb-5 pl-[16px] pt-[12px]'
                                rows={5}
                                placeholder='Enter your message...'
                                onChange={(e) => setMessage(e.target.value)}
                                maxLength={100}
                              />
                              <div className='w-full justify-end items-end text-oya-gray-200'>
                                {message.length} / 100
                              </div>
                            </Form.Item>
                          </>
                        </>
                      )}

                      {step === 2 && (
                        <>
                          <button
                            className='flex gap-2 items-center py-3'
                            onClick={() => {
                              setStep(1);
                            }}
                          >
                            <ChevronLeftIcon className='text-oya-gray w-5 h-5' />
                            <div>Back</div>
                          </button>
                          <div className='text-heading-3 pb-[32px]'>
                            Personal details
                          </div>

                          <div className='text-[16px] w-[80%] mt-3 mb-5'>
                            Please provide your details to help reach out to you
                            when travel date is due.
                          </div>

                          <Form.Item
                            name='first_name'
                            label={
                              <label className='ml-2'>Your first name</label>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <Input className='h-[50px]' />
                          </Form.Item>
                          <Form.Item
                            name='last_name'
                            initialValue={
                              saved_booking?.passenger
                                ? saved_booking?.passenger?.last_name
                                : ''
                            }
                            label={
                              <label className='ml-2'>Your last name</label>
                            }
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <Input className='h-[50px]' />
                          </Form.Item>
                          <Form.Item
                            colon={false}
                            label={
                              <label className='ml-2'>Your mobile number</label>
                            }
                            name='guest_phone'
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <PhoneInput
                              value={phone}
                              onChange={(e) => {
                                let formatted = e?.replace(/\s+/g, '');
                                setPhone(formatted);
                              }}
                              placeholder='Enter your mobile number'
                              className='phone bg-white w-full px-4 border border-oya-gray-200 rounded-lg outline-none h-[50px] focus:border-gray-200 focus:outline-none'
                              defaultCountry='GH'
                            />
                          </Form.Item>

                          <Form.Item
                            colon={false}
                            label={
                              <label className='ml-2'>
                                Primary emergency contact
                              </label>
                            }
                            name='user_ice_phone'
                            rules={[
                              {
                                required: true,
                                message: 'This field is required',
                              },
                            ]}
                          >
                            <PhoneInput
                              value={icePhone}
                              onChange={(e) => {
                                let formatted = e?.replace(/\s+/g, '');
                                setIcePhone(formatted);
                              }}
                              placeholder='Enter your primary emergency number'
                              className='phone bg-white w-full px-4 border border-oya-gray-200 rounded-lg outline-none h-[50px] focus:border-gray-200 focus:outline-none'
                              defaultCountry='GH'
                            />
                          </Form.Item>
                        </>
                      )}
                    </>
                  </Form>
                  <div className='flex-[0.3] '>
                    <div className='book-advance-container p-5 fixed w-[20%]'>
                      {details.isLoading ? (
                        <div className='border border-oya-gray-100 px-6 py-4 rounded-[8px]'>
                          <div className='animate-pulse w-20 h-6 bg-slate-200' />

                          <div className='pt-2 text-oya-gray flex gap-4 items-center'>
                            <div className='animate-pulse w-8 h-4 bg-slate-200' />
                            <div className='animate-pulse w-14 h-4 bg-slate-200' />
                          </div>
                        </div>
                      ) : (
                        <>
                          {details.data && details.isSuccess ? (
                            <div className='border border-oya-gray-100 px-6 py-4 rounded-[8px]'>
                              <div className='header-text-4'>
                                {details.data?.loading_point?.source?.name} to{' '}
                                {details.data?.loading_point?.destination?.name}
                              </div>

                              <div className='pt-2 text-oya-gray'>
                                {details.data?.loading_point?.label} |{' '}
                                {details.data?.branch?.name}
                              </div>

                              <div className='pt-2 text-oya-gray'>
                                Departure -{' '}
                                {moment(details.data?.departures_at).format(
                                  'LLL'
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className='border border-oya-gray-100 px-6 py-4 rounded-[8px]'>
                              <div className='header-text-4'>
                                {schedule?.loading_point?.source?.name} to{' '}
                                {schedule?.loading_point?.destination?.name}
                              </div>

                              <div className='pt-2 text-oya-gray'>
                                {schedule?.loading_point?.label} |{' '}
                                {schedule?.branch?.name}
                              </div>
                              <div className='pt-2 text-oya-gray'>
                                Depature -{' '}
                                {moment(schedule?.departures_at).format('LLL')}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <div className='mt-8'>
                        <div className='flex justify-between items-center'>
                          <div>Pickup</div>
                          {details.isLoading ? (
                            <>
                              <div className='animate-pulse w-14 h-4 bg-slate-200' />
                            </>
                          ) : (
                            <>
                              {details.data && details.isSuccess ? (
                                <div className='text-right max-w-[50%]'>
                                  {details.data?.loading_point?.label}
                                </div>
                              ) : (
                                <div className='text-right max-w-[50%]'>
                                  {schedule?.loading_point?.label}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div className='flex justify-between items-center py-2'>
                          <div>Price</div>
                          {details.isLoading ? (
                            <>
                              <div className='animate-pulse w-14 h-4 bg-slate-200' />
                            </>
                          ) : (
                            <>
                              {details.data && details.isSuccess ? (
                                <div className='text-right max-w-[50%]'>
                                  {details.data?.price?.currency}
                                  {Intl.NumberFormat('en-EN', {
                                    minimumFractionDigits: 2,
                                  }).format(
                                    Number(details.data?.price?.amount)
                                  )}
                                </div>
                              ) : (
                                <div className='text-right max-w-[50%]'>
                                  {schedule?.price?.currency}
                                  {Intl.NumberFormat('en-EN', {
                                    minimumFractionDigits: 2,
                                  }).format(Number(schedule?.price?.amount))}
                                </div>
                              )}
                            </>
                          )}
                        </div>

                        <div className='flex justify-between items-center font-semibold pt-2 pb-3'>
                          <div>Total price</div>
                          {details.isLoading ? (
                            <>
                              <div className='animate-pulse w-14 h-4 bg-slate-200' />
                            </>
                          ) : (
                            <>
                              {details.data && details.isSuccess ? (
                                <div className='text-right max-w-[50%]'>
                                  {details.data?.price?.currency}
                                  {Intl.NumberFormat('en-EN', {
                                    minimumFractionDigits: 2,
                                  }).format(
                                    Number(details.data?.price?.amount)
                                  )}
                                </div>
                              ) : (
                                <div className='text-right max-w-[50%]'>
                                  {schedule?.price?.currency}
                                  {Intl.NumberFormat('en-EN', {
                                    minimumFractionDigits: 2,
                                  }).format(Number(schedule?.price?.amount))}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <CustomButton
                        data-testid='submitAndContinue'
                        width='w-full'
                        onClick={() => {
                          if (token) {
                            handleSubmit();
                          } else {
                            handleSubmitGuest();
                          }
                        }}
                      >
                        <div>Continue</div>
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>

            {/* <BusStopModal
            selected={selectedMidRoute}
            pickups={pickups}
            isOpen={busStopModal}
            handleClose={() => {
              openBusStopModal(false);
              if (!selectedMidRoute) {
                setPickupType(0);
              }
            }}
            handleSelectedStop={(pickup) => {
              setPickupType(1);
              setSelectedMidRoute(pickup);
              openBusStopModal(false);
            }}
            routeId={form.getFieldValue("route_id")}
          /> */}
            <LuggageModal
              isOpen={luggageModal}
              handleClose={() => {
                openLuggageModal(false);
                if (!selectedLuggage) {
                  setLuggageType(2);
                }
              }}
              handleSelectedStop={(type) => {
                setLuggageType(3);
                openLuggageModal(false);
                setSelectedLuggage(type);

                //console.log(type);
              }}
              luggages={luggageSizes}
            />

            <Modal open={success} footer={null} closable={false}>
              <div>
                <div className='w-full flex justify-end items-end'>
                  <button
                    data-testid='advanceBookingSuccess'
                    onClick={() => {
                      setSuccess(false);
                    }}
                  >
                    <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
                  </button>
                </div>
                <div className='flex flex-col justify-center mt-4 items-center gap-3'>
                  {/* <img src={successImg} alt='processing' /> */}
                  <div className='border-[4px] rounded-full w-[63px] h-[63px] border-seat-green flex items-center justify-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='25'
                      height='18'
                      viewBox='0 0 25 18'
                      fill='none'
                    >
                      <path
                        d='M1.5 9.29247L8.79247 16.5849L23.34 2'
                        stroke='#2ABB7F'
                        stroke-width='2.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                  <div className='text-center text-[24px] font-semibold text-seat-green py-2'>
                    Booking successful!
                  </div>
                  <div className='text-[16px] text-center'>
                    Your trip has been booked successfully.
                  </div>
                </div>
              </div>
            </Modal>
          </>
        </Fragment>
      ) : (
        <div>Not found</div>
      )}
    </div>
  );
};

export default HshsScheduleBooking;
