import React from 'react';
import {
  Font,
  Page,
  View,
  Text,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import moment from 'moment';

const AdvanceTicketReceipt = ({
  method,
  amount,
}: {
  method: string;
  amount: string;
}) => {
  const tripRoute: any = localStorage.getItem('rid');
  const tripRouteJson = JSON.parse(tripRoute);

  const tripFields: any = localStorage.getItem('trf');
  const tripFieldsJson = JSON.parse(tripFields);

  const luggageArr: any = localStorage.getItem('ulgs');
  const luggageArrJson = JSON.parse(luggageArr);
  // const priceFields: any = localStorage.getItem('pfd');
  // const priceFieldsJson = JSON.parse(priceFields);

  return (
    <Document>
      <Page style={styles.body} orientation='portrait'>
        <Text style={styles.title}>Advance ticket receipt</Text>
        {/* <Text style={styles.author}>Trip CODE: {details?.code}</Text> */}
        <View
          style={{
            width: '100%',
          }}
        >
          <View style={styles.item}>
            <View style={styles.subItem}>
              <View>
                <Text
                  style={{
                    fontWeight: 'semibold',
                  }}
                >
                  Route
                </Text>
              </View>
              <View>
                <Text>{tripRouteJson?.label}</Text>
              </View>
            </View>
          </View>

          <View style={styles.item}>
            <View style={styles.subItem}>
              <View>
                <Text
                  style={{
                    fontWeight: 'semibold',
                  }}
                >
                  Trip date
                </Text>
              </View>
              <View>
                <Text>{moment(tripFieldsJson?.date).format('DD/MM/YYYY')}</Text>
              </View>
            </View>
          </View>

          <View style={styles.item}>
            <View style={styles.subItem}>
              <View>
                <Text
                  style={{
                    fontWeight: 'semibold',
                  }}
                >
                  Pickup
                </Text>
              </View>
              <View>
                <Text>{tripFieldsJson?.boarding}</Text>
              </View>
            </View>
          </View>

          <View style={styles.item}>
            <View style={styles.subItem}>
              <View>
                <Text
                  style={{
                    fontWeight: 'semibold',
                  }}
                >
                  Number of seats
                </Text>
              </View>
              <View>
                <Text>{tripFieldsJson?.seats}</Text>
              </View>
            </View>
          </View>

          <View style={styles.item}>
            <View style={styles.subItem}>
              <View>
                <Text
                  style={{
                    fontWeight: 'semibold',
                  }}
                >
                  Number of children
                </Text>
              </View>
              <View>
                <Text>{tripFieldsJson?.minors}</Text>
              </View>
            </View>
          </View>

          <View style={styles.item}>
            <View style={styles.subItem}>
              <View>
                <Text
                  style={{
                    fontWeight: 'semibold',
                  }}
                >
                  Luggage
                </Text>
              </View>
              <View>
                <View>
                  {luggageArrJson ? (
                    <View style={{ display: 'flex' }}>
                      {luggageArrJson?.map((item: any, index: number) => (
                        <View key={index} style={{ display: 'flex' }}>
                          <View
                            style={{
                              display: 'flex',
                              gap: 4,
                              alignItems: 'center',
                            }}
                          >
                            <Text>{item?.quantity}</Text>
                            <Text>{item?.name}</Text>
                          </View>
                          <div>,</div>
                        </View>
                      ))}
                    </View>
                  ) : (
                    <Text>Traveling without luggage</Text>
                  )}
                </View>
              </View>
            </View>
          </View>
          <View style={styles.item}>
            <View style={styles.subItem}>
              <View>
                <Text
                  style={{
                    fontWeight: 'semibold',
                  }}
                >
                  Payment method
                </Text>
              </View>
              <View>
                <Text>{method}</Text>
              </View>
            </View>
          </View>

          <View style={styles.item}>
            <View style={styles.subItem}>
              <View>
                <Text
                  style={{
                    fontWeight: 'semibold',
                  }}
                >
                  Trip fare
                </Text>
              </View>
              <View>
                <Text>GHS {tripFieldsJson?.price}</Text>
              </View>
            </View>
          </View>

          <View style={styles.item}>
            <View style={styles.subItem}>
              <View>
                <Text
                  style={{
                    fontWeight: 'semibold',
                  }}
                >
                  Total
                </Text>
              </View>
              <View>
                <Text>
                  GHS {Intl.NumberFormat('en-US').format(Number(amount))}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  item: {
    marginTop: 16,
    padding: 8,
  },
  subItem: {
    display: 'flex',
    flexDirection: 'row',

    justifyContent: 'space-between',
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
  },
  textSub: {
    fontSize: 10,
    color: 'grey',
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  titleText: {
    fontSize: 13,
    paddingBottom: '2px',
    marginBottom: '2px',
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    borderBottomWidth: '1px',
    borderBottomColor: '#242424',
  },
  text: {
    fontSize: 13,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

export default AdvanceTicketReceipt;
