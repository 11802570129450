import { Modal } from "antd";
import React, { Fragment, useState } from "react";
import CustomButton from "../CustomBtn";
import { components } from "react-select";
import Select from "react-select";
// import { Option } from "../../types";
// import { RouteConstants } from "../../helpers/constants";
// import { api } from "../../helpers/api";

type ModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleSelectedStop: (pickup: any) => void;
  routeId: any;
  selected: any;
  pickups: Array<any>;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.5999 7.4585L11.1666 12.8918C10.5249 13.5335 9.4749 13.5335 8.83324 12.8918L3.3999 7.4585"
          stroke="#484442"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const BusStopModal = ({
  isOpen,
  handleClose,
  handleSelectedStop,
  routeId,
  selected,
  pickups,
}: ModalProps) => {
  //const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  //const [pickups, setPickups] = useState<Option[]>([]);
  const [selectedMidRoute, setSelectedMidRoute] = useState<any>(null);

  // const getMidroutes = async () => {
  //   if (routeId) {
  //     try {
  //       const filter = [
  //         {
  //           f: "route.id",
  //           o: "=",
  //           p: [routeId?.value],
  //         },
  //       ];
  //       const res = await api.get(
  //         `${RouteConstants.MIDROUTES}?filters=${JSON.stringify(filter)}`
  //       );

  //       setPickups(
  //         res.data?.payload?.items?.map((item: MidRoute) => ({
  //           label: item?.pickup?.name,
  //           value: item?.pickup?.id,
  //         }))
  //       );
  //     } catch (e) {
  //       console.log(e);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };

  // const loadPickups = (value: string, callback: (options: any) => void) => {
  //   setTimeout(async () => {
  //     let filterData = [
  //       {
  //         f: "pickup.name",
  //         o: "contains",
  //         p: [`${value}`],
  //       },
  //     ];
  //     try {
  //       const res = await api.get(
  //         `${RouteConstants.MIDROUTES}?filters=${JSON.stringify(filterData)}`
  //       );

  //       setPickups(
  //         res.data?.payload?.items?.map((item: MidRoute) => ({
  //           label: item?.pickup?.name,
  //           value: item?.pickup?.id,
  //         }))
  //       );
  //     } catch (e) {
  //       console.log(e);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }, 2000);
  // };

  // useEffect(() => {
  //   getMidroutes();

  //   // eslint-disable-next-line
  // }, [routeId]);

  return (
    <Fragment>
      <Modal
        open={isOpen}
        footer={null}
        title="Join mid route"
        onCancel={handleClose}
      >
        <div className="mb-8 mt-5">
          <div className="text-[16px] ml-2">
            Where would you like to join the bus?
          </div>
          <Select
            isClearable
            options={pickups}
            defaultValue={selected}
            components={{ DropdownIndicator }}
            isSearchable
            onChange={(value) => {
              setSelectedMidRoute(value);
              //console.log(value);
            }}
            isDisabled={pickups.length === 0}
            styles={{
              container(base, props) {
                return {
                  ...base,
                  height: "44px",
                  borderWidth: "1px",
                  borderRadius: "8px",
                  borderColor: "#939291",
                };
              },
              control(base, props) {
                return {
                  ":active": {
                    borderColor: "#ccc",
                  },
                  width: "100%",
                  display: "flex",
                };
              },
            }}
          />
          {error && (
            <div className="mt-1 text-oya-red-100">
              Please select a bus stop
            </div>
          )}
        </div>

        {pickups.length > 0 && selectedMidRoute ? (
          <div className="w-full">
            <CustomButton
              data-testid="confirmBusStop"
              onClick={() => {
                if (selectedMidRoute) {
                  //localStorage.setItem("pid", JSON.stringify(selectedMidRoute));
                  handleSelectedStop(selectedMidRoute);
                } else {
                  setError(true);
                }
              }}
              width="w-full"
            >
              Confirm bus stop
            </CustomButton>
          </div>
        ) : null}
      </Modal>
    </Fragment>
  );
};

export default BusStopModal;
