// api.js
import axios from 'axios';
import { RIDE_MS_URL, AUTH_MS_URL, ACCOUNTS_MS_URL } from './constants';

const api = axios.create({
  baseURL: `${RIDE_MS_URL}`,
});

const auth = axios.create({
  baseURL: `${AUTH_MS_URL}`,
});

const accounts = axios.create({
  baseURL: `${ACCOUNTS_MS_URL}`,
});

// Request interceptor
// api.interceptors.request.use(
//   (config) => {
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// api.interceptors.response.use(
//   (response) => {
//     // if (response.data.status !== 200) {
//     //   handleLogout();
//     // }
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       handleLogout();
//       console.log(error.response);
//     }
//     return Promise.reject(error);
//   }
// );

export { api, auth, accounts };
