import React, { useState, useEffect } from "react";
import { Drawer, Checkbox } from "antd";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { api } from "../../helpers/api";
import { RouteConstants } from "../../helpers/constants";
import { Seat } from "../../types";
import { getCookie } from "../../helpers/utils";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  scheduleId: string | undefined;
  handleSelected: (seats: Array<Seat>) => void;
};

const SeatModal = ({
  isOpen,
  handleClose,
  scheduleId,
  handleSelected,
}: Props) => {
  const [seats, setSeats] = useState<Seat[]>([]);
  const [seatArray, setSeatArray] = useState<any[]>([]);
  const [seatObjArray, setSeatObjArray] = useState<Seat[]>([]);

  const token = getCookie("oya_token");

  const getSeats = async () => {
    if (scheduleId) {
      try {
        // const { data } = await api.get(
        //   `${RouteConstants.SCHEDULES}/${scheduleId}/realtime-seats`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );

        // const socket = new WebSocket(
        //   `${data?.payload?.endpoint}?token=${data?.payload?.token}`
        // );

        // socket.addEventListener("error", (event) => {
        //   //const data = JSON.parse(event?);
        //   console.log("Socket error", event);
        // });

        // socket.addEventListener("message", (event) => {
        //   const data = JSON.parse(event.data);
        //   console.log("Message from server ", data);
        // });

        // socket.addEventListener("open", (event):any => {
        //   const data = event.target?.addEventListener('message',(res)=>{
        //     return res;
        //   });
        //   console.log("Message from server ", data);
        // });

        const { data } = await api.get(
          `${RouteConstants.SCHEDULES}/${scheduleId}/seats`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSeats(data?.payload?.items);
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  //TO-DO
  //Handle removed seats from parent component

  const handleSeatSelection = (seat: Seat) => {
    //console.log(seat);
    const findOne = seatArray.find((value) => value === seat?.id);

    const findSeatObj = seatObjArray.find((value) => value?.id === seat?.id);

    if (findSeatObj) {
      setSeatObjArray((prevSeatObjArray) =>
        prevSeatObjArray.filter((selectedSeat) => selectedSeat?.id !== seat?.id)
      );
    } else {
      setSeatObjArray((prevSeatObjArray) => [...prevSeatObjArray, seat]);
    }

    if (findOne) {
      setSeatArray((prevSeatArray) =>
        prevSeatArray.filter((selectedSeat) => selectedSeat !== seat?.id)
      );
    } else {
      setSeatArray((prevSeatArray) => [...prevSeatArray, seat?.id]);
    }
  };

  useEffect(() => {
    getSeats();

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Drawer
        open={isOpen}
        footer={null}
        placement='bottom'
        closable={false}
        className='rounded-t-2xl'
        height={600}
      >
        <div className='p-2'>
          <div className='flex justify-between items-center text-oya-gray font-semibold w-full'>
            <div className='header-text-4'>Select your seats</div>
            <button onClick={() => handleClose()}>
              <XMarkIcon className='w-6 h-6 ' />
            </button>
          </div>
          <div className='flex items-center gap-4 mt-4'>
            <div className='flex items-center gap-2'>
              <div className='w-6 h-6 rounded-sm bg-[#FFCECC] ' />
              <div className='text-oya-gray'>Booked</div>
            </div>
            <div className='flex items-center gap-2'>
              <div className='w-6 h-6 rounded-sm bg-seat-green ' />
              <div className='text-oya-gray'>Available</div>
            </div>
            <div className='flex items-center gap-2'>
              <div className='w-6 h-6 rounded-sm bg-oya-red-100' />
              <div className='text-oya-gray'>Selected</div>
            </div>
          </div>

          <div className='mt-5 text-oya-gray font-semibold flex items-center gap-1'>
            <div>Seat(s)</div>
            {seatObjArray?.length > 0 && (
              <div className='font-semibold flex gap-2'>
                {seatObjArray?.map((item, index) => (
                  <div key={index}>{item?.number},</div>
                ))}
              </div>
            )}
            <div>selected</div>
          </div>

          <div className='mt-5'>
            <Checkbox.Group className='flex justify-center items-center'>
              <div className='grid grid-cols-4'>
                {seats.map((seat) => (
                  <Checkbox
                    key={seat?.id}
                    value={seat}
                    className='pt-2 mobileSeatSelection'
                    disabled={seat?.status === 1}
                    onChange={(e) => {
                      handleSeatSelection(e.target.value);
                    }}
                  >
                    <div className=' relative flex items-center justify-center'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className={`seat ${
                          seat?.status === 1
                            ? "text-[#FFCECC]"
                            : "text-seat-green"
                        }`}
                        width='44'
                        height='39'
                        viewBox='0 0 54 49'
                        fill='none'
                      >
                        <rect
                          y='6.23016'
                          width='5.25'
                          height='37.1907'
                          rx='2.625'
                          className='fill-current'
                          fill='none'
                        />
                        <rect
                          x='48.75'
                          y='6.23016'
                          width='5.25'
                          height='37.1907'
                          rx='2.625'
                          className='fill-current'
                          fill='none'
                        />
                        <path
                          d='M10.5 5.51144C10.5 2.95824 12.4242 0.806375 14.9672 0.579164C23.7952 -0.209571 30.1884 -0.180242 39.0114 0.591414C41.5634 0.814609 43.5 2.96993 43.5 5.53167V29.704C43.5 32.064 41.8493 34.1184 39.5261 34.5334C30.5267 36.1411 23.962 36.0686 14.5616 34.4894C12.1998 34.0926 10.5 32.0214 10.5 29.6265V5.51144Z'
                          className='fill-current'
                          fill='none'
                        />
                        <path
                          d='M10.5 42.7474C10.5 40.0977 13.0312 38.1936 15.6164 38.7748C23.981 40.6551 30.1751 40.6137 38.3947 38.766C40.9761 38.1858 43.5 40.0888 43.5 42.7347V43.8398C43.5 45.7559 42.1406 47.4108 40.2522 47.7352C30.5438 49.4033 23.8265 49.4414 13.7562 47.7303C11.8644 47.4089 10.5 45.7524 10.5 43.8335V42.7474Z'
                          className='fill-current'
                          fill='none'
                        />
                      </svg>

                      <span className='text-white text-oya-ghana-header-h7 absolute text-center pb-2'>
                        {seat?.number}
                      </span>
                    </div>
                  </Checkbox>
                ))}
              </div>
            </Checkbox.Group>
          </div>
          <button
            onClick={() => {
              handleClose();
              handleSelected(seatObjArray);
            }}
            className='bg-oya-red-100 text-white rounded-lg w-full h-[35px] flex justify-center items-center'
          >
            <div className='font-medium text-lg'>Select seat</div>
          </button>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default SeatModal;
