import { useState, useCallback, useEffect } from "react";
import TripCode from "../components/Search/TripCode";
import { RouteConstants } from "../helpers/constants";
import { api } from "../helpers/api";
import ReactGA from "react-ga";
import { Schedule } from "../types";
import { Col, Pagination, Row, Skeleton } from "antd";
import ScheduleCard from "../components/Schedules/ScheduleCard";
import CustomButton from "../components/CustomBtn";
import { useNavigate } from "react-router-dom";
import notFound from "../assets/img/not_found.svg";

const TripCodeBooking = () => {
  const navigate = useNavigate();
  const [tripCode, setTripCode] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [schedules, setSchedules] = useState<Schedule[]>([]);

  const [pagination, setPagination] = useState({
    pageSize: 12,
    pageIndex: 0,
  });

  const handleTripCode = useCallback(
    (value: string | undefined) => {
      setTripCode(value);
    },
    // eslint-disable-next-line
    [tripCode]
  );

  const getRides = async () => {
    setLoading(true);
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endofDay = new Date();
    endofDay.setHours(23, 59, 59, 9999);

    let defaultFilter = [];

    defaultFilter.push(
      {
        f: "created_at",
        o: "between",
        p: [`${startOfDay.toISOString()}`, `${endofDay.toISOString()}`],
      },
      {
        f: "status",
        o: "in",
        p: ["LOADING", "SCALED"],
      }
    );

    if (tripCode) {
      defaultFilter.push({
        f: "code",
        o: "contains",
        p: [`${tripCode}`],
      });
    }

    try {
      const { data } = await api.get(
        `${RouteConstants.SCHEDULES_SEARCH}?filters=${JSON.stringify(
          defaultFilter
        )}&limit=${pagination.pageSize}&offset=${Number(
          pagination.pageSize * pagination.pageIndex
        )}`
      );

      setTotal(data?.payload?.total);
      setSchedules(data?.payload?.items);

      setLoading(false);
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  const handlePagination = (page: number) => {
    setPagination({
      ...pagination,
      pageIndex: page - 1,
    });
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    if (tripCode) {
      getRides();
    }
    // eslint-disable-next-line
  }, [tripCode, pagination.pageIndex, pagination.pageSize]);

  return (
    <>
      <TripCode handleTripCodeChange={(value) => handleTripCode(value)} />
      {loading ? (
        <Row className="mx-3 gap-3">
          <Col xs={24} md={12} lg={7}>
            <div className="p-2 border shadow rounded-md">
              <Skeleton paragraph active />
            </div>
          </Col>
          <Col xs={24} md={12} lg={7}>
            <div className="p-2 border shadow rounded-md">
              <Skeleton paragraph active />
            </div>
          </Col>
          <Col xs={24} md={12} lg={7}>
            <div className="p-2 border shadow rounded-md">
              <Skeleton paragraph active />
            </div>
          </Col>
        </Row>
      ) : (
        <>
          {schedules.length < 1 && tripCode ? (
            <div className=" py-3 ">
              <div className="flex flex-col justify-center items-center h-full">
                <img
                  className="w-[50%] sm:w-[40%] h-auto mt-8"
                  src={notFound}
                  alt="404"
                />
                <div className="font-bold sm:text-xl text-center mt-8 text-gray-500">
                  No schedules available
                </div>
                <div className="mt-2">
                  <CustomButton
                    onClick={() => {
                      navigate("/book-advance");
                    }}
                  >
                    Book for advance ticket
                  </CustomButton>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
                {schedules.map((item, index) => (
                  <div className="w-full min-w-[220px]" key={index}>
                    <ScheduleCard item={item} />
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}

      <div className="pt-10 w-full flex justify-end items-end">
        {total > pagination.pageSize && (
          <div className="pagination w-full justify-end items-end">
            <Pagination
              pageSize={pagination.pageSize}
              current={pagination.pageIndex + 1}
              total={total}
              onChange={(page) => {
                handlePagination(page);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TripCodeBooking;
