import { useEffect, useState } from 'react';
import PageLayout from '../utils/PageLayout';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../helpers/utils';
import { ArrowBackIos, Download } from '@mui/icons-material';
import { api } from '../../helpers/api';
import { RouteConstants } from '../../helpers/constants';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input';
import Loader from '../Loader';
import { networks } from '../../helpers/utils';

import { Spin, Form, Divider, Input, Modal, notification } from 'antd';
import Select from 'react-select';
import CustomButton from '../CustomBtn';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import { useParams } from 'react-router-dom';
import { NetworkProvider } from '../../types';
import { PDFDownloadLink } from '@react-pdf/renderer';
import verifyImg from '../../assets/img/verify.gif';
import loadingImg from '../../assets/img/loading.gif';
import { XMarkIcon } from '@heroicons/react/24/outline';
import SignUp from '../Auth/Signup';
import Signin from '../Auth/Signin';
import HshsReceipt from './HshsReceipt';
import { useDispatch, useSelector } from 'react-redux';
import { setHshsBookingPayload, UserState } from '../../redux/reducer';
import checkMarkImg from '../../assets/img/check_mark.svg';

interface SelectType {
  label: string;
  value: number;
}

const HshsPayment = () => {
  const { id } = useParams();
  const token = getCookie('oya_token');

  const user_info = useSelector((state: UserState) => state);

  const [form] = Form.useForm();
  const [countdown, setCountdown] = useState(60);

  //const [summary, setSummary] = useState<any>();
  const [otp, setOtp] = useState('');
  const [otp_required, setOTPRequired] = useState(false);
  const [submittingOTP, setSubmittingOTP] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [selectedProvider, setSelectedProvider] = useState<any>();
  const dispatch = useDispatch();
  const [pay_phone, setPaymentPhone] = useState<string | undefined>();

  const [accordionOpen, setAccordionOpen] = useState(!token ? 2 : 1);
  const [fetchingProviders, setFetchingProviders] = useState(false);
  const [network_providers, setNetworks] = useState<SelectType[] | any>([]);
  const [isCounting, setIsCounting] = useState(false);

  const [socketUrl, setSocketUrl] = useState<string | null>(null);
  const [socketToken, setSocketToken] = useState<string | null>(null);
  const [paymentId, setPaymentId] = useState<any>();

  const [viewReceipt, setViewReceipt] = useState(false);
  //processed
  const [isBusy, setIsBusy] = useState(false);
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [stilProcessing, setStillProcessing] = useState(false);

  const [openLogIn, setOpenLogIn] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  const navigate = useNavigate();

  const { hshs_schedule, hshs_booking_payload, user } = useSelector(
    (state: UserState) => state
  );

  const getNetworkProviders = async () => {
    try {
      if (token) {
        setFetchingProviders(true);
        const { data } = await api.get(RouteConstants.NETWORK_PROVIDER, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const res = data?.payload?.items?.map(
          (item: NetworkProvider, index: number) => ({
            label: item?.name,
            value: item?.code,
          })
        );
        setNetworks(res);

        if (token) {
          if (
            user_info.user?.payment_account_number &&
            user_info.user?.payment_account_provider
          ) {
            form.setFieldValue(
              'account_number',
              user_info.user?.payment_account_number
            );
            form.setFieldValue('account_name', user_info.user?.name);

            const findProvider = res?.find(
              (item: any) =>
                item?.value === user_info.user?.payment_account_provider
            );
            setSelectedProvider(findProvider);
            form.setFieldValue('account_provider', findProvider);
            setPaymentPhone(user_info.user?.payment_account_number);
          }
        }
      } else {
        setNetworks(networks);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFetchingProviders(false);
    }
  };

  const submitOtp = async (paymentId: any) => {
    if (otp) {
      setSubmittingOTP(true);
      try {
        const { data } = await api.post(
          `${RouteConstants.TRANSACTIONS}/${paymentId}/authorize`,
          {
            credential: otp,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        notification.success({
          message: 'OTP Verified',
          description: data.message,
          duration: 15,
        });
        notification.info({
          message: 'Payment Initiated. Waiting for approval...',
          duration: 15,
        });

        const socket = new WebSocket(`${socketUrl}?token=${socketToken}`);
        setIsBusy(true);
        setProcessing(true);
        setSubmitting(false);
        setIsCounting(true);

        // Connection opened
        socket.addEventListener('open', (event) => {
          console.log('socket connection open');
        });

        // Listen for messages
        socket.addEventListener('message', (event) => {
          const data = JSON.parse(event.data);

          if (data?.status?.code === 'SUCCESS') {
            setSuccess(true);
            setProcessing(false);
            setIsCounting(false);
            socket.close();
          }

          if (data?.status?.code === 'FAILED') {
            setFailed(true);
            setSuccess(false);
            setProcessing(false);
            setIsCounting(false);

            socket.close();
          }

          //console.log('Message from server ', event.data);
        });
      } catch (e: any) {
        notification.error({
          message: e?.response?.data?.message || e?.message,
          duration: 15,
        });
        //handleClosed();
        setSubmittingOTP(false);
      }
    } else {
      notification.error({
        message: 'Please ensure all required fields are entered',
      });
    }
  };

  const createTicket = () => {
    setSubmitting(true);

    form
      .validateFields(['account_provider', 'account_name', 'account_number'])
      .then(async (values) => {
        console.log(values);

        try {
          const { data } = await api.post(
            `${RouteConstants.LOADING_POINT_BOOKING}/${id}/pay`,
            {
              account_name: values?.account_name,
              account_number: pay_phone,

              payment_provider: selectedProvider?.value,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          //setSummary(data?.payload);

          setPaymentId(data?.payload?.id);

          if (data?.payload?.auth?.type === 'OTP') {
            setSocketToken(data?.payload?.realtime?.token);
            setSocketUrl(data?.payload?.realtime?.endpoint);

            setIsBusy(true);
            setOTPRequired(true);
            //setInitiatePayment(false);
          } else {
            const socket = new WebSocket(
              `${data?.payload?.realtime?.endpoint}?token=${data?.payload?.realtime?.token}`
            );
            setIsBusy(true);
            setProcessing(true);
            setSubmitting(false);
            setIsCounting(true);

            // Connection opened
            socket.addEventListener('open', (event) => {
              console.log('socket connection open');
            });

            // Listen for messages
            socket.addEventListener('message', (event) => {
              const data = JSON.parse(event.data);

              if (data?.status?.code === 'SUCCESS') {
                setSuccess(true);
                setProcessing(false);
                setIsCounting(false);
                localStorage.removeItem('trd');
                socket.close();
              }

              if (data?.status?.code === 'FAILED') {
                setFailed(true);
                setSuccess(false);
                setProcessing(false);
                setIsCounting(false);

                socket.close();
              }

              //console.log('Message from server ', event.data);
            });
          }
        } catch (e: any) {
          setSubmitting(false);
          notification.error({
            message: e?.response?.data?.message,
          });
        }
      })
      .catch((e) => {
        setSubmitting(false);
        notification.error({
          message: 'Fill required fields to continue',
        });
      });
  };

  const payGuestTicket = () => {
    setSubmitting(true);

    form
      .validateFields(['account_provider', 'account_name', 'account_number'])
      .then(async (values) => {
        console.log(values);

        try {
          const { data } = await api.post(
            `${RouteConstants.LOADING_POINT_BOOKING}/${id}/pay`,
            {
              account_name: values?.account_name,
              account_number: pay_phone,

              payment_provider: selectedProvider?.value,
              passenger: hshs_booking_payload?.passenger,
            }
          );

          if (data?.payload?.auth?.type === 'OTP') {
            setSocketToken(data?.payload?.realtime?.token);
            setSocketUrl(data?.payload?.realtime?.endpoint);

            setIsBusy(true);
            setOTPRequired(true);
            //setInitiatePayment(false);
          } else {
            const socket = new WebSocket(
              `${data?.payload?.realtime?.endpoint}?token=${data?.payload?.realtime?.token}`
            );
            setIsBusy(true);
            setProcessing(true);
            setSubmitting(false);
            setIsCounting(true);

            // Connection opened
            socket.addEventListener('open', (event) => {
              console.log('socket connection open');
            });

            // Listen for messages
            socket.addEventListener('message', (event) => {
              const data = JSON.parse(event.data);

              if (data?.status?.code === 'SUCCESS') {
                setSuccess(true);
                setProcessing(false);
                setIsCounting(false);

                socket.close();
              }

              if (data?.status?.code === 'FAILED') {
                setFailed(true);
                setSuccess(false);
                setProcessing(false);
                setIsCounting(false);

                socket.close();
              }

              //console.log('Message from server ', event.data);
            });
          }
        } catch (e: any) {
          setSubmitting(false);
          notification.error({
            message: e?.response?.data?.message,
          });
        }
      })
      .catch((e) => {
        setSubmitting(false);
        notification.error({
          message: 'Fill required fields to continue',
        });
      });
  };

  const handleOpen = (value: any) =>
    setAccordionOpen(accordionOpen === value ? 0 : value);

  function Icon({ id, open }: any) {
    return (
      <svg
        className={`${
          id === open
            ? 'transition delay-150 -rotate-90'
            : 'transition delay-150'
        }  transition-transform`}
        xmlns='http://www.w3.org/2000/svg'
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
      >
        <path
          d='M26.5599 11.9333L17.8666 20.6267C16.8399 21.6533 15.1599 21.6533 14.1333 20.6267L5.43994 11.9333'
          stroke='#B40303'
          stroke-width='3'
          stroke-miterlimit='10'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    );
  }

  useEffect(() => {
    getNetworkProviders();
    //getScheduleInfo();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timer: any;
    if (isCounting && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setFailed(true);
      setProcessing(false);
      setSuccess(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isCounting, countdown]);

  return (
    <PageLayout>
      <div className='pt-[4rem] px-[1rem] sm:px-[8rem] bg-white min-h-[100vh]'>
        <Spin
          spinning={submitting}
          className='py-10 md:py-20 min-h-[50vh] flex justify-center items-center'
          indicator={
            <Loader
              float
              bgColor='transparent appear'
              width='w-16'
              height='h-16'
            />
          }
        >
          <Signin
            isOpen={openLogIn}
            handleClosed={() => setOpenLogIn(false)}
            openSignUp={() => setOpenRegister(true)}
          />

          <SignUp
            isOpen={openRegister}
            handleClosed={() => setOpenRegister(false)}
            openSignIn={() => setOpenLogIn(true)}
          />
          {/* process modal */}
          {isBusy && (
            <Modal open={isBusy} footer={null} closable={false}>
              {otp_required && (
                <Form form={form} layout='vertical' className='appear'>
                  <div className='pb-4'>
                    <div className='font-semibold text-[20px] text-center'>
                      Verify Payment Mobile Number
                    </div>
                    <div>Enter OTP sent to your number</div>
                  </div>
                  <Form.Item
                    colon={false}
                    label='OTP'
                    name='otp'
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <div className='flex justify-center mt-4 mb-3'>
                      <img src={verifyImg} alt='verify' />
                    </div>
                    <Input
                      size='large'
                      disabled={submittingOTP}
                      placeholder='000000'
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </Form.Item>
                  <div className='text-right flex items-end gap-3 justify-end'>
                    <CustomButton
                      data-testid='payAdvanceTicket'
                      onClick={() => {
                        submitOtp(paymentId);
                      }}
                    >
                      Pay
                    </CustomButton>

                    <CustomButton
                      data-testid='cancelAdvanceTicketPay'
                      variant='secondary'
                      onClick={() => {
                        setIsBusy(false);
                        setOTPRequired(false);
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </CustomButton>
                  </div>
                </Form>
              )}
              {processing && (
                <div>
                  <div className='flex justify-center mt-4'>
                    <div className='text-oya-yellow-500 text-center text-[24px] font-semibold'>
                      Processing payment
                    </div>
                  </div>
                  <div className='text-center py-3'>
                    You will receive a prompt to enter your Pin to complete this
                    transaction.
                  </div>
                  <div className='text-center text-[20px] py-4'>
                    {countdown}
                  </div>
                  <Divider className='my-4' />
                  <div className='text-oya-yellow-500 text-center text-[24px] font-semibold'>
                    Processing...
                  </div>
                </div>
              )}

              {success && (
                <>
                  {token ? (
                    <div>
                      <div className='w-full flex justify-end items-end'>
                        <button
                          data-testid='advanceBookingSuccess'
                          onClick={() => {
                            navigate(-1);
                            setIsBusy(false);
                            setSuccess(false);
                            dispatch(setHshsBookingPayload(undefined));
                          }}
                        >
                          <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
                        </button>
                      </div>
                      <div className='flex flex-col justify-center mt-4 items-center gap-3'>
                        {/* <img src={successImg} alt='processing' /> */}
                        <div className='border-[4px] rounded-full w-[63px] h-[63px] border-seat-green flex items-center justify-center'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='25'
                            height='18'
                            viewBox='0 0 25 18'
                            fill='none'
                          >
                            <path
                              d='M1.5 9.29247L8.79247 16.5849L23.34 2'
                              stroke='#2ABB7F'
                              stroke-width='2.5'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                            />
                          </svg>
                        </div>
                        <div className='text-center text-[24px] font-semibold text-seat-green py-2'>
                          Booking successful!
                        </div>
                        <div className='text-[16px] text-center'>
                          Your trip has been booked successfully.
                        </div>

                        <button
                          data-testid='viewAdvanceBookingReceipt'
                          className=' text-oya-red-50 text-center'
                          onClick={() => {
                            setViewReceipt(true);
                            setIsBusy(false);
                            setProcessing(false);
                            setSuccess(false);
                          }}
                        >
                          View receipt
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className='success-popup'>
                      <div className='w-full flex justify-end items-end'>
                        <button
                          data-testid='advanceTicketSuccess'
                          onClick={() => {
                            navigate(-1);
                            setIsBusy(false);
                            setSuccess(false);
                            dispatch(setHshsBookingPayload(undefined));
                          }}
                        >
                          <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
                        </button>
                      </div>
                      <div className='flex flex-col justify-center mt-4 items-center gap-3'>
                        {/* <img src={successImg} alt='processing' /> */}
                        <div className='border-[4px] rounded-full w-[63px] h-[63px] border-seat-green flex items-center justify-center'>
                          <img src={checkMarkImg} alt='check mark' />
                        </div>
                        <div className='text-center text-[24px] font-semibold text-seat-green py-2'>
                          Booking successful!
                        </div>
                      </div>
                      <div className='text-[16px] text-center'>
                        Your trip has been booked successfully.
                      </div>
                      <div className='text-oya-red-50 text-center text-[16px]'>
                        <button
                          data-testid='viewAdvanceTicketReceipt'
                          onClick={() => {
                            setViewReceipt(true);
                            setIsBusy(false);
                            setProcessing(false);
                            setSuccess(false);
                          }}
                        >
                          View receipt
                        </button>
                      </div>
                      {!token && (
                        <>
                          <Divider />
                          <div className='text-center text-[16px]'>
                            Sign up with Oya to{' '}
                            <span className='text-oya-red-50'>
                              see your trip history
                            </span>
                            ,{' '}
                            <span className='text-oya-red-50'>
                              rate your trip
                            </span>
                            ,
                            <span className='text-oya-red-50'>
                              report incidents
                            </span>{' '}
                            and{' '}
                            <span className='text-oya-red-50'>
                              share your trip
                            </span>
                          </div>

                          <div className='flex justify-center items-center gap-4 mt-4'>
                            <CustomButton
                              data-testid='createAccount'
                              onClick={() => {
                                setIsBusy(false);
                                setSuccess(false);
                                setOpenRegister(true);
                              }}
                            >
                              Create account
                            </CustomButton>
                            <CustomButton
                              data-testid='login'
                              onClick={() => {
                                setIsBusy(false);
                                setSuccess(false);
                                setOpenLogIn(true);
                              }}
                              variant='secondary'
                            >
                              Login
                            </CustomButton>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
              {failed && (
                <div>
                  <div className='w-full flex justify-end items-end'>
                    <button
                      data-testid='advanceBookingFailed'
                      onClick={() => {
                        navigate(-1);
                        setIsBusy(false);
                        setSuccess(false);
                        dispatch(setHshsBookingPayload(undefined));
                      }}
                    >
                      <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
                    </button>
                  </div>

                  <div className='flex items-center gap-3'>
                    <XMarkIcon className='w-12 h-12 text-red-500' />
                    <div className='text-center text-[24px] font-semibold text-red-500 py-1'>
                      Payment Failed
                    </div>
                  </div>
                  <div className='flex items-center gap-1'>
                    <div className='text-center py-3 text-lg'>
                      We could not process your payment. Try again later.
                    </div>
                  </div>
                  <div className='flex items-center justify-center'>
                    <CustomButton
                      data-testid='closeAdvanceBooking'
                      onClick={() => {
                        //setStillProcessing(false);
                        setIsBusy(false);
                        setFailed(false);
                        dispatch(setHshsBookingPayload(undefined));

                        navigate(-1);
                      }}
                    >
                      Close
                    </CustomButton>
                  </div>
                </div>
              )}
              {stilProcessing && (
                <div>
                  <div className='w-full flex justify-end items-end'>
                    <button
                      data-testid='processingAdvanceBooking'
                      onClick={() => {
                        navigate(-1);
                        setIsBusy(false);
                        setSuccess(false);
                      }}
                    >
                      <XMarkIcon className='w-[20px] h-[20px] text-oya-gray' />
                    </button>
                  </div>
                  <div className='flex justify-center mt-4 items-center gap-3'>
                    <img src={loadingImg} alt='processing' />
                  </div>

                  <div className='flex justify-between items-center'>
                    <div className='text-center py-3 text-base'>
                      This is taking longer than expected. This could be as a
                      result of the payment provider's prompt failing.
                    </div>
                  </div>

                  <button
                    data-testid='dismissAdvanceBooking'
                    className='text-oya-red-50 font-semibold hover:text-red-400'
                    onClick={() => {
                      //setStillProcessing(false);
                      setIsBusy(false);
                      setStillProcessing(false);
                      dispatch(setHshsBookingPayload(undefined));

                      navigate(-1);
                    }}
                  >
                    Dismiss
                  </button>
                </div>
              )}
            </Modal>
          )}

          <div>
            <button
              // to={{
              //   pathname: `${'/hshs'}`,
              //   search: `?sid=${id}`,
              // }}
              className='mt-[2rem] mb-5 flex gap-3 items-center'
              onClick={() => {
                navigate(-1);

                if (success) {
                  dispatch(setHshsBookingPayload(undefined));
                }
              }}
            >
              <ArrowBackIos
                sx={{
                  width: '20px',
                  height: '20px',
                  color: '#B40303',
                }}
              />
              <div className='text-oya-red-100 font-semibold text-lg'>Back</div>
              {viewReceipt && (
                <div className='text-oya-red-100 font-semibold text-[24px] ml-4'>
                  Receipt
                </div>
              )}
            </button>

            {viewReceipt ? (
              <div className='flex flex-col items-center justify-center w-full'>
                <div className='lg:w-[70%] w-full mt-4 p-2 border-b border-oya-gray-100'>
                  <div className='flex items-center'>
                    <div className='flex-[0.5]'>
                      <div className='font-semibold'>Route</div>
                    </div>
                    <div className='flex-[0.5]'>
                      <div>
                        {hshs_schedule?.loading_point?.source?.name} to{' '}
                        {hshs_schedule?.loading_point?.destination?.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='lg:w-[70%] w-full mt-4 p-2 border-b border-oya-gray-100'>
                  <div className='flex items-center'>
                    <div className='flex-[0.5]'>
                      <div className='font-semibold'>Travel date</div>
                    </div>
                    <div className='flex-[0.5]'>
                      <div>
                        {moment(hshs_schedule?.departures_at).format('LLL')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='lg:w-[70%] w-full mt-4 p-2 border-b border-oya-gray-100'>
                  <div className='flex items-center'>
                    <div className='flex-[0.5]'>
                      <div className='font-semibold'>Pickup</div>
                    </div>
                    <div className='flex-[0.5]'>
                      <div>{hshs_schedule?.loading_point?.label}</div>
                    </div>
                  </div>
                </div>

                <div className='lg:w-[70%] w-full mt-4 p-2 border-b border-oya-gray-100'>
                  <div className='flex items-center'>
                    <div className='flex-[0.5]'>
                      <div className='font-semibold'>Luggage</div>
                    </div>
                    <div className='flex-[0.5]'>
                      <div>
                        {hshs_booking_payload?.luggage_sizes ? (
                          <div className='flex flex-wrap items-center'>
                            {hshs_booking_payload?.luggage_sizes?.map(
                              (item: any, index: number) => (
                                <div key={index} className='flex'>
                                  <div className='flex items-center gap-1'>
                                    <div>{item?.number_of_luggages}</div>
                                    <div>{item?.luggage_size_code}</div>
                                    {hshs_booking_payload?.luggage_sizes
                                      ?.length && (
                                      <div>
                                        {index <
                                        hshs_booking_payload?.luggage_sizes
                                          ?.length -
                                          1
                                          ? ', '
                                          : ''}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <div>Traveling without luggage</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='lg:w-[70%] w-full mt-4 p-2 border-b border-oya-gray-100'>
                  <div className='flex items-center'>
                    <div className='flex-[0.5]'>
                      <div className='font-semibold'>Payment method</div>
                    </div>
                    <div className='flex-[0.5]'>
                      <div>{selectedProvider?.label}</div>
                    </div>
                  </div>
                </div>
                <div className='lg:w-[70%] w-full mt-4 p-2 border-b border-oya-gray-100'>
                  <div className='flex items-center'>
                    <div className='flex-[0.5]'>
                      <div className='font-semibold'>Trip fare</div>
                    </div>
                    <div className='flex-[0.5]'>
                      <div>
                        {hshs_schedule?.price?.currency}
                        {Intl.NumberFormat('en-EN', {
                          minimumFractionDigits: 2,
                        }).format(Number(hshs_schedule?.price?.amount))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='lg:w-[70%] w-full mt-4 p-2 border-b border-oya-gray-100'>
                  <div className='flex items-center'>
                    <div className='flex-[0.5]'>
                      <div className='font-semibold'>Total</div>
                    </div>
                    <div className='flex-[0.5]'>
                      <div>
                        {hshs_schedule?.price?.currency}
                        {Intl.NumberFormat('en-EN', {
                          minimumFractionDigits: 2,
                        }).format(Number(hshs_schedule?.price?.amount))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex justify-end items-end lg:w-[70%] w-full mt-9'>
                  <CustomButton data-testid='downloadAdvanceBookingReceipt'>
                    <PDFDownloadLink
                      document={
                        <HshsReceipt
                          method={selectedProvider?.label}
                          amount={hshs_schedule?.price?.amount}
                          hshs_booking_payload={hshs_booking_payload}
                          hshs_schedule={hshs_schedule}
                        />
                      }
                      fileName={`ticket-${id}-receipt.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          'Loading document...'
                        ) : (
                          <div className='flex gap-2 items-center'>
                            <Download />

                            <div>Download receipt</div>
                          </div>
                        )
                      }
                    </PDFDownloadLink>
                  </CustomButton>
                </div>
              </div>
            ) : (
              <Form layout='vertical' form={form}>
                <div className='lg:flex flex-wrap gap-6'>
                  <div className='flex-[0.6] '>
                    {!token ? (
                      <>
                        {/* Personal details */}

                        {/* <Accordion
                          open={accordionOpen === 1}
                          icon={<Icon id={1} open={accordionOpen} />}
                          placeholder=''
                        >
                          <AccordionHeader
                            onClick={() => handleOpen(1)}
                            className='border-none'
                          >
                            <div className='flex items-center font-semibold text-oya-red-100 gap-5 text-[18px] sm:text-[24px]'>
                              <h2>1</h2>
                              <h2>Personal details</h2>
                            </div>
                          </AccordionHeader>
                          <AccordionBody>
                            {accordionOpen === 1 && (
                              <div className='card-container font-medium text-[16px] mt-4 mb-8 lg:mx-8'>
                                <Form.Item
                                  name='user_name'
                                  initialValue={
                                    tripFieldsJson?.passenger?.first_name
                                  }
                                  label={
                                    <label className='ml-2'>
                                      Your first name
                                    </label>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                    {
                                      validator: validateUserName,
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder='Enter first name'
                                    className='border border-oya-gray-200 h-[50px] rounded-lg'
                                  />
                                </Form.Item>
                                <Form.Item
                                  name='user_last_name'
                                  initialValue={
                                    tripFieldsJson?.passenger?.last_name
                                  }
                                  label={
                                    <label className='ml-2'>
                                      Your last name
                                    </label>
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                    {
                                      validator: validateUserName,
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder='Enter last name'
                                    className='border border-oya-gray-200 h-[50px] rounded-lg'
                                  />
                                </Form.Item>

                                <Form.Item
                                  colon={false}
                                  label={
                                    <label className='ml-2'>
                                      Your mobile number
                                    </label>
                                  }
                                  name='user_phone'
                                  initialValue={
                                    tripFieldsJson?.passenger?.phone
                                  }
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}
                                >
                                  <PhoneInput
                                    value={phone}
                                    onChange={(e) => {
                                      let formatted = e?.replace(/\s+/g, '');
                                      setPhone(formatted);
                                    }}
                                    placeholder='Enter your mobile number'
                                    className='phone bg-white w-full px-4 border border-oya-gray-200 rounded-lg outline-none h-[50px] focus:border-gray-200 focus:outline-none'
                                    defaultCountry='GH'
                                  />
                                </Form.Item>

                                <Form.Item
                                  colon={false}
                                  initialValue={
                                    tripFieldsJson?.passenger?.ice_phone
                                  }
                                  label={
                                    <label className='ml-2'>
                                      Primary emergency contact
                                    </label>
                                  }
                                  name='user_ice_phone'
                                  rules={[
                                    {
                                      required: true,
                                      message: 'This field is required',
                                    },
                                  ]}
                                >
                                  <PhoneInput
                                    value={icePhone}
                                    onChange={(e) => {
                                      let formatted = e?.replace(/\s+/g, '');
                                      setIcePhone(formatted);
                                    }}
                                    placeholder='Enter your primary emergency number'
                                    className='phone bg-white w-full px-4 border border-oya-gray-200 rounded-lg outline-none h-[50px] focus:border-gray-200 focus:outline-none'
                                    defaultCountry='GH'
                                  />
                                </Form.Item>
                              </div>
                            )}
                          </AccordionBody>
                        </Accordion>

                        <Divider className='border-oya-gray mt-2' /> */}

                        {/* Payment info */}
                        <Accordion
                          open={accordionOpen === 2}
                          icon={<Icon id={2} open={accordionOpen} />}
                          placeholder=''
                        >
                          <AccordionHeader
                            onClick={() => handleOpen(2)}
                            className='border-none'
                          >
                            <div className='flex items-center font-semibold text-oya-red-100 gap-5 text-[24px]'>
                              <h2>1</h2>
                              <h2>Payment Details</h2>
                            </div>
                          </AccordionHeader>
                          <AccordionBody>
                            {accordionOpen === 2 && (
                              <>
                                <div className='rounded-lg border-oya-gray-200 border p-3  mb-6 sm:mx-8'>
                                  <Form.Item
                                    colon={false}
                                    label={
                                      <label className='ml-2'>
                                        Your payment provider
                                      </label>
                                    }
                                    name='account_provider'
                                    rules={[
                                      {
                                        required: true,
                                        message: 'This field is required',
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder='Select payment provider'
                                      onChange={(e) => {
                                        // let findOne = networks.find(
                                        //   (value) => value?.code === e
                                        // );
                                        setSelectedProvider(e);
                                      }}
                                      options={network_providers}
                                      isSearchable
                                      isClearable
                                      isLoading={fetchingProviders}
                                      styles={{
                                        container(base, props) {
                                          return {
                                            ...base,
                                            height: '48px',
                                            borderWidth: '1px',
                                            borderRadius: '8px',
                                            borderColor: '#939291',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                          };
                                        },
                                        control(base, props) {
                                          return {
                                            ':active': {
                                              borderColor: '#ccc',
                                            },
                                            width: '100%',
                                            display: 'flex',
                                          };
                                        },
                                      }}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    colon={false}
                                    label={
                                      <label className='ml-2'>
                                        Your payment number
                                      </label>
                                    }
                                    name='account_number'
                                    rules={[
                                      {
                                        required: true,
                                        message: 'This field is required',
                                      },
                                    ]}
                                  >
                                    <PhoneInput
                                      value={pay_phone}
                                      onChange={(e) => {
                                        let formatted = e?.replace(/\s+/g, '');
                                        setPaymentPhone(formatted);
                                      }}
                                      placeholder='Enter your payment number'
                                      className='phone bg-white w-full px-4 border border-oya-gray-200 rounded-lg outline-none h-[50px] focus:border-gray-200 focus:outline-none'
                                      defaultCountry='GH'
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    colon={false}
                                    label={
                                      <label className='ml-2'>
                                        Name on your mobile money wallet
                                      </label>
                                    }
                                    name='account_name'
                                    rules={[
                                      {
                                        required: true,
                                        message: 'This field is required',
                                      },
                                      // {
                                      //   validator: validateUserName,
                                      // },
                                    ]}
                                  >
                                    <Input
                                      placeholder='Enter your payment name'
                                      className='border border-oya-gray-200 h-[50px] rounded-lg'
                                    />
                                  </Form.Item>
                                </div>
                              </>
                            )}
                          </AccordionBody>
                        </Accordion>
                      </>
                    ) : (
                      <>
                        {/* Payment info */}
                        <Accordion
                          open={accordionOpen === 1}
                          icon={<Icon id={1} open={accordionOpen} />}
                          placeholder=''
                        >
                          <AccordionHeader
                            onClick={() => handleOpen(1)}
                            className='border-none'
                          >
                            <div className='flex mt-4 items-center font-semibold text-oya-red-100 gap-5 text-[24px]'>
                              <h2>1</h2>
                              <h2>Payment Details</h2>
                            </div>
                          </AccordionHeader>
                          <AccordionBody>
                            {accordionOpen === 1 && (
                              <>
                                <div className='rounded-lg border-oya-gray-200 border p-3  mb-6 sm:mx-8'>
                                  <Form.Item
                                    colon={false}
                                    label={
                                      <label className='ml-2'>
                                        Your payment provider
                                      </label>
                                    }
                                    name='account_provider'
                                    rules={[
                                      {
                                        required: true,
                                        message: 'This field is required',
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder='Select payment provider'
                                      onChange={(e) => {
                                        // let findOne = networks.find(
                                        //   (value) => value?.code === e
                                        // );
                                        setSelectedProvider(e);
                                      }}
                                      options={networks}
                                      isSearchable
                                      isClearable
                                      isLoading={fetchingProviders}
                                      styles={{
                                        container(base, props) {
                                          return {
                                            ...base,
                                            height: '48px',
                                            borderWidth: '1px',
                                            borderRadius: '8px',
                                            borderColor: '#939291',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                          };
                                        },
                                        control(base, props) {
                                          return {
                                            ':active': {
                                              borderColor: '#ccc',
                                            },
                                            width: '100%',
                                            display: 'flex',
                                          };
                                        },
                                      }}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    colon={false}
                                    label={
                                      <label className='ml-2'>
                                        Your payment number
                                      </label>
                                    }
                                    name='account_number'
                                    rules={[
                                      {
                                        required: true,
                                        message: 'This field is required',
                                      },
                                    ]}
                                  >
                                    <PhoneInput
                                      value={pay_phone}
                                      onChange={(e) => {
                                        let formatted = e?.replace(/\s+/g, '');
                                        setPaymentPhone(formatted);
                                      }}
                                      placeholder='Enter your payment number'
                                      className='phone bg-white w-full px-4 border border-oya-gray-200 rounded-lg outline-none h-[50px] focus:border-gray-200 focus:outline-none'
                                      defaultCountry='GH'
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    colon={false}
                                    label={
                                      <label className='ml-2'>
                                        Name on your mobile money wallet
                                      </label>
                                    }
                                    name='account_name'
                                    rules={[
                                      {
                                        required: true,
                                        message: 'This field is required',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder='Enter your payment name'
                                      className='border border-oya-gray-200 h-[50px] rounded-lg'
                                    />
                                  </Form.Item>
                                </div>
                              </>
                            )}
                          </AccordionBody>
                        </Accordion>
                      </>
                    )}
                  </div>
                  <div className=' flex-[0.4]'>
                    <div className='card-container p-3 lg:fixed lg:w-[393px]'>
                      <div className='sm:text-lg mb-3 font-semibold'>
                        Ticket Summary
                      </div>
                      <div className='my-3'>
                        <div className='text-oya-red-50'>Trip details</div>

                        <div className='flex justify-between items-center'>
                          <div>Trip</div>

                          <div>
                            {hshs_schedule?.loading_point?.source?.name}{' '}
                            {hshs_schedule?.loading_point?.destination?.name}
                          </div>
                        </div>
                        <div className='flex justify-between items-center'>
                          <div>Pickup</div>

                          <div>{hshs_schedule?.loading_point?.label}</div>
                        </div>
                        <div className='flex justify-between items-center'>
                          <div>Departure</div>
                          <div>
                            {moment(hshs_schedule?.departures_at).format('LLL')}
                          </div>
                        </div>
                      </div>
                      {/* Personal */}
                      <Divider className='border-oya-gray my-2' />
                      <div>
                        <div className='text-oya-red-50'>Personal info</div>
                        {token && user ? (
                          <div>
                            <div className='flex justify-between items-center'>
                              <div>Name</div>

                              <div>{user?.name}</div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Phone</div>
                              <div>{user?.phone}</div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Primary Emergency Contact</div>
                              <div>{user?.ice1_phone}</div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className='flex justify-between items-center'>
                              <div>Name</div>

                              <div>
                                {hshs_booking_payload?.passenger?.first_name}{' '}
                                {hshs_booking_payload?.passenger?.last_name}
                              </div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Phone</div>
                              <div>
                                {hshs_booking_payload?.passenger?.phone}
                              </div>
                            </div>
                            <div className='flex justify-between items-center'>
                              <div>Primary Emergency Contact</div>
                              <div>
                                {hshs_booking_payload?.passenger?.ice_phone}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* Payment */}
                      <Divider className='border-oya-gray mt-2' />

                      <div>
                        <div className='text-oya-red-50'>Payment details</div>
                        <div className='flex justify-between items-center'>
                          <div>Payment provider</div>
                          <div>
                            {selectedProvider && (
                              <div>{selectedProvider?.label}</div>
                            )}
                          </div>
                        </div>
                        <div className='flex justify-between items-center'>
                          <div>Payment number</div>
                          <div>{pay_phone && <div>{pay_phone}</div>}</div>
                        </div>
                        <div className='flex justify-between items-center'>
                          <div>Payment name</div>
                          <div>
                            {form.getFieldValue('account_name') && (
                              <span>{form.getFieldValue('account_name')}</span>
                            )}
                          </div>
                        </div>
                      </div>

                      <Divider className='border-oya-gray my-2' />
                      <div className='my-3'>
                        <div className='flex justify-between items-center font-semibold'>
                          <div>Total amount</div>
                          <div>
                            {hshs_schedule?.price?.currency}
                            {Intl.NumberFormat('en-EN', {
                              minimumFractionDigits: 2,
                            }).format(Number(hshs_schedule?.price?.amount))}
                          </div>
                        </div>
                        <div className='text-xs text-oya-red-50 py-3'>
                          *Transaction charges may apply.
                        </div>
                      </div>

                      <CustomButton
                        data-testid='payToBook'
                        width='w-full'
                        onClick={() => {
                          if (token) {
                            createTicket();
                          } else {
                            payGuestTicket();
                          }
                        }}
                      >
                        Pay to book trip
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </div>
        </Spin>
      </div>
    </PageLayout>
  );
};

export default HshsPayment;
