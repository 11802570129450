import React from "react";
import Select from "react-select";
import { components } from "react-select";

type Props = {
  handleChange: (value: boolean) => void;
};

const comfort = [
  {
    label: "Regular",
    value: false,
  },
  {
    label: "Premium",
    value: true,
  },
];

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.5999 7.4585L11.1666 12.8918C10.5249 13.5335 9.4749 13.5335 8.83324 12.8918L3.3999 7.4585"
          stroke="#484442"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const Comfort = ({ handleChange }: Props) => {
  return (
    // <Dropdown menu={{ items }}>
    //   <div className='dropdown-container'>
    //     <div>Comfort</div>
    //     <div>
    //       <svg
    //         xmlns='http://www.w3.org/2000/svg'
    //         width='24'
    //         height='24'
    //         viewBox='0 0 24 24'
    //         fill='none'
    //       >
    //         <path
    //           d='M19.92 8.95L13.4 15.47C12.63 16.24 11.37 16.24 10.6 15.47L4.08 8.95'
    //           stroke='#484442'
    //           stroke-width='1.5'
    //           stroke-miterlimit='10'
    //           stroke-linecap='round'
    //           stroke-linejoin='round'
    //         />
    //       </svg>
    //     </div>
    //   </div>
    // </Dropdown>

    <Select
      options={comfort}
      onChange={(value: any) => {
        handleChange(value?.value);
      }}
      placeholder="Comfort"
      components={{ DropdownIndicator }}
      isClearable
      styles={{
        container(base, props) {
          return {
            ...base,
            height: "40px",
            borderWidth: "1px",
            borderRadius: "12px",
            borderColor: "#939291",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "#fff",
          };
        },
        control(base, props) {
          return {
            ":active": {
              borderColor: "#ccc",
            },
            width: "100%",
            display: "flex",
          };
        },
      }}
    />
  );
};

export default Comfort;
