import React, { useState } from "react";
import { Modal, notification } from "antd";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CustomButton from "../../components/CustomBtn";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@material-tailwind/react";
import { auth } from "../../helpers/api";
import { RouteConstants } from "../../helpers/constants";
import { getCookie } from "../../helpers/utils";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

const VerifyModal = ({ isOpen, handleClose }: Props) => {
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(false);
  const user: any = getCookie("oya_user_info");

  const handleSubmit = async () => {
    if (user) {
      const data = JSON.parse(user);

      setIsBusy(true);
      try {
        await auth.post(RouteConstants.SEND_OTP, {
          phone: data?.phone,
        });
        notification.success({
          message: "Verification code sent",
        });

        handleClose();
        navigate("/verify-phone");
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.message || error?.message,
        });
        setIsBusy(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Modal open={isOpen} footer={null} closable={false} className="z-[9999]">
        <div>
          <div className="w-full flex justify-end items-end">
            <button onClick={() => handleClose()}>
              <XMarkIcon className="w-5 h-5 text-oya-gray" />
            </button>
          </div>

          <div>
            <div className="header-text-3 text-center">Verify account</div>
            <div className="py-4 text-center">
              Your account has not been verified, verify now to use the
              application{" "}
            </div>

            <div className="mt-[30px] flex justify-center items-center">
              <CustomButton
                width="w-full"
                onClick={() => handleSubmit()}
                disabled={isBusy}
                data-testid="login-mobile"
              >
                {isBusy ? (
                  <div className="w-full flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  <span>Verify account now</span>
                )}
              </CustomButton>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default VerifyModal;
