import { useState, useEffect } from 'react';
import moment from 'moment';
import { CalendarMonth } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Prompt from '../Auth/Prompt';
import ReactGA from 'react-ga';
import premiumIcon from '../../assets/img/premiumIcon.svg';
import locationIcon from '../../assets/img/location.svg';
import destLocationIcon from '../../assets/img/destination_location.svg';
import { useDispatch } from 'react-redux';
import { setHshsSchedule } from '../../redux/reducer';

const LoadingPointScheduleCard = ({ item }: { item: any }) => {
  const [authenticated, setNoAuth] = useState(false);
  const [isMobile, setIsMobile] = useState(true);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkScreenSize);

    checkScreenSize();

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div className='schedule-card mb-3 items-start justify-start text-left min-h-[400]: max-h-[400] '>
      <Prompt
        isOpen={authenticated}
        handleClosed={() => setNoAuth(false)}
        scheduleId={item?.id}
      />
      <div className='flex items-center justify-between sm:gap-[30px] p-2 '>
        <div className='flex items-center gap-1 h-8'>
          <CalendarMonth sx={{ color: 'red' }} />
          <div className='text-[14px] font-semibold'>
            {moment(item?.departures_at).format('LLL')}
          </div>
        </div>
        {item?.premium && (
          // <div className='premium-container text-oya-red-100 font-semibold'>
          //   Premium
          // </div>
          <div className='bg-[#FFF7D6] p-2 rounded-md'>
            <img src={premiumIcon} alt='premium' />
          </div>
        )}
      </div>

      <div className='w-full border-b border-oya-gray-50 h-[1px]' />

      <div className='px-4 py-2 text-[14px]'>
        <div className='flex gap-1 items-center'>
          <div className='w-[20px] h-[20px] rounded-full border-[1.5px] border-oya-gray-200 flex items-center justify-center'>
            <div className='w-[10px] h-[10px] bg-oya-gray rounded-full' />
          </div>
          <div>{item?.loading_point?.source?.name}</div>
        </div>
        <div className='flex justify-between items-center'>
          <div className='flex items-center justify-center w-[20px]'>
            <img src={locationIcon} alt='start location' />
          </div>
        </div>
        <div className='flex justify-between items-center'>
          <div className='flex gap-1 items-center'>
            <img src={destLocationIcon} alt='destination icon' />
            <div>{item?.loading_point?.destination?.name}</div>
          </div>
        </div>
      </div>

      <div className='flex px-4 pb-3 text-[10px]'>
        <div className='text-oya-red-50'>
          Pickup point is{' '}
          {item?.loading_point?.label ? ` ${item?.loading_point?.label}` : ''}
        </div>
      </div>

      <div className='w-full h-[1px] bg-oya-gray-50' />
      <div className='py-1'>
        <div className='flex items-center justify-between mx-4'>
          <div>
            {item?.price?.currency && (
              <div className='font-semibold text-[16px]'>
                {Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: item?.price?.currency,
                }).format(parseFloat(item?.price?.amount))}
              </div>
            )}
          </div>
          <button
            data-testid='bookTrip'
            className='w-[115px] h-[35px] rounded-[8px] bg-oya-red-100 text-white text-[12px]'
            onClick={() => {
              ReactGA.event({
                category: `${process.env.NODE_ENV} HSHS ticket`,
                action: 'View trip',
                label: 'Book trip',
              });
              dispatch(setHshsSchedule(item));
              if (isMobile) {
                navigate(`/mb-hshs/${item?.id}`);
              } else {
                navigate(`/hshs?sid=${item?.id}`);
              }
            }}
          >
            <div>Book Trip</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoadingPointScheduleCard;
