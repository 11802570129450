import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

type Props = {};

const AppInfo = (props: Props) => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content={"#B40303"}
        />
        <meta name="theme-color" content={"#fff"} />
      </Helmet>
      <div className="bg-[#fff] relative min-h-screen overflow-hidden">
        <div className="top-0 fixed z-[100] w-full">
          <div className="relative w-full inline-flex">
            <div className="bg-white h-[44px] w-full pt-5 pb-3 px-[15px] ">
              <div className="flex items-center">
                <div className="flex-[0.3]">
                  <button onClick={() => navigate(-1)}>
                    <ChevronLeftIcon className="w-7 h-7 " />
                  </button>
                </div>
                <div className="flex-[0.7]">
                  <div className="header-text-4">App info</div>
                </div>
              </div>
            </div>
          </div>

          <div className="min-h-screen flex-1 flex flex-col justify-center items-center">
            <div>
              <img src={require("../../assets/img/logo.png")} alt="app logo" />
            </div>
            <div className="text-[26px] text-oya-gray font-semibold py-6">
              Bus Ride Passenger
            </div>
            <div>version 1.0</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AppInfo;
