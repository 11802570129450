import React from "react";
import { useMediaQuery } from "@mui/material";

type Props = {};

const HeroTwoLarge = (props: Props) => {
    const isLarge = useMediaQuery("(min-width:500px)");
    const phoneSize = useMediaQuery("(min-width:700px)");

    return (
        <div className="hero-two-large bg-gray-50">
            {!isLarge && (
                <div className="font-[900] pl-[1rem] lg:pl-[4rem] xl:pl-[8rem] text-[24px] sm:text-[30px] lg:text-[50px] lg:leading-[50px] pt-4">
                    <div>To join a bus to anywhere in Ghana,</div>
                    <div>
                        Enroll yourself with{" "}
                        <span className="text-red-700">Oya!</span>
                    </div>
                </div>
            )}

            <div className="flex justify-center">
                <div className="flex-[0.5]">
                    {isLarge && (
                        <div className="font-[900] pl-[1rem] lg:pl-[4rem] xl:pl-[8rem] text-[24px] sm:text-[30px] lg:text-[40px] xl:text-[50px] lg:leading-[50px] pt-4">
                            <div>To join a bus to anywhere in Ghana,</div>
                            <div>
                                Enroll yourself with{" "}
                                <span className="text-red-700">Oya!</span>
                            </div>
                        </div>
                    )}
                    <div className="mt-4 xl:mt-10 pl-[1rem] lg:pl-[4rem] xl:pl-[8rem] ">
                        <div className="sm:flex gap-3">
                            <button
                                data-testid="playStoreLg"
                                onClick={() => {
                                    window.open(
                                        "https://play.google.com/store/apps/details?id=com.oyaghana.oyaapp_porter&pli=1",
                                        "_blank"
                                    );
                                }}
                                className=" hover:bg-opacity-70"
                            >
                                <img
                                    className="sm:w-full w-[60%]"
                                    src="https://res.cloudinary.com/djp5oy3ri/image/upload/v1701973247/beehive_oya/way8jnbzu0tjemcbsdcl.svg"
                                    alt="google play"
                                />
                            </button>
                            <button
                                data-testid="appStoreLg"
                                onClick={() => {
                                    window.open(
                                        "https://apps.apple.com/us/app/oya/id1623866371",
                                        "_blank"
                                    );
                                }}
                                className=""
                            >
                                <img
                                    className="sm:w-full w-[60%]"
                                    src="https://res.cloudinary.com/djp5oy3ri/image/upload/v1701954978/beehive_oya/z3stgfbiz4ymqky3z5po.svg"
                                    alt="app store"
                                />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="flex-[0.5]">
                    {phoneSize ? (
                        <img
                            className="phone w-[80%] lg:w-[90%]"
                            src="https://res.cloudinary.com/djp5oy3ri/image/upload/v1701983541/beehive_oya/sgekpbayciwyhqdoya7x.png"
                            alt="phone"
                        />
                    ) : (
                        <img
                            className="phone-small"
                            src="https://res.cloudinary.com/djp5oy3ri/image/upload/v1701983538/beehive_oya/wkuwo9jeosodm2rxcm2d.png"
                            alt="phone"
                        />
                    )}
                </div>
            </div>
            <div className="bg-[#B40303] p-2 flex justify-center items-center text-white translate-y-[-10px] h-[72px]">
                <div className="text-[16px] sm:text-[32px]">
                    Ride with ease, Ride with OYA!
                </div>
            </div>
        </div>
    );
};

export default HeroTwoLarge;
