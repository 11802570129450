import { useState } from "react";
// import { UserCircleIcon } from '@heroicons/react/24/outline';
import CustomButton from "../../../components/CustomBtn";
import PhoneInput, { Value } from "react-phone-number-input";
import SecondStep from "./SecondStep";
import { Spinner } from "@material-tailwind/react";
import { Link } from "react-router-dom";

const FirstStep = () => {
  const [isBusy, setIsBusy] = useState(false);
  const [phoneNum, setPhoneNum] = useState<Value | undefined>("");
  const [step, setStep] = useState(1);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [middleName, setMiddleName] = useState<string | undefined>();
  const [isError, setIsError] = useState(false);
  const [step1Data, setStep1Data] = useState<any>(null);

  //handleSignUp
  const handleSignUp = async () => {
    let formatPhoneNumber = phoneNum?.replace(/\s+/g, "");
    if (!fName || !lName || !phoneNum) {
      setIsError(true);
    } else {
      setIsError(false);
      setIsBusy(true);
      let firstData = {
        first_name: fName,
        last_name: lName,
        middle_name: middleName && middleName,
        phone: formatPhoneNumber,
        country_code: "gh",
      };
      setTimeout(() => {
        setStep(2);
        setStep1Data(firstData);
        setIsBusy(false);
      }, 1000);
    }
  };

  return (
    <>
      {step === 1 ? (
        <div>
          <div className="w-full mt-5">
            <div className="header-text-3">Sign Up</div>
            <div className="body-text-2 pt-1">Create an account with us.</div>
          </div>
          <div className="flex flex-col mt-8 relative">
            <small className={`${isError ? "text-red-500" : ""} mb-2 ml-1`}>
              Your first name
            </small>
            <input
              className="input"
              value={fName}
              onChange={(e) => setFName(e.target.value)}
              placeholder="Enter your first name"
              data-testid="firstNameInput"
            />
            {/* <UserCircleIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
          </div>
          <div className="flex flex-col mt-4 relative">
            <small className={`mb-2 ml-1`}>Your middle name (optional)</small>
            <input
              className="input"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
              placeholder="Enter your middle name"
              data-testid="middleNameInput"
            />
            {/* <UserCircleIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
          </div>
          <div className="flex flex-col mt-4 relative">
            <small className={`${isError ? "text-red-500" : ""} mb-2 ml-1`}>
              Your last name
            </small>
            <input
              className="input"
              value={lName}
              onChange={(e) => setLName(e.target.value)}
              placeholder="Enter your last name"
              data-testid="lasttNameInput"
            />
            {/* <UserCircleIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
          </div>
          <div className="flex flex-col mt-4 relative">
            <small className={`${isError ? "text-red-500" : ""} mb-2 ml-1`}>
              Your mobile number
            </small>
            <PhoneInput
              className="input"
              defaultCountry="GH"
              inputStyle={{ width: "100%" }}
              value={phoneNum}
              placeholder="Enter your mobile number"
              onChange={(phone) => setPhoneNum(phone)}
              data-testid="phoneInput"
              numberInputProps={{
                className: "focus:outline-none",
              }}
            />
          </div>

          <div className="mt-[30px] flex justify-center items-center">
            <CustomButton
              width={"w-full"}
              disabled={isBusy}
              onClick={() => handleSignUp()}
              data-testid="submit-basic-info-mobile"
            >
              {isBusy ? (
                <div className="w-full flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <span>Continue</span>
              )}
            </CustomButton>
          </div>
          <div className="mt-5 flex items-center gap-3">
            <p>Already have an account?</p>
            <Link className="btn-subtle" to="/signin">
              Sign In
            </Link>
          </div>
        </div>
      ) : (
        <>
          <SecondStep
            dataFromStep1={step1Data}
            goBack={() => {
              setStep(1);
            }}
          />
        </>
      )}
    </>
  );
};

export default FirstStep;
