import React from "react";
import { Input } from "antd";
import { debounce } from "lodash";

const TripCode = ({
  handleTripCodeChange,
}: {
  handleTripCodeChange: (value: string) => void;
}) => {
  const handleChange = debounce((value) => {
    handleTripCodeChange(value);
  }, 1500);

  return (
    <div className="flex w-full justify-center items-center mt-[2rem] sm:mb-0 mb-[4rem]">
      <div className="sm:flex-[0.5] flex-[1] trip-code-search mx-3">
        <Input
          onChange={(e) => handleChange(e.target.value)}
          prefix={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M14.375 27.1875C7.3125 27.1875 1.5625 21.4375 1.5625 14.375C1.5625 7.3125 7.3125 1.5625 14.375 1.5625C21.4375 1.5625 27.1875 7.3125 27.1875 14.375C27.1875 21.4375 21.4375 27.1875 14.375 27.1875ZM14.375 3.4375C8.3375 3.4375 3.4375 8.35 3.4375 14.375C3.4375 20.4 8.3375 25.3125 14.375 25.3125C20.4125 25.3125 25.3125 20.4 25.3125 14.375C25.3125 8.35 20.4125 3.4375 14.375 3.4375Z"
                fill="#FF5A55"
              />
              <path
                d="M27.5 28.4375C27.2625 28.4375 27.025 28.35 26.8375 28.1625L24.3375 25.6625C23.975 25.3 23.975 24.7 24.3375 24.3375C24.7 23.975 25.3001 23.975 25.6626 24.3375L28.1626 26.8375C28.5251 27.2 28.5251 27.8 28.1626 28.1625C27.9751 28.35 27.7375 28.4375 27.5 28.4375Z"
                fill="#FF5A55"
              />
            </svg>
          }
          placeholder="Enter trip code"
          bordered={false}
          className="w-full"
        />
      </div>
    </div>
  );
};

export default TripCode;
