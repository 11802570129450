import React from 'react';
import { useMediaQuery } from '@mui/material';
// import { ControlBar, Player } from 'video-react';
import ReactPlayer from 'react-player';

const Step = () => {
  const isLarge = useMediaQuery('(min-width:680px)');
  return (
    <div className='flex flex-col justify-center items-center pt-8 bg-gray-50'>
      <div className='text-[24px] font-bold text-center w-[80%]'>
        How to enroll with the Oya! app
      </div>

      <div>
        {isLarge ? (
          <img
            src='https://res.cloudinary.com/djp5oy3ri/image/upload/v1701954986/beehive_oya/dpuyng0gez4xylsdaxdp.png'
            alt='large'
          />
        ) : (
          <img
            src='https://res.cloudinary.com/djp5oy3ri/image/upload/v1701954985/beehive_oya/xrfdw62djklld0xak360.png'
            alt=''
          />
        )}
      </div>

      <div className='py-3'>
        <div className=' shadow-md '>
          {!isLarge ? (
            <ReactPlayer
              width={200}
              controls
              url='https://res.cloudinary.com/djp5oy3ri/video/upload/v1701972482/beehive_oya/iuxs9otsealyik2y4xe0.mp4'
            />
          ) : (
            <ReactPlayer
              controls
              url='https://res.cloudinary.com/djp5oy3ri/video/upload/v1701972482/beehive_oya/iuxs9otsealyik2y4xe0.mp4'
            />
          )}
        </div>
        <div className='text-center font-semibold py-3'>
          How to enroll with the oya app
        </div>
      </div>
    </div>
  );
};

export default Step;
