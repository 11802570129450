import React, { useEffect, useState } from "react";

type Props = {
  children: any;
};
const CheckScreen = ({ children }: Props) => {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", checkScreenSize);

    checkScreenSize();

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  if (isMobile) {
    return children;
  } else {
    return (
      <div className="flex flex-col min-h-screen w-full justify-center items-center">
        <div className="header-text-3 text-center">Unsupported page</div>
        <div className="text-xl text-center">
          This page is not supported on large screens. Switch to a smaller
          screen.
        </div>
      </div>
    );
  }
};

export default CheckScreen;
