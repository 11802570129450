import React, { useState } from "react";
import { Modal, Spin, Form, notification } from "antd";
import PhoneInput, { Value } from "react-phone-number-input";
import CustomButton from "../CustomBtn";
import { getDaysUntilJwtExpiration } from "../../helpers/utils";
import { RouteConstants } from "../../helpers/constants";
import { auth } from "../../helpers/api";
import { setCookie, setCookieJson } from "../../helpers/utils";
import { useNavigate } from "react-router-dom";
interface User {
    first_name: string;
    last_name: string;
    phone: Value | undefined;
    pin1: string;
    pin2: string;
}

const EmergencyContact = ({
    isOpen,
    handleClosed,
    values,
    closeSignUp,
    schedule,
}: {
    isOpen: boolean;
    handleClosed: () => void;
    values: User;
    closeSignUp: () => void;
    schedule?: number;
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [phone1, setPhone1] = useState<Value | undefined>();
    const [phone2, setPhone2] = useState<Value | undefined>();

    const navigate = useNavigate();

    const submit = async () => {
        setSubmitting(true);
        if (values?.pin1 === values?.pin2) {
            try {
                const jsonData = await auth.post(RouteConstants.REGISTER, {
                    ...values,
                    pin: values?.pin1,
                    ice1_phone: phone1,
                    ice2_phone: phone2,
                    country_code: "GH",
                });

                const daysUntilExpiration = getDaysUntilJwtExpiration(
                    jsonData.data?.payload?.token
                );

                setCookieJson(
                    "usr",
                    jsonData.data?.payload?.user?.id,
                    daysUntilExpiration
                );
                setCookie(
                    "token",
                    jsonData.data?.payload?.token,
                    daysUntilExpiration
                );
                setCookieJson("user_info", jsonData?.data?.payload?.user);

                setCookieJson(
                    "user",
                    {
                        // staffs: jsonData?.data?.payload?.relations,
                        user_roles: jsonData.data?.payload?.user_roles,
                        role_id: jsonData.data?.payload?.user?.type?.id,
                        role: jsonData.data?.payload?.user?.type?.name,
                    },
                    daysUntilExpiration
                );

                setSubmitting(false);
                notification.success({
                    message: "Sign up successful",
                });
                if (schedule) {
                    navigate(`/trip/${schedule}`);
                }
                closeSignUp();
                handleClosed();
                window.location.reload();
            } catch (e: any) {
                notification.error({
                    message: e?.response?.data?.message,
                });
                console.log(e);
                setSubmitting(false);
            }
        }
    };

    return (
        <Modal footer={null} open={isOpen} onCancel={handleClosed}>
            <Spin spinning={submitting}>
                <div>
                    <h4 className="text-lg md:text-[24px] font-bold">
                        Emergency contacts
                    </h4>
                    <div className=" font-medium pb-2">
                        Provide two emergency contact that we call in the event
                        of an emergency
                    </div>
                </div>
                <Form className="pt-2" form={form} layout="vertical">
                    <Form.Item
                        label={
                            <label
                                htmlFor="phone1"
                                className="block capitalize text-sm text-gray-601"
                            >
                                emergency contact 1
                            </label>
                        }
                        className="w-full mb-6 pr-3"
                        rules={[
                            {
                                required: true,
                                message: "This field is required",
                            },
                        ]}
                        colon={false}
                        required={true}
                    >
                        <PhoneInput
                            name="phone1"
                            className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                            data-testid="register-phone-input"
                            defaultCountry="GH"
                            value={phone1}
                            disabled={submitting}
                            onChange={(e) => setPhone1(e)}
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <label
                                htmlFor="phone2"
                                className="block capitalize text-sm text-gray-601"
                            >
                                emergency contact 2
                            </label>
                        }
                        className="w-full mb-6 pr-3"
                        rules={[
                            {
                                required: true,
                                message: "This field is required",
                            },
                        ]}
                        colon={false}
                        required={true}
                    >
                        <PhoneInput
                            name="phone2"
                            className="phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 focus:border-gray-200 focus:outline-none"
                            data-testid="register-phone-input"
                            defaultCountry="GH"
                            value={phone2}
                            disabled={submitting}
                            onChange={(e) => setPhone2(e)}
                        />
                    </Form.Item>
                    <div className="flex gap-2">
                        <CustomButton
                            data-testid="submitEmergencyConytact"
                            type="button"
                            onClick={() => submit()}
                        >
                            Submit
                        </CustomButton>
                        <CustomButton
                            data-testid="cancelEmergencyContactForm"
                            onClick={() => {
                                handleClosed();
                            }}
                            variant="secondary"
                        >
                            Cancel
                        </CustomButton>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
};

export default EmergencyContact;
