import React from 'react';
import oya_logo from '../../assets/img/oya_logo.svg';
import { PhoneIcon } from '@heroicons/react/24/solid';

type Props = {};

const HeaderSmall = (props: Props) => {
  return (
    <nav className='bg-white flex justify-between items-center py-[24px] px-[1rem]'>
      <div>
        <img src={oya_logo} alt='' />
      </div>
      <div>
        <a
          href='tel:+233 27 441 0871'
          className='flex items-center gap-2 hover:text-gray-800'
        >
          <PhoneIcon className='sm:w-5 sm:h-5 w-3 h-3' />
          <div className='font-semibold sm:text-base text-sm'>
            Help Line: +233 27 441 0871
          </div>
        </a>
      </div>
    </nav>
  );
};

export default HeaderSmall;
