import { Fragment, useState } from "react";
import { Spinner } from "@material-tailwind/react";
import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput, { Value } from "react-phone-number-input";
import { RouteConstants } from "../../helpers/constants";
import { useLocation } from "react-router-dom";
import CustomButton from "../../components/CustomBtn";
import { notification } from "antd";
import { parse } from "querystring";
import {
  getDaysUntilJwtExpiration,
  redirect,
  setCookie,
  setCookieJson,
} from "../../helpers/utils";
import { auth } from "../../helpers/api";

const SignIn = () => {
  const navigate = useNavigate();
  const [phoneNum, setPhoneNum] = useState<Value | undefined>("");
  const [thePin, setThePin] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [isPinError, setIsPinError] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [pinLength, setPinLength] = useState<number>(0);

  const location = useLocation();
  const redirect_url = parse(location.search.replace("?", ""));

  //handle login
  const handleLogin = async () => {
    let formatPhoneNumber = phoneNum?.replace(/\s+/g, "");
    if (!phoneNum) {
      setIsPhoneError(true);
    } else if (!thePin) {
      setIsPinError(true);
    } else {
      let data = {
        phone: formatPhoneNumber,
        pin: thePin,
      };
      // console.log(data)
      setIsBusy(true);
      try {
        const jsonData = await auth.post(RouteConstants.LOGIN, {
          ...data,
        });

        const daysUntilExpiration = getDaysUntilJwtExpiration(
          jsonData.data?.payload?.token
        );

        setCookieJson(
          "usr",
          jsonData.data?.payload?.user?.id,
          daysUntilExpiration
        );
        setCookie("token", jsonData.data?.payload?.token, daysUntilExpiration);
        setCookieJson("user_info", jsonData?.data?.payload?.user);

        setCookieJson(
          "user",
          {
            // staffs: jsonData?.data?.payload?.relations,
            user_roles: jsonData.data?.payload?.user_roles,
            role_id: jsonData.data?.payload?.user?.type?.id,
            role: jsonData.data?.payload?.user?.type?.name,
          },
          daysUntilExpiration
        );
        setIsBusy(false);
        notification.success({
          message: "Login successful",
        });
        localStorage.setItem("isOnboard", "ONBOARD");
        if (redirect_url?.appref) {
          redirect(redirect_url?.appref);
        } else {
          window.location.replace("/");
        }
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.message,
        });

        setIsBusy(false);
      }
    }
  };

  return (
    <Fragment>
      <div className="h-screen bg-[#fff] overflow-hidden flex flex-col">
        <div className="w-full px-[30px] mt-[40%]">
          <div className="header-text-3">Sign In</div>
          <div className="body-text-2 pt-1">Welcome back!</div>
          <div>
            <div className="flex flex-col mt-8 relative">
              <small
                className={`${
                  isPhoneError ? "text-red-500" : ""
                } mb-2 ml-1 text-oya-gray font-medium`}
              >
                Your mobile number
              </small>
              <PhoneInput
                className="input"
                defaultCountry="GH"
                placeholder="Enter your phone number"
                inputStyle={{ width: "100%" }}
                value={phoneNum}
                onChange={(phone) => setPhoneNum(phone)}
                disabled={isBusy}
                numberInputProps={{
                  className: "focus:outline-none",
                }}
                data-testid="phoneInput"
              />
            </div>
            <div className="flex flex-col mt-4 relative">
              <small
                className={`${
                  isPinError ? "text-red-500" : ""
                } mb-2 ml-1 text-oya-gray font-medium`}
              >
                Your pin
              </small>
              <input
                className="input"
                placeholder="Enter your pin"
                type={showPin ? "text" : "password"}
                maxLength={4}
                value={thePin}
                data-testid="pinInput"
                onChange={(e) => {
                  let value = e.target.value;
                  const trimmedValue = value.trim();
                  const numberOfCharacters = trimmedValue.length;
                  setPinLength(numberOfCharacters);

                  setThePin(e.target.value);
                }}
              />

              <div className="absolute right-2 bottom-[10px]">
                {showPin ? (
                  <button onClick={() => setShowPin(!showPin)}>
                    <EyeIcon className="w-6 h-6 text-black" />
                  </button>
                ) : (
                  <button onClick={() => setShowPin(!showPin)}>
                    <EyeSlashIcon className="w-6 h-6 text-black" />
                  </button>
                )}
              </div>
            </div>

            <div className="flex w-full justify-end items-end mx-1 mt-1">
              <div className="text-oya-gray">{pinLength}/4</div>
            </div>

            <div className="mt-[30px] flex justify-center items-center">
              <CustomButton
                width="w-full"
                onClick={() => handleLogin()}
                disabled={isBusy}
                data-testid="loginBtn-mobile"
              >
                {isBusy ? (
                  <div className="w-full flex justify-center items-center">
                    <Spinner />
                  </div>
                ) : (
                  <span>Sign In</span>
                )}
              </CustomButton>
            </div>
          </div>

          <div className="flex items-center justify-between mx-1 mt-9">
            <button
              className="btn-subtle"
              onClick={() => navigate("/forgot-pin")}
              data-testid="setPinBtn"
            >
              Forgot PIN?
            </button>
          </div>

          <div className="mt-5 flex items-center gap-3">
            <p className="text-oya-gray">Don't have an account?</p>
            <Link className="btn-subtle " to="/signup">
              Create one
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignIn;
