import React, { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: "primary" | "secondary";
    width?: string;
}

const CustomButton: React.FC<ButtonProps> = ({
    variant = "primary",
    width,
    ...props
}) => {
    const solid = `${width} hover:transition hover:ease-in-out hover:delay-150 px-5 py-3 text-white rounded-md bg-oya-red-100 hover:bg-oya-red-50 items-center justify-center focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2`;
    const outline = `${width} hover:transition hover:ease-in-out hover:delay-150 px-5 py-3 font-bold border border-oya-red-100 text-oya-red-100 rounded-md hover:bg-oya-red-100 hover:text-white items-center justify-center`;
    return (
        <button
            type="button"
            className={variant === "primary" ? solid : outline}
            {...props}
        />
    );
};

export default CustomButton;
