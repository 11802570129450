import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { KostI18nProvider } from 'kost-i18n';
import ENGLISH_TRANSLATIONS from './internationalisation/locales/default.en.json';
import FRENCH_TRANSLATIONS from './internationalisation/locales/default.fr.json';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistGate } from 'redux-persist/integration/react';
import Loader from './components/Loader';
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        persistor={persistor}
        loading={<Loader float height='h-5' width='h-5' />}
      >
        <QueryClientProvider client={queryClient}>
          <KostI18nProvider
            languages={[
              {
                key: 'en',
                name: 'English',
                translations: ENGLISH_TRANSLATIONS,
              },
              { key: 'fr', name: 'French', translations: FRENCH_TRANSLATIONS },
            ]}
          >
            <App />
          </KostI18nProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
