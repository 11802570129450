import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomBtn";
import { Spinner } from "@material-tailwind/react";
import { RouteConstants } from "../../helpers/constants";
import { notification } from "antd";
import PhoneInput, { Value } from "react-phone-number-input";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import PinInput from "react-pin-input";
import { auth } from "../../helpers/api";

type Props = {};

const ForgotPin = (props: Props) => {
  const [step, setStep] = useState<number>(1);
  const [isError, setIsError] = useState(false);
  const [phone, setPhone] = useState<Value | undefined>();
  const [isBusy, setIsBusy] = useState(false);
  const navigate = useNavigate();
  const [code, setCode] = useState<number | any>();
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [wrongPin, setWrongPins] = useState(false);

  const submitResetPinRequest = async () => {
    try {
      setIsBusy(true);
      if (!phone) {
        setIsError(true);
      } else {
        //fire up reset code
        await auth.post(RouteConstants.REQUEST_RESET_PIN_OTP, {
          phone,
        });
        setStep(2);
        //ShowToast.success_toast("Verification code sent");
        notification.success({
          message: "Verification code sent",
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsBusy(false);
    }
  };

  //handleOnPinComplete
  const handleOnPinComplete = () => {
    // console.log(value)
    setIsBusy(true);
    if (code) {
      setTimeout(() => {
        setStep(3);
        setIsBusy(false);
      }, 1000);
    } else {
      //ShowToast.error_toast("Verification required");
      notification.error({
        message: "Verification required",
      });
      setIsBusy(false);
    }
  };

  //handleResend
  const handleResend = async () => {
    setIsBusy(true);
    try {
      await auth.post(RouteConstants.REQUEST_RESET_PIN_OTP, {
        phone,
      });
      notification.success({
        message: "Verification code sent",
      });

      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    }
  };

  const handlePinReset = async () => {
    try {
      setIsBusy(true);
      if (pin !== confirmPin) {
        setWrongPins(true);
      } else {
        await auth.post(RouteConstants.RESET_PIN, {
          pin,
          code,
          phone,
        });
        //ShowToast.success_toast("Pin reset successful");
        notification.success({
          message: "Pin reset successful",
        });
        navigate(-1);
      }
    } catch (e: any) {
      //ShowToast.error_toast(e?.response?.data?.message);
      notification.error({
        message: e?.response?.data?.message || e?.message,
      });
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <Fragment>
      <div className="h-screen bg-white overflow-hidden flex flex-col">
        <div className="w-full px-[20px] mt-[20%]">
          {step === 1 && (
            <div>
              <button onClick={() => navigate(-1)}>
                <ChevronLeftIcon className="w-6 h-6" />
              </button>
              <div className="header-text-3 mt-8">Forgot pin</div>
              <div className="body-text-2 pt-1">
                Enter your phone number linked to your account.
              </div>
              <div className="flex flex-col mt-8 relative">
                <small className={`${isError ? "text-red-500" : ""} mb-2 ml-1`}>
                  Your mobile number
                </small>
                <PhoneInput
                  className="input"
                  defaultCountry="GH"
                  inputStyle={{ width: "100%" }}
                  placeholder="Enter your mobile number"
                  value={phone}
                  onChange={(value) => setPhone(value)}
                  limitMaxLength
                  numberInputProps={{
                    className: "focus:outline-none",
                  }}
                />
              </div>
              <div className="mt-[50px] flex justify-center items-center">
                <CustomButton
                  width="w-full"
                  onClick={() => {
                    submitResetPinRequest();
                  }}
                  data-testid="request-otp-button-mobile"
                >
                  {isBusy ? (
                    <div className="w-full flex justify-center items-center">
                      <Spinner />
                    </div>
                  ) : (
                    <span>Continue</span>
                  )}
                </CustomButton>
              </div>
            </div>
          )}

          {step === 2 && (
            <div>
              <button
                onClick={() => {
                  setStep(1);
                }}
              >
                <ChevronLeftIcon className="w-6 h-6" />
              </button>
              <div className="w-full mt-5">
                <div className="header-text-3">Verify number</div>
                <div className="body-text-2 pt-1">
                  Enter the 6-digit code sent to {phone}
                </div>
              </div>

              <div className="mt-[4rem]">
                <PinInput
                  length={6}
                  initialValue=""
                  secret
                  secretDelay={100}
                  type="custom"
                  inputMode="number"
                  inputStyle={{
                    borderColor: "#939291",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                  inputFocusStyle={{ borderColor: "#FF6200" }}
                  onComplete={(value, index) => {
                    setCode(value);
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </div>
              <div className="mt-5">
                {isBusy ? (
                  <Spinner className="h-8 w-8" />
                ) : (
                  <>
                    <div>
                      <p className="flex items-center gap-2">
                        Didn’t receive code?{" "}
                        <span
                          className="btn-subtle"
                          onClick={() => handleResend()}
                        >
                          Resend.
                        </span>
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="mt-[100px] flex justify-center items-center">
                <CustomButton
                  width="w-full"
                  disabled={isBusy}
                  onClick={() => {
                    handleOnPinComplete();
                  }}
                  data-testid="submit-otp-mobile"
                >
                  {isBusy ? (
                    <div className="w-full flex justify-center items-center">
                      <Spinner />
                    </div>
                  ) : (
                    <span>Continue</span>
                  )}
                </CustomButton>
              </div>
            </div>
          )}

          {step === 3 && (
            <div>
              <button
                onClick={() => {
                  setStep(2);
                }}
              >
                <ChevronLeftIcon className="w-6 h-6" />
              </button>
              <div className="w-full mt-5">
                <div className="header-text-3">Reset Pin</div>
                <div className="body-text-2 pt-1">
                  Create a new pin to secure your account.
                </div>
              </div>

              <div className="flex flex-col mt-8 relative">
                <small className={`${isError ? "text-red-500" : ""} mb-2`}>
                  Your new pin
                </small>
                <input
                  className="input"
                  type="password"
                  value={pin}
                  maxLength={4}
                  onChange={(e) => setPin(e.target.value)}
                  placeholder="Enter your new pin"
                />
                {/* <LockClosedIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
              </div>

              <div className="flex flex-col mt-8 relative">
                <small className={`${isError ? "text-red-500" : ""} mb-2`}>
                  Confirm your pin
                </small>
                <input
                  className="input"
                  type="password"
                  value={confirmPin}
                  maxLength={4}
                  onChange={(e) => setConfirmPin(e.target.value)}
                  placeholder="Confirm your new pin"
                />
                {/* <LockClosedIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
              </div>
              {wrongPin && (
                <div className="text-red-500 mt-2">Pin mismatch!</div>
              )}

              <div className="mt-[100px] flex justify-center items-center">
                <CustomButton
                  width="w-full"
                  disabled={isBusy}
                  onClick={() => {
                    handlePinReset();
                  }}
                  data-testid="submit-new-pin-mobile"
                >
                  {isBusy ? (
                    <div className="w-full flex justify-center items-center">
                      <Spinner />
                    </div>
                  ) : (
                    <span>Reset pin</span>
                  )}
                </CustomButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPin;
