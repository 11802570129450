import React, { useState } from "react";
import CustomButton from "../../components/CustomBtn";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Spinner } from "@material-tailwind/react";
import { getCookie } from "../../helpers/utils";
import { auth } from "../../helpers/api";
import { notification } from "antd";
import { RouteConstants } from "../../helpers/constants";

type Props = {};

const VerifyPhoneMobile = (props: Props) => {
  const navigate = useNavigate();
  const data: any = getCookie("oya_user_info");
  const theUserData = JSON.parse(data);
  const [isBusy, setIsBusy] = useState(false);

  const [code, setCode] = useState<number | any>();

  //handleResend
  const handleResend = async () => {
    let data = {
      phone: theUserData?.phone,
    };
    setIsBusy(true);
    try {
      await auth.post(RouteConstants.REQUEST_RESET_PIN_OTP, {
        phone: data.phone,
      });
      notification.success({
        message: "Verification code sent",
      });

      setIsBusy(false);
    } catch (error: any) {
      setIsBusy(false);
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    }
  };

  //handleOnPinComplete
  const handleOnPinComplete = async () => {
    // console.log(value)
    if (code) {
      let data = {
        phone: theUserData?.phone,
        code,
      };
      // console.log(data)
      setIsBusy(true);

      try {
        await auth.post(RouteConstants.VERIFY_OTP, {
          ...data,
        });
        notification.success({
          message: "Phone verification successful",
        });
        //localStorage.setItem("isOnboard", "ONBOARD");
        navigate("/");
        window.location.reload();

        setIsBusy(false);
      } catch (error: any) {
        setIsBusy(false);
        notification.error({
          message: error?.response?.data?.message || error?.message,
        });
      }
    }
  };

  return (
    <div>
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <ChevronLeftIcon className="w-6 h-6" />
      </button>
      <div className="w-full mt-5">
        <div className="header-text-3">Verify number</div>
        <div className="body-text-2 pt-1">
          Enter the 6-digit code sent to {theUserData?.phone}
        </div>
      </div>

      <div className="mt-[4rem]">
        <PinInput
          length={6}
          initialValue=""
          secret
          secretDelay={100}
          type="custom"
          inputMode="number"
          inputStyle={{
            borderColor: "#939291",
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
          inputFocusStyle={{ borderColor: "#FF6200" }}
          onComplete={(value, index) => {
            setCode(value);
          }}
          data-testid="otpInput"
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </div>
      <div className="mt-5">
        {isBusy ? (
          <Spinner color="green" className="h-8 w-8" />
        ) : (
          <>
            <div>
              <p className="flex items-center gap-2">
                Didn’t receive code?{" "}
                <span
                  className="btn-subtle"
                  onClick={() => handleResend()}
                  data-testid="resendOtpBtn"
                >
                  Resend.
                </span>
              </p>
            </div>
          </>
        )}
      </div>
      <div className="mt-[100px] flex justify-center items-center">
        <CustomButton
          width="w-full"
          disabled={isBusy}
          onClick={() => {
            handleOnPinComplete();
          }}
          data-testid="verifyPhoneMobileBtn"
        >
          {isBusy ? (
            <div className="w-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            <span>Verify phone</span>
          )}
        </CustomButton>
      </div>
    </div>
  );
};

export default VerifyPhoneMobile;
