import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import ReactGA from 'react-ga';
import Home from './pages/Home';
import { Helmet } from 'react-helmet';
//styles
import 'react-phone-number-input/style.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles/styles.scss';
import BookTrip from './pages/BookTrip';
import GuestBooking from './pages/GuestBooking';
import PageNotFound from './pages/PageNotFound';
import TripCodeBooking from './pages/TripCodeBooking';
import BeehiveLanding from './pages/BeehiveLanding';
// import AdvanceTicketing from './components/Schedules/AdvanceTicketing';
import AdvanceTicketPay from './components/Schedules/AdvanceTicketPay';
import { remoteConfig } from './helpers/firebase';
import { fetchAndActivate } from 'firebase/remote-config';
import Activites from './mobile/activities/Activites';
import Parcel from './mobile/parcel/Parcel';
import Settings from './mobile/settings/Settings';
import CheckScreen from './mobile/components/CheckScreen';
import MobileHome from './mobile/home/MobileHome';
import SignIn from './mobile/auth/SignIn';
import SignUp from './mobile/auth/SignUp';
import Search from './mobile/home/Search';
import ForgotPin from './mobile/auth/ForgotPin';
import FindTrip from './mobile/home/FindTrip';
import VerifyPhone from './pages/VerifyPhone';
import TripSummary from './mobile/home/TripSummary';
import PaymentDetail from './mobile/home/PaymentDetail';
import AppInfo from './mobile/settings/AppInfo';
import Personal from './mobile/settings/Personal';
import AdvanceTicketing from './components/Schedules/AdvanceTicketing';
import { NODE_ENV } from './helpers/constants';
import HshsPayment from './components/Hshs/HshsPayment';
import TicketReceipt from './pages/TicketReceipt';
import HshsBooking from './components/Schedules/HshsBooking';
import HshsScheduleDetail from './mobile/hshs/HshsScheduleDetail';

function App() {
  const googleTrackingID = 'G-F3KVXLHDN9';
  ReactGA.initialize(googleTrackingID);

  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

  remoteConfig.defaultConfig = {
    shouldShowAdBannerOnWeb: false,
  };

  const fetchAndActiveFunc = async () => {
    try {
      await fetchAndActivate(remoteConfig);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAndActiveFunc();
  }, []);

  return (
    <ConfigProvider>
      <Helmet>
        <meta charSet='utf-8' />
        {NODE_ENV === 'production' && <meta name='robots' content='all' />}
        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-F3KVXLHDN9'
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){
              dataLayer.push(arguments)
            }

            gtag('js', new Date()); gtag('config', 'G-F3KVXLHDN9');
          `}
        </script>
      </Helmet>
      <Router>
        <Routes>
          <Route path='/*' element={<Home />} />
          <Route path='/advance-ticket' element={<AdvanceTicketing />} />
          <Route
            path='/advance-ticket/pay/:id'
            element={<AdvanceTicketPay />}
          />
          <Route path='/hshs/pay/:id' element={<HshsPayment />} />
          <Route path='/trip/:id' element={<BookTrip />} />
          <Route path='/guest/:id' element={<GuestBooking />} />
          <Route path='/custom_trips/:id' element={<TripCodeBooking />} />
          <Route path='/branches/:id/trips' element={<BeehiveLanding />} />
          <Route path='/verify-phone' element={<VerifyPhone />} />
          <Route path='/ticket' element={<TicketReceipt />} />
          <Route
            path='/mobile-settings'
            element={
              <CheckScreen>
                <Settings />
              </CheckScreen>
            }
          />
          <Route
            path='/trip-search'
            element={
              <CheckScreen>
                <Search />
              </CheckScreen>
            }
          />
          <Route
            path='/mb-hshs'
            element={
              <CheckScreen>
                <HshsBooking />
              </CheckScreen>
            }
          />
          <Route
            path='/mb-hshs/:id'
            element={
              <CheckScreen>
                <HshsScheduleDetail />
              </CheckScreen>
            }
          />

          <Route
            path='/find-trip'
            element={
              <CheckScreen>
                <FindTrip />
              </CheckScreen>
            }
          />
          <Route
            path='/signin'
            element={
              <CheckScreen>
                <SignIn />
              </CheckScreen>
            }
          />
          <Route
            path='/signup'
            element={
              <CheckScreen>
                <SignUp />
              </CheckScreen>
            }
          />
          <Route
            path='/forgot-pin'
            element={
              <CheckScreen>
                <ForgotPin />
              </CheckScreen>
            }
          />
          <Route
            path='/parcel'
            element={
              <CheckScreen>
                <Parcel />
              </CheckScreen>
            }
          />
          <Route
            path='/activities'
            element={
              <CheckScreen>
                <Activites />
              </CheckScreen>
            }
          />
          <Route
            path='/guest-user'
            element={
              <CheckScreen>
                <MobileHome />
              </CheckScreen>
            }
          />
          <Route
            path='/trips/:id/summary'
            element={
              <CheckScreen>
                <TripSummary />
              </CheckScreen>
            }
          />

          <Route
            path='/app-info'
            element={
              <CheckScreen>
                <AppInfo />
              </CheckScreen>
            }
          />

          <Route
            path='/personal'
            element={
              <CheckScreen>
                <Personal />
              </CheckScreen>
            }
          />

          <Route
            path='/trips/:id/payment'
            element={
              <CheckScreen>
                <PaymentDetail />
              </CheckScreen>
            }
          />
          {/* <Route
            path='/advance-ticket/receipt/:id'
            element={<AdvanceTicketReceipt />}
          /> */}
          <Route
            path='/companies/:id/trips'
            element={<BeehiveLanding type='companies' />}
          />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
