import { Modal } from "antd";

type Props = {
  visible: boolean;
  handleClose: () => void;
  handleRemove: () => void;
};

const RemoveSeatModal = ({
  visible,
  handleClose,

  handleRemove,
}: Props) => {
  return (
    <Modal
      closable={true}
      open={visible}
      onCancel={() => handleClose()}
      footer={null}
    >
      <div className="rounded-xl bg-white ">
        <div className="header-text-3">Remove seat</div>
        <div>
          <div>Removing seat will clear the seat and all of its data.</div>

          <div className="w-full justify-end items-end flex-row flex mt-10">
            <div className="flex gap-5 items-center">
              <button
                onClick={() => {
                  handleClose();
                }}
              >
                <div className="font-medium text-oya-gray">Cancel</div>
              </button>
              <button
                onClick={() => {
                  handleRemove();
                  handleClose();
                }}
              >
                <div className="font-medium text-oya-red-50">Remove seat</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveSeatModal;
