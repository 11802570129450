import React, { Fragment } from "react";
import FirstStep from "./SignUpPages/FirstPage";

const SignUp = () => {
  return (
    <Fragment>
      <div className="h-screen bg-white overflow-hidden flex flex-col">
        <div className="w-full px-[20px] mt-[20%]">
          <FirstStep />
        </div>
      </div>
    </Fragment>
  );
};

export default SignUp;
