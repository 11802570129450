import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyBTAb8eQWfxuVo8yuB5CcV-Za5ndBZCvVs",
  authDomain: "oya-402421.firebaseapp.com",
  projectId: "oya-402421",
  storageBucket: "oya-402421.appspot.com",
  messagingSenderId: "724420927803",
  appId: "1:724420927803:web:c60af1adbb1a08f906571e",
  measurementId: "G-N1X72BPRW6",
};

const app = initializeApp(firebaseConfig);

export const remoteConfig = getRemoteConfig(app);
