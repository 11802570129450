import React from "react";
import processing from "../../assets/img/pay_processing.gif";

const StillProcessing = ({ handleDismiss }: { handleDismiss: () => void }) => {
  return (
    <div className='bg-white min-h-screen'>
      <div className='header-text-3 text-center mt-7'>Payment processing</div>

      <div className='min-h-full flex flex-col justify-center items-center mx-4 my-auto py-auto'>
        <div className='w-full flex justify-center items-center'>
          <img
            alt='processing'
            src={processing}
            className='mt-[8rem] w-[40%] h-auto'
          />
        </div>
        <div className=''>
          <div className='mx-auto mt-3 text-center'>
            Payment is still processing. You will be notified once transaction
            is successful.
          </div>
          <div className='text-center mt-4'>
            Call <span className='text-oya-red-50'>03033456543</span> for
            support.
          </div>

          <button
            onClick={() => {
              handleDismiss();
            }}
            className='bg-oya-red-100 mt-6 rounded-lg w-full h-[42px] flex justify-center items-center text-white'
          >
            <div className='text-[16px]'>Dismiss</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StillProcessing;
