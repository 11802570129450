import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { api } from "../../helpers/api";
import { RouteConstants } from "../../helpers/constants";
import { useMediaQuery } from "@mui/material";
import { debounce } from "lodash";
import { Option } from "../../types";

const SearchFilter = ({
    handleSearch,
}: {
    handleSearch: (
        pickupId: number | undefined,
        destinationId: number | undefined
    ) => void;
}) => {
    const isLarge = useMediaQuery("(min-width:1024px)");

    const [pickup, setPickup] = useState();
    const [destination, setDestination] = useState();

    const loadFroms = debounce(
        async (inputValue: string, callback: (options: Option[]) => void) => {
            let filterData = [
                {
                    f: "from.name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            try {
                const { data } = await api.get(
                    `${RouteConstants.ROUTES}?filters=${JSON.stringify(
                        filterData
                    )}`
                );

                const uniqueFromIds = new Set();
                const filteredFromData = data?.payload?.items.filter(
                    (item: any) => {
                        if (!uniqueFromIds.has(item?.from?.id)) {
                            uniqueFromIds.add(item?.from?.id);
                            return true;
                        }
                        return false;
                    }
                );

                const searchOptions = filteredFromData?.map((item: any) => ({
                    label: item?.from?.name,
                    value: item?.from?.id,
                }));

                callback(searchOptions);

                //console.log(uniqueData);
            } catch (e) {
                console.log(e);
                //setLoadingCompanies(false);
            }
        },
        2000
    );

    const loadTos = debounce(
        async (inputValue: string, callback: (options: Option[]) => void) => {
            let filterData = [
                {
                    f: "to.name",
                    o: "contains",
                    p: [`${inputValue}`],
                },
            ];

            try {
                const { data } = await api.get(
                    `${RouteConstants.ROUTES}?filters=${JSON.stringify(
                        filterData
                    )}`
                );

                const uniqueFromIds = new Set();
                const filteredFromData = data?.payload?.items.filter(
                    (item: any) => {
                        if (!uniqueFromIds.has(item?.to?.id)) {
                            uniqueFromIds.add(item?.to?.id);
                            return true;
                        }
                        return false;
                    }
                );

                const searchOptions = filteredFromData?.map((item: any) => ({
                    label: item?.to?.name,
                    value: item?.to?.id,
                }));

                callback(searchOptions);

                //console.log(uniqueData);
            } catch (e) {
                console.log(e);
                //setLoadingCompanies(false);
            }
        },
        2000
    );

    return (
        <div className="sm:px-[8rem] px-[1rem] flex flex-col justify-center items-center lg:block w-full mt-[2rem] ">
            <div className="w-full lg:flex justify-center items-center">
                {isLarge ? (
                    <div className="search-input sm:flex justify-center lg:flex-[0.8] sm:mb-2">
                        <div className="flex-[0.45] h-full flex items-center ">
                            <div className="ml-3">
                                <div className="w-[24px] h-[24px] rounded-full border-[1.5px] border-oya-gray-200 flex items-center justify-center">
                                    <div className="w-[12px] h-[12px] bg-oya-gray rounded-full" />
                                </div>
                            </div>
                            <AsyncSelect
                                //defaultOptions={allPickups}
                                placeholder="Traveling from"
                                loadOptions={(inputValue, callback) => {
                                    loadFroms(inputValue, callback);
                                }}
                                onChange={(e: any) => {
                                    setPickup(e?.value);
                                    if (!e) {
                                        handleSearch(undefined, destination);
                                    }
                                }}
                                styles={{
                                    container: (base, props) => ({
                                        ...base,
                                        flex: "1",
                                    }),
                                    control(base, props) {
                                        return {
                                            ":active": {
                                                borderColor: "#ccc",
                                            },
                                            display: "flex",
                                            borderWidth: 0,
                                        };
                                    },
                                }}
                                isClearable
                            />
                        </div>
                        <div className="height-[40px] border-r border-gray-200" />
                        <div className="flex-[0.45] flex items-center">
                            <div className="ml-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M11.9999 14.1699C9.86988 14.1699 8.12988 12.4399 8.12988 10.2999C8.12988 8.15994 9.86988 6.43994 11.9999 6.43994C14.1299 6.43994 15.8699 8.16994 15.8699 10.3099C15.8699 12.4499 14.1299 14.1699 11.9999 14.1699ZM11.9999 7.93994C10.6999 7.93994 9.62988 8.99994 9.62988 10.3099C9.62988 11.6199 10.6899 12.6799 11.9999 12.6799C13.3099 12.6799 14.3699 11.6199 14.3699 10.3099C14.3699 8.99994 13.2999 7.93994 11.9999 7.93994Z"
                                        fill="#FF5A55"
                                    />
                                    <path
                                        d="M12.0002 22.76C10.5202 22.76 9.03018 22.2 7.87018 21.09C4.92018 18.25 1.66018 13.72 2.89018 8.33C4.00018 3.44 8.27018 1.25 12.0002 1.25C12.0002 1.25 12.0002 1.25 12.0102 1.25C15.7402 1.25 20.0102 3.44 21.1202 8.34C22.3402 13.73 19.0802 18.25 16.1302 21.09C14.9702 22.2 13.4802 22.76 12.0002 22.76ZM12.0002 2.75C9.09018 2.75 5.35018 4.3 4.36018 8.66C3.28018 13.37 6.24018 17.43 8.92018 20C10.6502 21.67 13.3602 21.67 15.0902 20C17.7602 17.43 20.7202 13.37 19.6602 8.66C18.6602 4.3 14.9102 2.75 12.0002 2.75Z"
                                        fill="#FF5A55"
                                    />
                                </svg>
                            </div>
                            <AsyncSelect
                                placeholder="Traveling to"
                                loadOptions={(inputValue, callback) => {
                                    loadTos(inputValue, callback);
                                }}
                                styles={{
                                    container: (base, props) => ({
                                        ...base,
                                        flex: "1",
                                    }),
                                    control(base, props) {
                                        return {
                                            ":active": {
                                                borderColor: "#ccc",
                                            },
                                            display: "flex",
                                            borderWidth: 0,
                                        };
                                    },
                                }}
                                onChange={(e: any) => {
                                    setDestination(e?.value);
                                    if (!e) {
                                        handleSearch(pickup, undefined);
                                    }
                                }}
                                isClearable
                            />
                        </div>

                        <button
                            data-testid="tripSearchLg"
                            onClick={() => {
                                handleSearch(pickup, destination);
                            }}
                            className="flex-[0.1] bg-white px-2 py-2  rounded-r-[10px] text-[20px]"
                        >
                            <div className="text-white bg-oya-red-100 py-1 rounded-lg">
                                Search
                            </div>
                        </button>
                    </div>
                ) : (
                    <div className="w-full mb-10 bg-white p-3 rounded-xl">
                        <div className="border p-1 rounded-xl flex justify-center items-center w-full mb-2 flex-[1]">
                            <div className="ml-1">
                                <div className="w-[24px] h-[24px] rounded-full border-[1.5px] border-oya-gray-200 flex items-center justify-center">
                                    <div className="w-[12px] h-[12px] bg-oya-gray rounded-full" />
                                </div>
                            </div>
                            <AsyncSelect
                                //defaultOptions={allPickups}
                                placeholder="From"
                                loadOptions={(inputValue, callback) => {
                                    loadFroms(inputValue, callback);
                                }}
                                onChange={(e: any) => {
                                    setPickup(e?.value);
                                    if (!e) {
                                        handleSearch(undefined, destination);
                                    }
                                }}
                                styles={{
                                    container: (base, props) => ({
                                        ...base,
                                        flex: "1",
                                    }),
                                    control(base, props) {
                                        return {
                                            ":active": {
                                                borderColor: "#ccc",
                                            },
                                            display: "flex",
                                        };
                                    },
                                }}
                                isClearable
                            />
                        </div>
                        <div className="border p-1 rounded-xl flex justify-center items-center w-full mb-2 flex-[1]">
                            <div className="ml-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M11.9999 14.1699C9.86988 14.1699 8.12988 12.4399 8.12988 10.2999C8.12988 8.15994 9.86988 6.43994 11.9999 6.43994C14.1299 6.43994 15.8699 8.16994 15.8699 10.3099C15.8699 12.4499 14.1299 14.1699 11.9999 14.1699ZM11.9999 7.93994C10.6999 7.93994 9.62988 8.99994 9.62988 10.3099C9.62988 11.6199 10.6899 12.6799 11.9999 12.6799C13.3099 12.6799 14.3699 11.6199 14.3699 10.3099C14.3699 8.99994 13.2999 7.93994 11.9999 7.93994Z"
                                        fill="#FF5A55"
                                    />
                                    <path
                                        d="M12.0002 22.76C10.5202 22.76 9.03018 22.2 7.87018 21.09C4.92018 18.25 1.66018 13.72 2.89018 8.33C4.00018 3.44 8.27018 1.25 12.0002 1.25C12.0002 1.25 12.0002 1.25 12.0102 1.25C15.7402 1.25 20.0102 3.44 21.1202 8.34C22.3402 13.73 19.0802 18.25 16.1302 21.09C14.9702 22.2 13.4802 22.76 12.0002 22.76ZM12.0002 2.75C9.09018 2.75 5.35018 4.3 4.36018 8.66C3.28018 13.37 6.24018 17.43 8.92018 20C10.6502 21.67 13.3602 21.67 15.0902 20C17.7602 17.43 20.7202 13.37 19.6602 8.66C18.6602 4.3 14.9102 2.75 12.0002 2.75Z"
                                        fill="#FF5A55"
                                    />
                                </svg>
                            </div>
                            <AsyncSelect
                                placeholder="To"
                                loadOptions={(inputValue, callback) => {
                                    loadTos(inputValue, callback);
                                }}
                                styles={{
                                    container: (base, props) => ({
                                        ...base,
                                        flex: "1",
                                    }),
                                    control(base, props) {
                                        return {
                                            ":active": {
                                                borderColor: "#ccc",
                                            },
                                            display: "flex",
                                        };
                                    },
                                }}
                                onChange={(e: any) => {
                                    setDestination(e?.value);
                                    if (!e) {
                                        handleSearch(pickup, undefined);
                                    }
                                }}
                                isClearable
                            />
                        </div>
                        <button
                            data-testid="tripSearchMd"
                            onClick={() => {
                                handleSearch(pickup, destination);
                            }}
                            className="w-full bg-oya-red-100 px-2 py-2 text-white rounded-[10px] text-[18px] mt-2"
                        >
                            Search
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchFilter;
