import React, { useEffect, useState } from "react";
import { RouteConstants } from "../../helpers/constants";
import { Card, Row, Col, Skeleton, Divider } from "antd";
import seatImg from "../../assets/img/Seat.png";
//import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Schedule } from "../../types";
import { api } from "../../helpers/api";
import { copyTextToClipboard } from "../../helpers/utils";
// import outmos from '../../assets/img/outmos.png';
// import { buses } from '../../helpers/constants';
import CustomButton from "../CustomBtn";

// const { Meta } = Card;

function Buses({ param, type }: { param?: number; type: string }) {
    const [schedules, setSchedules] = useState<Schedule[]>([]);
    const [loading, setLoading] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const [itemIndex, setIndex] = useState<any>(0);
    //const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(50);
    //const [total, setTotal] = useState<number>(0);

    // const [copiedStates, setCopiedStates] = useState<any>([]);

    const handleCopyClick = (item: string, index: number) => {
        copyTextToClipboard(item)
            .then(() => {
                setIndex(index);
                setIsCopied(true);

                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            })
            .catch((e) => console.log(e));
    };

    const getSchedules = async () => {
        const filter = [
            type === "branches"
                ? {
                      f: "station.id",
                      o: "=",
                      p: [param],
                  }
                : {
                      f: "bus_company.id",
                      o: "=",
                      p: [param],
                  },
            {
                f: "status",
                o: "in",
                p: ["LOADING", "SCALED"],
            },
        ];
        setLoading(true);
        //console.log(page);
        setLimit(50);
        try {
            const res = await api.get(
                `${RouteConstants.SCHEDULES}?filters=${JSON.stringify(
                    filter
                )}&limit=${limit}`
            );
            setSchedules(res.data?.payload?.items);
            //setTotal(res.data?.payload?.total);
            //setLoading(false);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    // const handleLoadMore = () => {
    //   setPage((prevPage) => prevPage + 1);
    //   getSchedules();
    // };

    useEffect(() => {
        getSchedules();

        // eslint-disable-next-line
    }, []);

    return (
        <div className="flex justify-center items-center px-[1rem] lg:px-[8rem]">
            <div className="my-5">
                <div className="font-bold text-center text-[24px] pb-8">
                    Join a bus from your preferred location
                </div>
                <div className="lg:grid lg:grid-cols-3 gap-5">
                    {loading ? (
                        <Row className="mx-3 gap-3">
                            <Col xs={24} lg={7}>
                                <div className="p-2 border shadow rounded-md">
                                    <Skeleton paragraph active />
                                </div>
                            </Col>
                            <Col xs={24} lg={7}>
                                <div className="p-2 border shadow rounded-md">
                                    <Skeleton paragraph active />
                                </div>
                            </Col>
                            <Col xs={24} lg={7}>
                                <div className="p-2 border shadow rounded-md">
                                    <Skeleton paragraph active />
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            {schedules?.length > 0 ? (
                                <>
                                    {schedules.map((item, index) => (
                                        <div
                                            key={index}
                                            className="buses-cards"
                                        >
                                            <Card
                                                cover={
                                                    <img
                                                        src={
                                                            item?.branch
                                                                ?.backdrop_image_url
                                                                ? item?.branch
                                                                      ?.backdrop_image_url
                                                                : "https://res.cloudinary.com/djp5oy3ri/image/upload/v1703227725/beehive_oya/iydw1javstgy3csuosoc.png"
                                                        }
                                                        alt=""
                                                    />
                                                }
                                                className="mb-3"
                                            >
                                                <div>
                                                    <div className="text-lg font-bold">
                                                        {
                                                            item?.route?.from
                                                                ?.name
                                                        }{" "}
                                                        -{" "}
                                                        {item?.route?.to?.name}
                                                    </div>
                                                    <div className="flex gap-1 items-center py-2">
                                                        <img
                                                            src={seatImg}
                                                            alt=""
                                                        />
                                                        <div>
                                                            {item?.bus
                                                                ?.passenger_capacity -
                                                                item?.onboard_passengers}{" "}
                                                            of{" "}
                                                            {
                                                                item?.bus
                                                                    ?.passenger_capacity
                                                            }{" "}
                                                            seats available
                                                        </div>
                                                    </div>

                                                    <div className="flex justify-between items-center">
                                                        <div>
                                                            <div className="text-xs text-gray-500">
                                                                Trip code
                                                            </div>
                                                            <div className="font-bold">
                                                                {item?.code}
                                                            </div>
                                                        </div>
                                                        <button
                                                            data-testid="copyCode"
                                                            onClick={() => {
                                                                handleCopyClick(
                                                                    item?.code,
                                                                    item?.id
                                                                );
                                                            }}
                                                            className="text-oya-secondary-red text-[16px]"
                                                        >
                                                            <span>
                                                                {isCopied &&
                                                                itemIndex ===
                                                                    item?.id
                                                                    ? "Copied"
                                                                    : "Copy code"}
                                                            </span>
                                                        </button>
                                                    </div>

                                                    <Divider />
                                                    <CustomButton
                                                        data-testid="bookTrip"
                                                        onClick={() => {
                                                            window.open(
                                                                `/guest/${item.id}`,
                                                                "_blank"
                                                            );
                                                        }}
                                                        width="w-full"
                                                    >
                                                        Book Trip
                                                    </CustomButton>
                                                </div>
                                            </Card>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <div>No available buses</div>
                            )}
                        </>
                    )}

                    {/* {!loading && schedules.length <= total && (
            <button
              className='border-2 rounded-md border-oya-secondary-red p-2'
              onClick={() => {
                handleLoadMore();
              }}
            >
              Load more
            </button>
          )} */}

                    {/* {buses.map((item) => (
            <div key={item.id}>
              <Card cover={<img src={item.image} alt='' />} hoverable>
                <Meta
                  title={item.name}
                  description={
                    <div className='flex gap-1 items-center'>
                      <img src={seatImg} alt='' />
                      <div className='text-gray-600'>{item.description}</div>
                    </div>
                  }
                />
                <button
                  onClick={() => {
                    window.open(`/custom_trips/${item.link}`, '_blank');
                  }}
                  className='joinBusBtn flex gap-1 items-center bg-beehive-gold justify-center transition ease-in-out delay-100 duration-200 p-3 mt-4 rounded-md text-white'
                >
                  <div>Join bus</div>
                  <ChevronRightIcon className='w-5 h-5' />
                </button>
              </Card>
            </div>
          ))} */}
                </div>
            </div>
        </div>
    );
}

export default Buses;
