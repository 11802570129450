import React,{useState,useEffect} from "react";
import { Avatar, Dropdown } from "antd";
//import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { logout } from "../../helpers/utils";
import { getCookie } from "../../helpers/utils";
import { accounts } from "../../helpers/api";
import type { MenuProps } from "antd";
import { RouteConstants } from "../../helpers/constants";

const ProfileDropdown = () => {
  const [data, setData] = useState({
    name: "",
    image: "",
    phone: "",
  });
  
  const token = getCookie("oya_token");
  const user = getCookie("oya_user_info");
  //const parsedUserObj = user ? JSON.parse(user) : null;
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const items: MenuProps["items"] = [
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
        >
          <path
            d="M1 20C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H15C15.2652 0 15.5196 0.105357 15.7071 0.292893C15.8946 0.48043 16 0.734784 16 1V4H14V2H2V18H14V16H16V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20H1ZM14 14V11H7V9H14V6L19 10L14 14Z"
            fill="#7C0000"
          />
        </svg>
      ),
      onClick: () => {
        // handleLogout();
        logout();
        // dispatch(logout());
      },
      label: (
        <button
          data-testid="headerLogout"
          className="pl-4 text-oya-ghana-header-h7 font-medium text-black"
        >
          Logout
        </button>
      ),
      key: "1",
    },
  ];

  const getUserData = async () => {
    if (token && user) {
      setIsLoading(true);
      try {
        const res = await accounts.get(`${RouteConstants.PROFILE}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setData(res.data?.payload);
        setIsLoading(false);
        //return res;
      } catch (e) {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    if (user && token) {
      getUserData();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {
        isLoading ? (
          <div className="w-5 h-5 bg-gray-100 rounded-md"/>
        ):(
          <div className="flex items-center gap-3">
        <div className="hover:bg-gray-50 hover:rounded-md hover:cursor-pointer p-2">
          <Dropdown
            trigger={["click"]}
            menu={{ items }}
            overlayStyle={{
              boxShadow: "#1d2a9f 0px 1px 4px",
              position: "relative",
              width: 256,
              borderRadius: 8,
            }}
            onOpenChange={() => setVisible(!visible)}
            placement="bottom"
            arrow
          >
            <div className="flex gap-2 items-center">
              {data?.image ? (
                <Avatar
                  alt="user"
                  size={"large"}
                  shape="square"
                  src={data?.image}
                />
              ) : (
                <div className="rounded-sm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_13_361)">
                      <rect width="48" height="48" rx="12" fill="white" />
                      <rect
                        x="14"
                        y="8"
                        width="20"
                        height="20"
                        rx="10"
                        fill="#484442"
                      />
                      <rect
                        x="-12"
                        y="32"
                        width="72"
                        height="72"
                        rx="36"
                        fill="#484442"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13_361">
                        <rect width="48" height="48" rx="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              )}
              {/* <div>{parsedUserObj?.name}</div> */}
              {/* <Popover
                placement="bottom"
                content={() => (
                  <div className="text-[19px]">{parsedUserObj?.first_name}</div>
                )}
              >
                <ChevronDownIcon className="w-[26px] h-[26px] text-white" />
              </Popover> */}
            </div>
          </Dropdown>
        </div>
      </div>
        )
      }
    </div>
  );
};

export default ProfileDropdown;
