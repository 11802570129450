import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { api } from "../../helpers/api";
import { RouteConstants } from "../../helpers/constants";

import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { MidRoute, Option } from "../../types";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  routeId: number | undefined;
  handleSelected: (pickupId: any) => void;
  isMidroute: boolean | undefined;
  selected: Option | null;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M16.5999 7.4585L11.1666 12.8918C10.5249 13.5335 9.4749 13.5335 8.83324 12.8918L3.3999 7.4585"
          stroke="#484442"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const MidrouteModal = ({
  isOpen,
  handleClose,
  routeId,
  handleSelected,
  isMidroute,
  selected,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [pickups, setPickups] = useState<Option[]>([]);
  const [selectedMidRoute, setSelectedMidRoute] = useState<any>(selected);

  const getMidroutes = async () => {
    if (routeId) {
      try {
        const filter = [
          {
            f: "route.id",
            o: "=",
            p: [routeId],
          },
        ];
        const res = await api.get(
          `${RouteConstants.MIDROUTES}?filters=${JSON.stringify(filter)}`
        );

        setPickups(
          res.data?.payload?.items?.map((item: MidRoute) => ({
            label: item?.pickup?.name,
            value: item?.pickup?.id,
          }))
        );
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  const loadPickups = (value: string, callback: (options: any) => void) => {
    setTimeout(async () => {
      let filterData = [
        {
          f: "pickup.name",
          o: "contains",
          p: [`${value}`],
        },
      ];
      try {
        const res = await api.get(
          `${RouteConstants.MIDROUTES}?filters=${JSON.stringify(filterData)}`
        );

        callback(
          res.data?.payload?.items?.map((item: MidRoute) => ({
            label: item?.pickup?.name,
            value: item?.pickup?.id,
          }))
        );
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }, 2000);
  };

  useEffect(() => {
    if (isMidroute === true) {
      getMidroutes();
    }

    // eslint-disable-next-line
  }, [routeId]);

  return (
    <React.Fragment>
      <Drawer
        open={isOpen}
        footer={null}
        placement="bottom"
        closable={false}
        className="rounded-t-2xl"
        height={600}
      >
        {isMidroute === true ? (
          <div className="p-2">
            <div className="flex justify-between items-center text-oya-gray font-semibold w-full">
              <div className="header-text-4">
              Join Mid-Route
              </div>
              <button onClick={() => handleClose()}>
                <XMarkIcon className="w-6 h-6 " />
              </button>
            </div>

            <div className="mt-5">
            <div className="text-[16px] my-3">
                Where would you like to join the bus?
              </div>
              <AsyncSelect
                isClearable
                defaultOptions={pickups}
                loadOptions={loadPickups}
                isLoading={loading}
                placeholder="Select Bus Stop"
                value={selectedMidRoute}
                components={{ DropdownIndicator }}
                onChange={(value) => {
                  setSelectedMidRoute(value);
                  //console.log(value);
                }}
                styles={{
                  container(base, props) {
                    return {
                      ...base,
                      height: "44px",
                      borderWidth: "1px",
                      borderRadius: "8px",
                      borderColor: "#939291",
                    };
                  },
                  control(base, props) {
                    return {
                      ":active": {
                        borderColor: "#ccc",
                      },
                      width: "100%",
                      display: "flex",
                    };
                  },
                }}
              />
              {error && (
                <div className="mt-1 text-oya-red-100">
                  Please select a bus stop
                </div>
              )}
            </div>
            <button
              onClick={() => {
                if (!selectedMidRoute) {
                  setError(true);
                } else {
                  setError(false);
                  handleClose();
                  handleSelected(selectedMidRoute);
                }
              }}
              className="bg-oya-red-100 text-white rounded-lg w-full h-[35px] flex justify-center items-center mt-5"
            >
              <div className="font-medium text-lg">Select bus stop</div>
            </button>
          </div>
        ) : (
          <div className="p-2">
            <div className="flex justify-between items-center text-oya-gray font-semibold w-full">
              <div className="header-text-4">
                Where will you like to join the bus?
              </div>
              <button onClick={() => handleClose()}>
                <XMarkIcon className="w-6 h-6 " />
              </button>
            </div>
            <div className="text-oya-gray text-lg mt-8 text-center">
              Sorry! This schedule does not support joining mid-route.
            </div>
          </div>
        )}
      </Drawer>
    </React.Fragment>
  );
};

export default MidrouteModal;
