import { Fragment, useState } from "react";
import { Modal } from "antd";
import CustomButton from "../CustomBtn";
import Signin from "./Signin";
import SignUp from "./Signup";
import { useNavigate } from "react-router-dom";

const Prompt = ({
    isOpen,
    handleClosed,
    scheduleId,
}: {
    isOpen: boolean;
    handleClosed: () => void;
    scheduleId?: number;
}) => {
    const [openLogIn, setOpenLogIn] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const navigate = useNavigate();

    return (
        <Fragment>
            <Signin
                isOpen={openLogIn}
                handleClosed={() => setOpenLogIn(false)}
                openSignUp={() => setOpenRegister(true)}
                schedule={scheduleId}
            />

            <SignUp
                isOpen={openRegister}
                handleClosed={() => setOpenRegister(false)}
                openSignIn={() => setOpenLogIn(true)}
                schedule={scheduleId}
            />
            <Modal open={isOpen} onCancel={() => handleClosed()} footer={null}>
                <div className="mb-[40px] w-full flex gap-3 mt-4 items-center">
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                        >
                            <path
                                d="M20 3.33335C29.1666 3.33335 36.6666 10.8334 36.6666 20C36.6666 29.1667 29.1666 36.6667 20 36.6667C10.8333 36.6667 3.33331 29.1667 3.33331 20C3.33331 10.8334 10.8333 3.33335 20 3.33335Z"
                                stroke="#FF0800"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M20 26.6667V18.3334"
                                stroke="#FF0800"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M19.9908 13.3333H20.0058"
                                stroke="#FF0800"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                    <div>
                        <div className="font-semibold  sm:text-xl">
                            You do not seem to have logged in.
                        </div>
                        <div className="py-1 sm:text-lg">
                            How do you want to proceed?
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <div className="w-full flex gap-4 mb-4">
                        <CustomButton
                            data-testid="continueAsGuest"
                            onClick={() => navigate(`/guest/${scheduleId}`)}
                            width="w-1/2"
                        >
                            Continue as guest
                        </CustomButton>
                        <CustomButton
                            data-testid="login"
                            onClick={() => {
                                handleClosed();
                                setOpenLogIn(true);
                            }}
                            width="w-1/2"
                            variant="secondary"
                        >
                            Login
                        </CustomButton>
                    </div>
                    <div className="w-full  flex justify-center items-center">
                        <button
                            data-testid="signUp"
                            className="text-oya-red-100 text-[16px] font-semibold"
                            onClick={() => {
                                handleClosed();
                                setOpenRegister(true);
                            }}
                        >
                            Sign up
                        </button>
                        {/* <CustomButton
              width='w-full'
              variant='secondary'
              onClick={() => navigate(`/guest/${scheduleId}`)}
            >
              Continue as Guest
            </CustomButton> */}
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default Prompt;
