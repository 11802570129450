import Dexie, { type EntityTable } from "dexie";
import { ISeat } from ".";

const db = new Dexie("SeatDatabase") as Dexie & {
  seats: EntityTable<ISeat, "index">;
};

// Schema declaration:
db.version(1).stores({
  seats:
    "index, schedule_id, seat_id,seat_num,isComplete, minors, occupant_type, passenger, pickup_name, shareWithMinor",
});

export { db };
