import React from 'react';
import logo from '../../assets/img/logo.png';
import { FacebookSharp, LinkedIn, Twitter } from '@mui/icons-material';

const Footer = () => {
  return (
    <div className='bg-black text-white pl-[2rem] pr-[2rem] sm:pl-[8rem] sm:pr-[8rem] py-6'>
      <div className='sm:flex justify-between items-center gap-2'>
        <div className='text-sm font-semibold'>
          <div className='text-sm'>Powered by</div>
          <div className='flex items-center gap-2'>
            <img src={logo} className='w-6 h-6' alt='logo' />
            <div className='text-3xl'>Oya!</div>
          </div>
        </div>
        <div className='sm:my-0 my-4'>
          <div className='text-sm font-semibold'>Help line</div>
          <div className='pt-1'>059 410 7075</div>
          <div>020 865 9922</div>
          <div>030 823 0907</div>
        </div>
        <div>
          <div>Follow Oya! on socials</div>
          <div className='flex gap-2 items-center'>
            <a href='https://www.facebook.com/oyaghanaofficial'>
              <FacebookSharp
                sx={{
                  wdith: 25,
                  height: 25,
                }}
              />
            </a>
            <a href='https://www.linkedin.com/company/oyaghanaofficial'>
              <LinkedIn
                sx={{
                  wdith: 25,
                  height: 25,
                }}
              />
            </a>
            <a href='https://twitter.com/oyaghofficial'>
              <Twitter
                sx={{
                  wdith: 25,
                  height: 25,
                }}
              />
            </a>
          </div>
        </div>
      </div>
      <div className='text-center text-sm pt-4'>&copy;2023, Oya! Ghana</div>
    </div>
  );
};

export default Footer;
