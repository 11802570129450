import React, { useState } from "react";
import { Modal, Spin, Form, Input, notification } from "antd";
import CustomButton from "../CustomBtn";
import PhoneInput, { Value } from "react-phone-number-input";
import { RouteConstants } from "../../helpers/constants";
import { auth } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import {
    setCookie,
    setCookieJson,
    getDaysUntilJwtExpiration,
} from "../../helpers/utils";

const Signin = ({
    isOpen,
    handleClosed,
    openSignUp,
    schedule,
}: {
    isOpen: boolean;
    handleClosed: () => void;
    openSignUp: () => void;
    schedule?: number;
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [phone, setPhone] = useState<Value | undefined>();

    const navigate = useNavigate();

    const validatePin = (e: any) => {
        const regex = /[^0-9]/gi;
        form.setFieldsValue({
            [e.target.id]: e.target.value.replace(regex, ""),
        });
    };

    const submit = async () => {
        setSubmitting(true);
        try {
            const data = form.getFieldsValue();
            const jsonData = await auth.post(RouteConstants.LOGIN, {
                ...data,
                phone,
            });

            const daysUntilExpiration = getDaysUntilJwtExpiration(
                jsonData.data?.payload?.token
            );

            setCookieJson(
                "usr",
                jsonData.data?.payload?.user?.id,
                daysUntilExpiration
            );
            setCookie(
                "token",
                jsonData.data?.payload?.token,
                daysUntilExpiration
            );
            setCookieJson("user_info", jsonData?.data?.payload?.user);

            setCookieJson(
                "user",
                {
                    // staffs: jsonData?.data?.payload?.relations,
                    user_roles: jsonData.data?.payload?.user_roles,
                    role_id: jsonData.data?.payload?.user?.type?.id,
                    role: jsonData.data?.payload?.user?.type?.name,
                },
                daysUntilExpiration
            );
            handleClosed();
            setSubmitting(false);
            notification.success({
                message: "Login successful",
            });
            if (schedule) {
                navigate(`/trip/${schedule}`);
            }
            window.location.reload();
        } catch (e: any) {
            notification.error({
                message: e?.response?.data?.message,
            });
            console.log(e);
            setSubmitting(false);
        }
    };

    return (
        <Modal footer={null} open={isOpen} onCancel={handleClosed}>
            <Spin spinning={submitting}>
                <div>
                    <h4 className="text-lg md:text-[24px] font-bold">
                        Sign In
                    </h4>
                    <div className=" font-medium md:text-lg">Welcome back!</div>
                </div>

                <Form
                    form={form}
                    layout="vertical"
                    className="w-full pt-10"
                    autoComplete="false"
                >
                    <Form.Item
                        colon={false}
                        label={
                            <div className="text-gray-500 text-sm">
                                Your mobile number
                            </div>
                        }
                        className="w-full mb-6"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: "This field is required",
                            },
                        ]}
                    >
                        <PhoneInput
                            name="phone"
                            className="phone w-full pl-4 px-2 border border-gray-200 rounded-md outline-none h-10 focus:outline-0 focus:shadow-transparent"
                            defaultCountry="GH"
                            onChange={(e) => {
                                setPhone(e);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        colon={false}
                        className="my-6"
                        label={
                            <h5 className="text-gray-500 text-sm">Your pin</h5>
                        }
                        name="pin"
                        rules={[
                            {
                                required: true,
                                message: "This field is required",
                            },
                        ]}
                    >
                        <Input.Password
                            type="password"
                            autoComplete="none"
                            size="large"
                            className="w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none"
                            disabled={submitting}
                            onChange={validatePin}
                        />
                    </Form.Item>

                    {/* <p className='text-sm pb-4 hidden'>
            Do you forget your pin?{' '}
            <span className='font-bold pt-3 cursor-pointer' onClick={() => {}}>
              Reset PIN
            </span>
          </p> */}

                    <div className="flex gap-2">
                        <CustomButton
                            data-testid="login"
                            width="w-full"
                            onClick={() => submit()}
                        >
                            Login
                        </CustomButton>
                        {/* <CustomButton
              onClick={() => {
                handleClosed();
              }}
              variant='secondary'
            >
              Cancel
            </CustomButton> */}
                    </div>

                    <div className="mt-4 font-light">
                        Don't have an account?{" "}
                        <button
                            data-testid="createAccount"
                            onClick={() => {
                                openSignUp();
                                handleClosed();
                            }}
                            type="button"
                            className="text-oya-red-100 font-semibold"
                        >
                            Create one
                        </button>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );
};

export default Signin;
