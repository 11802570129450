import React from 'react';
import oya_logo from '../../assets/img/oya_logo.svg';
import { PhoneIcon } from '@heroicons/react/24/solid';
type Props = {};

const HeaderLarge = (props: Props) => {
  return (
    <nav className='flex justify-between items-center px-[1rem] lg:px-[4rem] xl:px-[8rem] pt-5 pb-5'>
      <div>
        <img src={oya_logo} alt='' />
      </div>
      <div className='flex justify-center items-center gap-2'>
        <a
          href='tel:+233 27 441 0871'
          className='flex items-center gap-2 hover:text-gray-800'
        >
          <PhoneIcon className='sm:w-5 sm:h-5 w-3 h-3' />
          <div className='font-semibold sm:text-base text-sm'>
            Help Line: +233 27 441 0871
          </div>
        </a>
        {/* <button className='bg-oya-secondary-red rounded-md text-white px-3 py-2 text-xs font-medium'>
          Get the app
        </button> */}
      </div>
    </nav>
  );
};

export default HeaderLarge;
