import React from "react";
import BottomNav from "../components/BottomNav";

const Activites = () => {
  return (
    <React.Fragment>
      <div className="h-screen overflow-hidden bg-white relative">
        <div className="py-4 mx-3">
          <div className="header-text-3">Activities</div>
        </div>

        <div className="fixed w-full bottom-0">
          <BottomNav activeIndex={2} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Activites;
