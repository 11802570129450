import { Fragment } from "react";
// import { Drawer, IconButton } from "@material-tailwind/react";
import { Radio, Space, Drawer } from "antd";

type SORT = "desc" | "asc";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  sorting: SORT;
  handleSortChange: (value: SORT) => void;
};

const SortSchedules = ({
  isOpen,
  handleClose,
  sorting,
  handleSortChange,
}: Props) => {
  return (
    <Fragment>
      <Drawer
        placement="bottom"
        footer={null}
        closable={false}
        open={isOpen}
        className=" rounded-t-[30px] bg-white"
        height={180}
        onClose={() => handleClose()}
      >
        <div>
          <div className="mb-3 flex items-center justify-between">
            <div className="text-lg font-semibold">Sort by</div>
            <button onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
              >
                <path
                  d="M10.3519 21.9445C10.0083 21.9445 9.66463 21.8179 9.39335 21.5466C8.86888 21.0222 8.86888 20.1541 9.39335 19.6296L19.6296 9.39335C20.1541 8.86888 21.0222 8.86888 21.5466 9.39335C22.0711 9.91783 22.0711 10.7859 21.5466 11.3104L11.3104 21.5466C11.0572 21.8179 10.6955 21.9445 10.3519 21.9445Z"
                  fill="#484442"
                />
                <path
                  d="M20.5881 21.9445C20.2445 21.9445 19.9009 21.8179 19.6296 21.5466L9.39335 11.3104C8.86888 10.7859 8.86888 9.91783 9.39335 9.39335C9.91783 8.86888 10.7859 8.86888 11.3104 9.39335L21.5466 19.6296C22.0711 20.1541 22.0711 21.0222 21.5466 21.5466C21.2754 21.8179 20.9317 21.9445 20.5881 21.9445Z"
                  fill="#484442"
                />
              </svg>
            </button>
          </div>

          <div className="mobile-sort-radio">
            <Space direction="vertical">
              <Radio
                onClick={() => {
                  handleSortChange("asc");
                  handleClose();
                }}
                checked={sorting === "asc" ? true : false}
              >
                Lowest to highest price
              </Radio>
              <Radio
                onClick={() => {
                  handleSortChange("desc");
                  handleClose();
                }}
                checked={sorting === "desc" ? true : false}
              >
                Highest to lowest price
              </Radio>
            </Space>
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default SortSchedules;
